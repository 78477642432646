import { Component, OnInit } from "@angular/core";
import {  FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { SharedApiService } from "app/main/shared-module/services/shared.api.service";
import { ApiService } from "app/services/api.service";
import { MarketBotDialogComponent } from "app/main/shared-module/common/market-bot-dialog/market-bot-dialog.component";
import { MarketEntityDialogComponent } from "app/main/shared-module/common/market-entity-dialog/market-entity-dialog.component";
import { environment } from "environments/environment";
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: "app-entity-profile",
  templateUrl: "./entity-profile.component.html",
  styleUrls: ["./entity-profile.component.scss"],
})
export class EntityProfileComponent implements OnInit {
  inputText = {};
  title = "Bot Details";
  rootTitle = "Manage Bots";
  botId = "";
  currentBot: any = {};
  pramsuserid: any;
  entityData: any;
  entityForm: FormGroup;
  dataZeroIndex: any;
  localRoot: any;
  validFrom: any;
  validTo: any;
  processData: any;
  charactersticData: any;
  botData: any;
  processID: string;
  myShow: boolean = false;
  processData2: any;
  charactersticData2: any;
  joinedName: string;
  entityGeoLocation: any;
  charNotFound: any;
  dataSuccess: any;
  NotFound: any;
  createdBy: any;
  botData2: any;
  allProcessData: any;
  successMsg: any;
  successValue: any;
  defaultImg: any;
  getAccessValue: any;
  getAccessMsg: any;
  parentEntityID: string;
  parentEntity_Id: any;
  parentEntityDescribe: any;
  parentEntityName: any;
  parentProcessName: any;
  parentProcessDescribe: any;
  relationshipType: any;
  relationshipTypeDescription: any;
  singleEntityData: any;
  public createdByUser: boolean = false;
  public ownerUser: boolean = false;
  public parentProcessID: any;
  public entityParentRelationshipID: any;
  public owner: any;
  clonedFromId: any;
  clonedFrom: any;
  cloneable: boolean = false;

  userId: any;

  constructor(
    private apiService: SharedApiService,
    private api: ApiService,
    private route: ActivatedRoute,
    private _form: FormBuilder,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private cookieService: CookieService,
  ) {}

  ngOnInit(): void {
    const paramsData = this.route.snapshot.params;
    this.pramsuserid = paramsData.id;
    this.userId = this.cookieService.get('userId') || environment.userId

    this.api.getEntityByIdSingle(this.pramsuserid, "MAIN").then((getData) => {
      this.entityData = getData.data;
      this.getAccessValue = getData.access;
      this.getAccessMsg = getData.msg;
      this.successValue = getData.success;
      if (this.successValue == false) {
        this.successMsg = getData.msg;
      } else {
        this.singleEntityData = getData.data;

        this.dataZeroIndex = this.entityData;
        var entityName = this.entityData.entityName;

        var firstName =
          this.entityData.userDetails.length > 0
            ? this.entityData.userDetails[0].userFirstName
            : "";
        var lastName =
          this.entityData.userDetails.length > 0
            ? this.entityData.userDetails[0].userLastName
            : "";
        this.joinedName = firstName + " " + lastName;
        this.createdBy = this.entityData.createdBy;
        var hostIP = this.entityData.hostIP;
        this.localRoot = this.entityData.localRoot;
        this.parentEntity_Id =
          this.entityData.parentEntityDetails &&
          this.entityData.parentEntityDetails.length > 0
            ? this.entityData.parentEntityDetails[0]._id
            : "";
        this.parentEntityName =
          this.entityData.parentEntityDetails &&
          this.entityData.parentEntityDetails.length > 0
            ? this.entityData.parentEntityDetails[0].entityName
            : "";
        this.parentEntityDescribe =
          this.entityData.parentEntityDetails &&
          this.entityData.parentEntityDetails.length > 0
            ? this.entityData.parentEntityDetails[0].entityDescription
            : "";
        this.parentProcessName =
          this.entityData.parentProcessDetails &&
          this.entityData.parentProcessDetails.length > 0
            ? this.entityData.parentProcessDetails[0].processName
            : "";
        this.parentProcessDescribe =
          this.entityData.parentProcessDetails &&
          this.entityData.parentProcessDetails.length > 0
            ? this.entityData.parentProcessDetails[0].processDescription
            : "";
        this.relationshipType =
          this.entityData.entityParentRelationshipDetails &&
          this.entityData.entityParentRelationshipDetails.length > 0
            ? this.entityData.entityParentRelationshipDetails[0]
                .relationshipType
            : "";
        this.relationshipTypeDescription =
          this.entityData.entityParentRelationshipDetails &&
          this.entityData.entityParentRelationshipDetails.length > 0
            ? this.entityData.entityParentRelationshipDetails[0]
                .relationshipTypeDescription
            : "";
        
        var entityLocation = this.entityData.entityLocation;
        var entityPinCode = this.entityData.entityPinCode;
        this.entityGeoLocation = this.entityData.entityGeoLocation;
        var createdOn = this.entityData.createdOn;

        var toconvertDate = this.datePipe.transform(
          createdOn,
          "d MMMM y HH:MM:SS"
        );
        

        
        if (!this.entityGeoLocation) {
        } else {
        }

        var entityTypeID =
          this.entityData.entityTypeDetails &&
          this.entityData.entityTypeDetails.length > 0
            ? this.entityData.entityTypeDetails[0].entityType +
              " " +
              this.entityData.entityTypeDetails[0].entityTypeDescription
            : "";
        this.createdBy =
          this.singleEntityData.createdByDetails &&
          this.singleEntityData.createdByDetails.length > 0
            ? this.singleEntityData.createdByDetails[0].userFirstName +
              " " +
              this.singleEntityData.createdByDetails[0].userLastName
            : "";
        this.parentEntityID =
          this.singleEntityData.parentEntityDetails &&
          this.singleEntityData.parentEntityDetails.length > 0
            ? this.singleEntityData.parentEntityDetails[0].entityName +
              " " +
              this.singleEntityData.parentEntityDetails[0].entityDescription
            : "";
        this.parentProcessID =
          this.singleEntityData.parentProcessDetails &&
          this.singleEntityData.parentProcessDetails.length > 0
            ? this.singleEntityData.parentProcessDetails[0].processName +
              " " +
              this.singleEntityData.parentProcessDetails[0].processDescription
            : "";
        this.entityParentRelationshipID =
          this.singleEntityData.entityParentRelationshipDetails &&
          this.singleEntityData.parentProcessDetails.length > 0
            ? this.singleEntityData.entityParentRelationshipDetails[0]
                .relationshipType +
              " " +
              this.singleEntityData.entityParentRelationshipDetails[0]
                .relationshipTypeDescription
            : "";
        this.owner =
          this.singleEntityData.userDetails &&
          this.singleEntityData.userDetails.length > 0
            ? this.singleEntityData.userDetails[0].userFirstName +
              " " +
              this.singleEntityData.userDetails[0].userLastName
            : "";

        this.clonedFrom =
          this.singleEntityData.clonedFromEntityDetails.length > 0
            ? this.singleEntityData.clonedFromEntityDetails[0].entityName
            : "";
        this.clonedFromId =
          this.singleEntityData.clonedFromEntityDetails.length > 0
            ? this.singleEntityData.clonedFromEntityDetails[0]._id
            : "";
        this.cloneable = this.singleEntityData.cloned;

        this.entityForm = this._form.group({
          entityName: this.singleEntityData.entityName,
          entityTypeID: entityTypeID,
          entityDescription: this.singleEntityData.entityDescription,

          owner: this.owner,
          createdBy: this.createdBy,
          createdOn: this.datePipe.transform(
            this.singleEntityData.createdOn,
            "d MMMM y HH:MM:SS"
          ), //toconvertDate,

          parentEntityID: this.parentEntityID,
          parentProcessID: this.parentProcessID,
          entityParentRelationshipID: this.entityParentRelationshipID,

          entityLocation: this.singleEntityData.entityLocation,
          entityPinCode: this.singleEntityData.entityPinCode,
          entityGeoLocation: this.singleEntityData.entityGeoLocation,

          validFrom: this.datePipe.transform(
            this.singleEntityData.validFrom,
            "d MMMM y HH:MM:SS"
          ),
          validTo: this.datePipe.transform(
            this.singleEntityData.validTo,
            "d MMMM y HH:MM:SS"
          ),
          status: this.singleEntityData.status,
          process: "",
          processBlock: "",
          clone: "",
          hostIP: hostIP,
          clonedFromId: this.clonedFrom,
        });

        if (this.singleEntityData.createdBy == this.userId) {
          this.createdByUser = true;
        }
        if (this.singleEntityData.owner == this.userId) {
          this.ownerUser = true;
        }

        this.entityForm.controls["entityName"].disable();
        this.entityForm.controls["entityTypeID"].disable();
        this.entityForm.controls["entityDescription"].disable();
        this.entityForm.controls["owner"].disable();
        this.entityForm.controls["createdBy"].disable();
        this.entityForm.controls["createdOn"].disable();
        this.entityForm.controls["parentEntityID"].disable();
        this.entityForm.controls["parentProcessID"].disable();
        this.entityForm.controls["entityParentRelationshipID"].disable();
        this.entityForm.controls["entityLocation"].disable();
        this.entityForm.controls["entityPinCode"].disable();
        this.entityForm.controls["entityGeoLocation"].disable();
        this.entityForm.controls["validFrom"].disable();
        this.entityForm.controls["validTo"].disable();
        this.entityForm.controls["status"].disable();
        this.entityForm.controls["process"].disable();
        this.entityForm.controls["processBlock"].disable();
        this.entityForm.controls["clone"].disable();
        this.entityForm.controls["hostIP"].disable();
        this.entityForm.controls["clonedFromId"].disable();
      }
    });

    this.api.getBotByEntId(this.pramsuserid, "MAIN").then((getData) => {
      this.botData = getData.data;
      this.botData2 = getData.data;
    });

    this.api
      .getCharacterSticByEntId(this.pramsuserid, "MAIN")
      .then((getData) => {
        this.charactersticData2 = getData.data;
        this.charactersticData = getData.data;
      });

    this.api.getProcessByEntId(this.pramsuserid, "MAIN").then((getData) => {
      this.allProcessData = getData.success;
      this.processData2 = getData.data;
      this.processData = getData.data; //  -- Use for reload Data
    });
  }

  clickPanel(name) {
    // for reset table
    this.api
      .getCharacterSticByEntId(this.pramsuserid, "MAIN")
      .then((getData) => {
        this.charactersticData2 = getData.data;
        this.charactersticData = getData.data;
      });

    this.api.getProcessByEntId(this.pramsuserid, "MAIN").then((getData) => {
      this.processData2 = getData.data;
      this.processData = getData.data; //  -- Use for reload Data
    });

    this.api.getBotByEntId(this.pramsuserid, "MAIN").then((getData) => {
      this.botData = getData.data;
      this.botData2 = getData.data;
    });
  }

  showProcess(data) {
    for (let index = 0; index < data.length; index++) {
      var element = data[index];
    }
    var trt = this.processData2.filter((ele) => {
      if (element) {
        return element._id == ele._id;
      } else {
      }
    });

    this.processData = trt;

    var botNewData = this.botData2.filter((botD) => {
      if (element) {
        return botD.processID == element._id;
      } else {
      }
    });
    this.botData = botNewData;
  }

  showCharacterStick(id) {
    var test = this.charactersticData2.filter((ele) => {
      for (let index = 0; index < ele.processDetails.length; index++) {
        var processDetail = ele.processDetails[index];
      }
      if (processDetail) {
        return processDetail._id == id;
      } else {
      }
    });
    this.charactersticData = test;

    var botNewData = this.botData2.filter((botD) => {
      return botD.processID == id;
    });
    this.botData = botNewData;
  }
  openToBuyEntity(id, type) {
    const dialogRef = this.dialog.open(MarketEntityDialogComponent, {
      width: "650px",
      data: {
        id: id,
        cardType: type,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  openToBuyBot(id, type) {
    const dialogRef = this.dialog.open(MarketBotDialogComponent, {
      width: "650px",
      data: {
        id: id,
        cardType: type,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  getProperImage() {
    return "url(" + this.entityData.entityAvatar + ")";
  }

  getImage() {
    if (this.defaultImg == undefined || this.defaultImg == "") {
      return "url(assets/images/avatars/entity_avatar_1.jfif)";
    }
    return "url(" + this.defaultImg + ")";
  }
}
