//import { ApiService } from 'app/services/api.service';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpHeaders } from "@angular/common/http";

import {
  getAllEntities,
  getAllBots,
  createBots,
  createBotFeature,
  getAllDelegateBots,
  getDelegateBotsDropdown,
  createDelegateBots,
  updateDelegateBots,
  createTraining,
  getAllTraining,
  updateTraining,
  getProcessIntent,
  getAllIntegrationsBots,
} from "./bot.api.url";
import { SharedApiService } from "app/main/shared-module/services/shared.api.service";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class BotService {
  constructor(private apiService: SharedApiService, private cookieService: CookieService) {}

  httpOptions: any = {
    headers: this.generateHeaders(),
  };

  getAllEntities(): Observable<any> {
    return this.apiService.sendGetRequest(getAllEntities, {});
  }
  getAllBots1(): Observable<any> {
    return this.apiService.sendGetRequest(getAllBots, {});
  }
  editBots(payload, id): Observable<any> {
    return this.apiService.sendPutRequest(createBots + "/" + id, payload);
  }
  createFeaturedBot(id, payload): Observable<any> {
    return this.apiService.sendPostRequest(
      createBotFeature + "/" + id,
      payload
    );
  }
  getAllDelegateBots(id): Observable<any> {
    return this.apiService.sendGetRequest(getAllDelegateBots + "/" + id, {});
  }

  getDelegateBotsDropdown(id): Observable<any> {
    return this.apiService.sendGetRequest(
      getDelegateBotsDropdown + "/" + id,
      {}
    );
  }
  createDelegateBots(payload): Observable<any> {
    return this.apiService.sendPostRequest(createDelegateBots, payload);
  }
  deactivateDelegateBots(id, payload): Observable<any> {
    return this.apiService.sendPutRequest(
      updateDelegateBots + "/" + id,
      payload
    );
  }

  getAllTraining(id): Observable<any> {
    return this.apiService.sendGetRequest(getAllTraining + "/" + id, {});
  }
  createTraining(payload): Observable<any> {
    return this.apiService.sendPostRequest(createTraining, payload);
  }
  updateTraining(id, payload): Observable<any> {
    return this.apiService.sendPutRequest(updateTraining + "/" + id, payload);
  }

  getAllIntegrationsBots(id): Observable<any> {
    return this.apiService.sendGetRequest(
      getAllIntegrationsBots + "/" + id,
      {}
    );
  }

  getProcessIntent(id): Observable<any> {
    return this.apiService.sendGetRequest(getProcessIntent + "/" + id, {});
  }

  generateHeaders(options?: any): HttpHeaders {
    var httpHeaders = null;
    let token = localStorage.getItem("token") || this.cookieService.get("token");
    if (token) {
      httpHeaders = new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      });
    } else {
      httpHeaders = new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
      });
    }

    return httpHeaders;
  }
}
