import { Injectable, Output, EventEmitter } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { Store } from "@ngxs/store";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { promise } from "protractor";
import Fortmatic from "fortmatic";
import { APP_ROUTES } from "app/main/shared-module/constants/AppRoutes";
import { CookieService } from "ngx-cookie-service";
const SERVER_URL = environment.serverUrl;

@Injectable({
  providedIn: "root",
})
export class SharedApiService {
  httpOptions: any = {
    headers: this.generateHeaders(),
  };

  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private cookieService: CookieService
  ) {}

  generateHeaders(options?: any): HttpHeaders {
    const headers: any = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    let token = localStorage.getItem("token") || this.cookieService.get("token");
    if (token) {
      headers["Authorization"] = "Bearer " + token;
    }
    return new HttpHeaders(headers);
  }

  get(urlAddress: string, options?: any): Observable<any> {
    const headers: HttpHeaders = this.generateHeaders(options);
    return this.http
      .get<any>(SERVER_URL + urlAddress, { headers: headers })
      .pipe(map((data) => data));
  }

  post(urlAddress: string, payload?: any, options?: any): Observable<any> {
    const headers: HttpHeaders = this.generateHeaders(options);
    return this.http
      .post<any>(SERVER_URL + urlAddress, payload, { headers: headers })
      .pipe(map((data) => data));
  }

  put(urlAddress: string, payload: any, options?: any): any {
    const headers: HttpHeaders = this.generateHeaders(options);
    return this.http
      .put<any>(SERVER_URL + urlAddress, payload, { headers: headers })
      .pipe(map((data) => data));
  }

  delete(urlAddress: string, options?: any): any {
    const headers: HttpHeaders = this.generateHeaders(options);
    return this.http
      .delete<any>(SERVER_URL + urlAddress)
      .pipe(map((data) => data));
  }

  sendPutRequest(url, payload): any {
    return this.http.put<any>(url, payload);
  }
  sendDeleteRequest(url, payload): any {
    const options = {
      body: payload,
    };
    return this.http.request<any>("delete", url, options);
  }

  sendPostRequest(url, payload): any {
    const headers: HttpHeaders = this.generateHeaders();
    const options = {
      params: payload,
      headers: headers,
    };
    return this.http.post<any>(url, payload, { headers: headers });
  }

  sendPatchRequest(url, payload): any {
    return this.http.patch<any>(url, payload);
  }

  sendGetRequest(url, payload): any {
    const headers: HttpHeaders = this.generateHeaders();
    const options = {
      params: payload,
      headers: headers,
    };
    return this.http.get<any>(url, options);
  }
}
