import { MaterialModule } from "./../../shared-module/modules/material.module";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { FuseSharedModule } from "@fuse/shared.module";
import { LoginComponent } from "./login.component";
import { CommonModule } from "@angular/common";
import { APP_ROUTES } from "app/main/shared-module/constants/AppRoutes";
import { AccessCodeComponent } from "./access-code/access-code.component";
import { AuthGuardService as AuthGuard } from "app/main/shared-module/services/auth.guard.service";

const routes = [
  {
    path: APP_ROUTES.LOGIN,
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [LoginComponent, AccessCodeComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MaterialModule,
  ],
  exports: [LoginComponent, AccessCodeComponent],
  entryComponents: [AccessCodeComponent],
})
export class LoginModule {}
