import { Injectable,  } from "@angular/core";
import { environment } from "../../environments/environment";
import { Store } from "@ngxs/store";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import Fortmatic from "fortmatic";
import { CookieService } from "ngx-cookie-service";

const SERVER_URL = environment.baseUrl;
@Injectable({
  providedIn: "root",
})
export class ErrorHandleService {
  public data: any;
  constructor(    
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private cookieService: CookieService
  ) {}

  handleError(error) {
    switch (error.status) {
      case 400: {
        this.toastr.error("Bad Request / Invalid Request");
        return;
      }
      case 401: {
        this.toastr.error("Unauthorized");
        this.cookieService.delete("token", "");
        this.cookieService.delete("userId", "");
        this.cookieService.delete("user", "");
        this.cookieService.delete("userKYC", "");

        const fm = new Fortmatic("pk_live_DF44F7660C691718"); //pk_test_5751EB76847A1B12
        fm.user.logout();
        this.store.reset({});
        localStorage.setItem("login", "");
        localStorage.setItem("user", "");
        var testObject = {
          otp: null,
          user: {},
        };
        localStorage.setItem("user", JSON.stringify(testObject));
        var loginObject = {
          isLoggedIn: false,
          token: "",
        };
        localStorage.setItem("login", JSON.stringify(loginObject));

        localStorage.clear();
        this.router.navigate(["/login"]);
        return;
      }
      case 403: {
        this.toastr.error("Access Denied");
        return;
      }
      case 404: {
        this.toastr.error("API not found.");
        this.router.navigate(["/404"]);
        return;
      }
      case 405: {
        this.toastr.error("Method Not Allowed");
        return;
      }
      case 409: {
        this.toastr.error(
          "The request could not be completed due to a conflict."
        );
        return;
      }
      case 500: {
        this.toastr.error("Internal Server Error");
        this.router.navigate(["/500"]);
        return;
      }
      case 503: {
        this.toastr.error("Service Unavailable");
        return;
      }
      default: {
        this.toastr.error("Unknown Server Error");
        return;
      }
    }
  }
}
