import { Injectable, Output, EventEmitter } from "@angular/core";
import {  Store } from "@ngxs/store";
import { Observable } from "rxjs/Observable";
import { Http } from "@angular/http";
import { io } from "socket.io-client";
import { ApiService } from "./api.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ChatServiceService } from "app/main/chat-module/chat-service.service";
import { environment } from "environments/environment";



import { CookieService } from "ngx-cookie-service";




@Injectable({
  providedIn: "root",
})
export class SocketServiceService {
  @Output() notification: EventEmitter<any> = new EventEmitter();
  @Output() socketData: EventEmitter<any> = new EventEmitter();
  @Output() kafkaSocketDataList: EventEmitter<any> = new EventEmitter();
  @Output() kafkaSocketDataDetails: EventEmitter<any> = new EventEmitter();
  @Output() botDetailsData: EventEmitter<any> = new EventEmitter();
  //public botDetailsData : any;
  public socket;
  public kafkaSocket;
  public kafkaToken = localStorage.getItem("token") || this.cookieService.get("token");
  public notificationCount = 0;
  kafkaObserver;
  observer;
  chatOpened;
  userId;
  constructor(
    // private http: Http,
    private api: ApiService,
    private chatService: ChatServiceService,
    private router: Router,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private store: Store,
  ) {
    this.userId = localStorage.getItem("userId") || this.cookieService.get('userId') || environment.userId;
    this.socket = io(environment.baseUrl + "/?token=" + this.kafkaToken); //environment.baseUrl +
    this.getNotificationCount("");
    this.updateAllChatNotification();

    

    setInterval(() => {
      if (
        !this.router.url.includes("/login") &&
        !this.router.url.includes("/complete-profile")
      ) {
        this.socket.emit("notification-count", {
          token: this.kafkaToken,
        });
      }
    }, 5000);

    this.socket.on("notification-count/" + this.userId, (res) => {
      this.notificationCount = res.count ? res.count : 0;
      this.notification.emit(this.notificationCount);
    });

    this.kafkaSocket = io("https://kafka.hopabot.com", {
      query: {
        token: this.kafkaToken,
      },
    });

    this.socket
      .off("notify-k8/" + this.userId)
      .on("notify-k8/" + this.userId, (data) => {        
       
        
        this.store
        .select((s) => s.BotState.allBots)
        .subscribe((u) => {
          for(var j=0; j < u.length; j++) 
          {
            if(u[j]._id == data.botId)
            {
                if(data.deploymentStatus != undefined) {u[j].deploymentStatus = data.deploymentStatus}
                if(data.opInProgress != undefined) {u[j].opInProgress = data.opInProgress}
                if(data.reDeploy != undefined) {u[j].reDeploy = data.reDeploy}
                if(data.statusCode != undefined) {u[j].statusCode = data.statusCode}
                if(data.bot_url != undefined) {u[j].hostIP = data.bot_url}
                if(data.message != undefined) {u[j].message = data.message}
              }
          }
          
          
         
        });
        

        if (data.deploymentStatus == 1 || !data.deploymentStatus) {
          
          if (
            !data.statusCode ||
            data.statusCode == 200 ||
            data.statusCode == 201
          ) {
            this.toastr.success(
              data.botName + " (" + data.botId + ") : " + data.message
            );
          } else {
            this.toastr.error(
              data.botName + " (" + data.botId + ") : " + data.message
            );
          }

          if (this.router.url == "/manage-bots/editBots/" + data.botId) {
            this.router.navigate(["/manage-bots/editBots/" + data.botId]);
            // location.reload();
          }

          if (this.router.url == "/manage-bots") {
            this.router.navigate(["/manage-bots"]);
          }
        } else {
          this.toastr.error(
            data.botName + " (" + data.botId + ") : " + data.message
          );
        }
      });

    this.getKafkaSocketList();

    this.kafkaSocket
      .off("conversationupdate/" + this.userId)
      .on("conversationupdate/" + this.userId, (data) => {
        this.updateKafkaSocketList(data);
      });
  }

  updateKafkaSocketList(data) {
    var newData = JSON.parse(data);
    this.kafkaSocket.emit(
      "getConversationsHistory",
      JSON.stringify({
        token: this.kafkaToken,
        conversationId: newData.conversationId, //"",
        index: 0,
        size: 10,
      })
    );

    var passNewList;

    this.kafkaSocket
      .off("getConversationsHistory")
      .on("getConversationsHistory", (data) => {
        var newHistory = JSON.parse(data);

        passNewList = {
          newConversations: "yes",
          botId: newHistory.res.botId,
          data: [
            {
              botId: newHistory.res.botId, 
              userId: newHistory.res.userId, 
              conversationId: newHistory.res.conversationId, 
              pilotBotId: newHistory.res.pilotBotId, 
              pilotBotAavtar: newHistory.res.pilotBotAavtar, 
              pilotBotName: newHistory.res.pilotBotName, 
              channel: newHistory.res.channel, 
              connection: newHistory.res.connection, 
              topic: newHistory.res.topic, 
              handoffEvent: newHistory.res.handoffEvent, 
              handoffReqEvent: newHistory.res.handoffReqEvent, 
            },
          ],
        };

        /*************** new chat io */

        var topic = newHistory.res.handoffReqEvent;

        this.kafkaSocket.off(topic).on(topic, async (data) => {
          var newDataSave = {
            msg_type: "confirm",
            endconversation: "",
            count: 1,
            conversationId: data.conversationId,
            data: [
              {
                event: "user",
                timestamp: "", 
                metadata: {},
                text: data.message, // "new message",
                conversationId: data.conversationId,
              },
            ],
          };

          this.kafkaSocketDataDetails.emit(newDataSave);
        });

        // singleConversation.topic
        var singleTopic = newHistory.res.topic;

        this.kafkaSocket.off(singleTopic).on(singleTopic, async (data) => {
          var newData = JSON.parse(data);
          var newDataSaveUpdate = {
            msg_type: "single",
            endconversation: true,
            count: 1,
            data: [
              {
                event: newData.type, //"user",
                timestamp: newData.timestamp,
                metadata: {
                  attachmentKey:
                    newData &&
                    newData.metaData &&
                    newData.metaData.attachmentKey
                      ? newData.metaData.attachmentKey
                      : "",
                  data:
                    newData && newData.metaData && newData.metaData.data
                      ? newData.metaData.data
                      : "",
                  attachment:
                    newData && newData.metaData && newData.metaData.attachment
                      ? newData.metaData.attachment
                      : "",
                  fileName:
                    newData &&
                    newData.metaData &&
                    newData.metaData.attachmentKey
                      ? newData.metaData.attachmentKey
                      : "",
                },
                text: newData.message, // "new message",
                conversationId: newData.conversationId,
              },
            ],
          };

          this.kafkaSocketDataDetails.emit(newDataSaveUpdate);
        });

        

        var liveData = newHistory.res.handoffEvent;

        this.kafkaSocket.off(liveData).on(liveData, async (data) => {
          var newDataSave = {
            msg_type: "single",
            endconversation: data.endconversation,
            count: 1,
            data: [
              {
                event: "user",
                timestamp: data.timestamp ? data.timestamp : "",
                metadata: {
                  attachmentKey:
                    data && data.metadata && data.metadata.attachmentKey
                      ? data.metadata.attachmentKey
                      : "",
                  data:
                    data && data.metadata && data.metadata.data
                      ? data.metadata.data
                      : "",
                  attachment:
                    data && data.metadata && data.metadata.attachment
                      ? data.metadata.attachment
                      : "",
                  fileName:
                    data && data.metadata && data.metadata.attachmentKey
                      ? data.metadata.attachmentKey
                      : "",
                },
                text: data.text, // "new message",
                conversationId: data.conversationId,
              },
            ],
          };

          this.kafkaSocketDataDetails.emit(newDataSave);
        });
        /*************** End new chat io ***********/
      });

    setTimeout(() => {
      this.kafkaSocketDataList.emit(passNewList);
    }, 500);
  }

  getKafkaSocketList() {
    this.kafkaSocket.emit(
      "getConversations",
      JSON.stringify({
        token: this.kafkaToken,
        index: 0,
        size: 10,
      })
    );

    this.kafkaSocket.off("getConversations").on("getConversations", (data) => {
      this.kafkaSocketDataList.emit(JSON.parse(data));

      /***************************** Human Handoff **************** */
      let totalConversations = JSON.parse(data);

      for (var j = 0; j < totalConversations.data.length; j++) {
        for (
          var k = 0;
          k < totalConversations.data[j].conversations.length;
          k++
        ) {
          

          const singleConversation =
            totalConversations.data[j].conversations[k];
          const tokenConversation = singleConversation.conversationId;

          
          const topic = singleConversation.handoffReqEvent;
          

          this.kafkaSocket.off(topic).on(topic, (data) => {
            var newDataSave = {
              msg_type: "confirm",
              endconversation: "",              
            };
            this.kafkaSocketDataDetails.emit(newDataSave);
          });

          this.kafkaSocket
            .off(singleConversation.topic)
            .on(singleConversation.topic, (data) => {
              var newData = JSON.parse(data);

              var newDataSaveUpdate = {
                msg_type: "single",
                endconversation: true,
                count: 1,
                data: [
                  {
                    event: newData.type, //"user",
                    timestamp: newData.timestamp,
                    metadata: {
                      attachmentKey: newData.metaData.attachmentKey
                        ? newData.metaData.attachmentKey
                        : "",
                      data: newData.metaData.data ? newData.metaData.data : "",
                      attachment: newData.metaData.attachment
                        ? newData.metaData.attachment
                        : "",
                      fileName: newData.metaData.attachmentKey
                        ? newData.metaData.attachmentKey
                        : "",
                    },
                    text: newData.message, // "new message",
                    conversationId: newData.conversationId,
                  },
                ],
              };

              this.kafkaSocketDataDetails.emit(newDataSaveUpdate);
            });

          
          this.kafkaSocket
            .off(singleConversation.handoffEvent)
            .on(singleConversation.handoffEvent, (data) => {
              var newDataSave = {
                msg_type: "single",
                endconversation: data.endconversation,
                count: 1,
                data: [
                  {
                    event: "user",
                    timestamp: data.timestamp ? data.timestamp : "",
                    metadata: {
                      attachmentKey: data.metaData.attachmentKey
                        ? data.metaData.attachmentKey
                        : "",
                      data: data.metaData.data ? data.metaData.data : "",
                      attachment: data.metaData.attachment
                        ? data.metaData.attachment
                        : "",
                      fileName: data.metaData.attachmentKey
                        ? data.metaData.attachmentKey
                        : "",
                    },
                    text: data.text, // "new message",
                    conversationId: data.conversationId,
                  },
                ],
              };

              this.kafkaSocketDataDetails.emit(newDataSave);
            });

          // }
        }
      }
      /***************************** End Human Handoff **************** */
    });
  }

  confirmationEventTrue(data): any {
    this.kafkaSocket.emit("response/handoff", {
      message: "handoffRequested accepted",
      conversationId: data.conversationId, 
      accepted: true,
    });

    setTimeout(() => {
      this.kafkaSocket.emit("live/handoff-agent", {
        event: "agent",
        timestamp: data.timestamp,
        metadata: {
          
        },
        text: "Agent connected",
        conversationId: data.conversationId,
      });

      var newDataSave = {
        msg_type: "single",
        endconversation: false,
        count: 1,
        data: [
          {
            event: "bot",
            timestamp: data.timestamp,
            metadata: {},
            text: "Human handoff request accepted", 
            conversationId: data.conversationId,
          },
        ],
      };

      this.kafkaSocketDataDetails.emit(newDataSave);

      var newDataSave = {
        msg_type: "single",
        endconversation: false,
        count: 1,
        data: [
          {
            event: "bot",
            timestamp: data.timestamp,
            metadata: {},
            text: "Agent connected", 
            conversationId: data.conversationId,
          },
        ],
      };

      this.kafkaSocketDataDetails.emit(newDataSave);
    }, 1000);
  }

  confirmationEventFalse(data) {
    this.kafkaSocket.emit("response/handoff", {
      message: "handoffRequested declined",
      conversationId: data.conversationId, 
      accepted: false,
    });

    var newDataSave = {
      msg_type: "single",
      endconversation: false,
      count: 1,
      data: [
        {
          event: "bot",
          timestamp: data.timestamp,
          metadata: {},
          text: "Human handoff request declined", 
          conversationId: data.conversationId,
        },
      ],
    };

    this.kafkaSocketDataDetails.emit(newDataSave);
  }

  endConversationSocket(data): any {
    //normal chat
    this.kafkaSocket.emit("agent/endConversation", {
      event: "user",
      timestamp: "",
      metadata: {
        
      },
      text: "End conversation",
      conversationId: data.conversationId,
      endconversation: true,
    });

    this.kafkaSocket.emit("live/handoff-agent", {
      event: "user",
      timestamp: "",
      metadata: {
        
      },
      text: "End conversation",
      conversationId: data.conversationId,
      endconversation: true,
    });

    //Conversation ended.

    var newDataSave = {
      msg_type: "single",
      endconversation: false,
      count: 1,
      data: [
        {
          event: "user",
          timestamp: "",
          metadata: {},
          text: "Conversation ended", 
          conversationId: data.conversationId,
        },
      ],
    };

    this.kafkaSocketDataDetails.emit(newDataSave);
  }
  takeOverConversation(data): any {
    this.kafkaSocket.emit("takeover/handoff", {
      conversationId: data.conversationId,
    });
  }

  getKafkaHumanSocketData(newMessage): any {
    setTimeout(() => {
      this.kafkaSocket.emit("live/handoff-agent", {
        event: "agent",
        timestamp: "",
        metadata: {
          
        },
        text: newMessage.content,
        conversationId: newMessage.receiver.conversationId,
      });
    }, 500);
  }

  getConversationsHistory(coversationId, pageNo = 0) {
    //if user need the history of chat then use this event

    this.kafkaSocket.emit(
      "getConversationsHistory",
      JSON.stringify({
        token: this.kafkaToken,
        conversationId: coversationId,
        index: pageNo,
        size: 10,
      })
    );

    this.kafkaSocket
      .off("getConversationsHistory")
      .on("getConversationsHistory", (data) => {
        this.kafkaSocketDataDetails.emit(JSON.parse(data));
      });
  }

  deleteConversations(coversationId) {
    this.kafkaSocket.emit(
      "deleteConversation",
      JSON.stringify({
        token: this.kafkaToken,
        conversationId: coversationId,
      })
    );

    this.kafkaSocket
      .off("deleteConversation")
      .on("deleteConversation", (data) => {
        var data = JSON.parse(data);
        this.toastr.success(data.message);
      });
  }

  getSocketData(): Observable<any> {
    this.socket.on(this.userId, (res) => {
      // this.toastr.success("You get a new notification");

      this.emitSocketData(res);
      this.getToastNotification(res);
      if (res.notification) {
        this.getNotificationCount(res);
      }
      this.observer.next(res);
    });
    return this.getSocketDataObservable();
  }

  getToastNotification(res) {
    if (
      (res &&
        res.result &&
        res.result[0] &&
        res.result[0].sendBy !== this.userId) ||
      (res.status &&
        res.status.type &&
        (res.status.type === "group" || res.status.type === "message") &&
        res.status.data &&
        res.status.data &&
        res.status.data.sendBy !== this.userId &&
        res.status.data.chatID &&
        res.status.data.chatID !== this.chatOpened)
    ) {
      

      if (!this.router.url.includes("/chat")) {
        this.updateAllChatNotification();
      }
    }
  }

  updateAllChatNotification() {
    if (this.userId) {
      this.chatService.getAllUnreadMessage(this.userId).then((res) => {
        if (res.data) {
          this.api.totalUnreadMessageCount.emit(res.data);
        }
      });
    }
  }

  getSocketDataObservable(): Observable<any> {
    return new Observable((observer) => {
      this.observer = observer;
    });
  }

  getNotificationCount(res): void {
    if (res) {
      this.notification.emit(res);
    }
  }

  emitSocketData(res) {
    this.socketData.emit(res);
    this.notification.emit(res);
  }
}
