export class GetAllBots {
  static readonly type = "[GETALLBOTS] GET_ALL_BOTS";
  constructor(public response?: string) {}
}
export class GetAllTraining {
  static readonly type = "[GETALLTRAINING] GET_ALL_TRAINING";
  constructor(public response?: string) {}
}

export class GetAllBotsSuccess {
  static readonly type = "[GETALLBOTS] GET_ALL_BOTS_SUCCESS";
  constructor(public response: any) {}
}

export class GetAllBotsFailed {
  static readonly type = "[GETALLBOTS] GET_ALL_BOTS_FAILED";
  constructor(public response?: any) {}
}

export class GetAvatars {
  static readonly type = "[GETAVATARS] GET_AVATARS";
  constructor(public response?: string) {}
}

export class GetAvatarsSuccess {
  static readonly type = "[GETAVATARS] GET_AVATARS_SUCCESS";
  constructor(public response: any) {}
}

export class GetAvatarsFailed {
  static readonly type = "[GETAVATARS] GET_AVATARS_FAILED";
  constructor(public response?: any) {}
}

// new api added owner_bots
export class OwnerBots {
  static readonly type = "[OWNERBOTS] OWNER_BOTS";
  constructor(public response?: string) {}
}
export class OwnerBotsSuccess {
  static readonly type = "[OWNERBOTS] OWNER_BOTS_SUCCESS";
  constructor(public response: any) {}
}
export class OwnerBotsFailed {
  static readonly type = "[OWNERBOTS] OWNER_BOTS_FAILED";
  constructor(public response?: any) {}
}

export class GetAllDelegateBots {
  static readonly type = "[GETALLDELEGATEBOTS] GET_ALL_DELEGATE_BOTS";
  constructor(public response?: string) {}
}
export class GetDelegateBotsDropdown {
  static readonly type = "[GETDELEGATEBOTSDROPDOWN] GET_DELEGATE_BOTS_DROPDOWN";
  constructor(public response?: string) {}
}

export class GetAllIntegrationsBots {
  static readonly type = "[GETALLINTEGRATIONSBOTS] GET_ALL_INTEGRATIONS_BOTS";
  constructor(public response?: string) {}
}
