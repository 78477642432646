import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Data } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationModalComponent } from "../confirmation-modal/confirmation-modal.component";
import { ApiService } from "app/services/api.service";
import { ToastrService } from "ngx-toastr";
import { MutualListModalComponent } from "../mutual-list-modal/mutual-list-modal.component";

@Component({
  selector: "app-invitation-list-modal",
  templateUrl: "./invitation-list-modal.component.html",
  styleUrls: ["./invitation-list-modal.component.scss"],
})
export class InvitationListModalComponent {
  public getMutualListData: any;

  public defaultImg: any = "/assets/images/avatars/no-photo.png";
  public invitationsIndex: number = 0;

  constructor(
    public dialogRef: MatDialogRef<Data>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    public dialog: MatDialog,
    public toastr: ToastrService
  ) {}

  decline(): void {
    this.dialogRef.close(false);
  }

  openAcceptInvitationModal(data, i): void {
    this.invitationsIndex = i;
    const sendData = {
      header: "Accept Invitation",
      body: "Are you sure you want to accept the invitation ?",
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: "450px",
      data: sendData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const sendData_ = {
          loggedin: data.invitee,
          tochange: data.sender,
          state: "accepted",
          _id: data._id,
          userName:
            data.userDetails.length > 0
              ? data.userDetails[0].userFirstName +
                " " +
                data.userDetails[0].userLastName
              : "",
        };
        this.acceptInvitation(sendData_);
      }
    });
  }

  openIgnoreInvitationModal(data, i): void {
    this.invitationsIndex = i;

    const sendData = {
      header: "Decline Invitation",
      body: "Are you sure you want to decline the invitation ?",
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: "450px",
      data: sendData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const sendData_ = {
          loggedin: data.invitee,
          tochange: data.sender,
          state: "ignored",
          _id: data._id,
          userName:
            data.userDetails.length > 0
              ? `${data.userDetails[0].userFirstName} ${data.userDetails[0].userLastName}`
              : "", //data.senderDetail[0].userName
        };

        this.ignoreInvitation(sendData_);
      }
    });
  }

  getMutualList(data: any): void {
    this.getMutualListData = data.mutuals;
    const dialogRef = this.dialog.open(MutualListModalComponent, {
      width: "450px",
      //data: this.getMutualListData
      data: {
        MutualCount: this.getMutualListData.length,
        MutualconList: this.getMutualListData,
      },
    });
  }

  updateStatus(data: any) {
    const userIndex = this.invitationsIndex;

    this.data.splice(userIndex, 1);

    if (data.state === "accepted") {
      this.toastr.success(data.userName + " Invitation Accepted");
    } else if (data.state === "ignored") {
      this.toastr.success(data.userName + "Invitation Ignored");
    }
  }

  acceptInvitation(data: any) {
    this.api.acceptInvitation(data._id).then((response: any) => {
      this.updateStatus(data);
    });
  }

  ignoreInvitation(data: any) {
    this.api.ignoreInvitation(data._id).then((response: any) => {
      this.updateStatus(data);
    });
  }

  cancelInvitation(data: any) {
    this.api.cancelInvitation(data._id).then((response: any) => {
      this.updateStatus(data);
    });
  }

  removeInvitation(data: any) {
    this.api.removeInvitation(data._id).then((response: any) => {
      this.updateStatus(data);
    });
  }
}
