import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { Data } from "@angular/router";
import { ConfirmationModalComponent } from "app/main/shared-module/common/confirmation-modal/confirmation-modal.component";
import { MutualListModalComponent } from "app/main/shared-module/common/mutual-list-modal/mutual-list-modal.component";
import { ApiService } from "app/services/api.service";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../../../../environments/environment";
//import { locale as english } from "../../i18n/en";
//import { locale as turkish } from "../../i18n/tr";

@Component({
  selector: "app-sent-invitation-modal-list",
  templateUrl: "./sent-invitation-modal-list.component.html",
  styleUrls: ["./sent-invitation-modal-list.component.scss"],
})
export class SentInvitationModalListComponent {
  public getMutualListData: any;
  public defaultImg: any = "/assets/images/avatars/no-photo.png";
  public cancelIndex: number = 0;

  constructor(
    public dialogRef: MatDialogRef<Data>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    public toastr: ToastrService,
    public dialog: MatDialog
  ) {}

  decline(): void {
    this.dialogRef.close(false);
  }

  getMutualList(data: any): void {
    this.getMutualListData = data.mutuals;
    const dialogRef = this.dialog.open(MutualListModalComponent, {
      width: "550px",
      //data: this.getMutualListData
      data: {
        MutualCount: this.getMutualListData.length,
        MutualconList: this.getMutualListData,
      },
    });
  }

  openCancelInvitationModal(data, i): void {
    this.cancelIndex = i;
    const sendData = {
      header: "Cancel Invitation",
      body: "Are you sure you want to cancel the invitation ?",
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: "450px",
      data: sendData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const sendData_ = {
          loggedin: environment.userId,
          tochange: data.sender,
          state: "cancelled",
          userName:
            data.userDetails.length > 0
              ? `${data.userDetails[0].userFirstName} ${data.userDetails[0].userLastName}`
              : "",
          _id: data._id,
        };
        this.cancelInvitation(sendData_);
      }
    });
  }

  sendInvitation(data): void {
    this.api.sendInvitationStatus(data).then((response: any) => {
      this.data.map((connectionItem, index_) => {
        if (connectionItem.user_id === data.invitee) {
          this.data[index_]["pending"] = true;
        }
      });

      if (response.success && data.userName) {
        this.toastr.success("Request Sent to " + data.userName);
      }

      if (!response.success) {
        this.toastr.warning(response.msg);
      }
    });
  }

  cancelInvitation(data: any) {
    this.api.cancelInvitation(data._id).then((response: any) => {
      if (response.success) {
        const removedIndex = this.data.findIndex(
          (f) => f.user_id === data.user_id
        );
        this.data[this.cancelIndex]["cancelled"] = true;
        this.data.splice(this.cancelIndex, 1);

        //this.data[removedIndex]["cancelled"] = true;
        //this.data.splice(removedIndex, 1);
        this.toastr.success(`${data.userName} invitation` + " is cancelled");
        this.cancelIndex = 0;
      } else {
        this.toastr.error(response.msg);
      }
    });
  }
}
