import {
  Component,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import {
  HttpClient,  
  HttpResponse,
  HttpEventType,
} from "@angular/common/http";
import { environment } from "environments/environment";
import { MatDialog } from "@angular/material";
import { ImageCropperComponent } from "./image-cropper/image-cropper.component";
import { SharedApiService } from "../../shared-module/services/shared.api.service";

@Component({
  selector: "file-upload-login",
  templateUrl: "./file-upload-component.html",
  styleUrls: ["./file-upload-component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploadLoginComponent {
  percentDone: number;
  uploadSuccess: boolean;
  @ViewChild("input") input: any;
  @Input("text") text: string;
  @Input("key") key: string;
  @Output() onFileUploaded = new EventEmitter();

  constructor(
    private http: HttpClient,
    private api: SharedApiService,
    public dialog: MatDialog
  ) {}

  chooseFile(e: any) {
    e.preventDefault();
    this.input.nativeElement.click();
  }

  readURL = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(reader.result);
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  };

  dataURItoBlob(dataURI, file: File) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: file.type });
    return blob;
  }

  async upload(files: File[]) {
    //pick from one of the 4 styles of file uploads below

    if (this.key != "1") {
      this.uploadAndProgressSingle(files[0]);
    } else {
      const url = await this.readURL(files[0]);
      const dialogRef: any = this.dialog.open(ImageCropperComponent, {
        disableClose: false,
      });
      dialogRef.componentInstance.imageSource = String(url);
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result && result.content != "") {
          var content = String(result.content);
          var index = content.indexOf("base64,");
          var substr = content.substr(index + 7, content.length);
          const imageBlob = this.dataURItoBlob(substr, files[0]);
          var newFilename =
            this.newGuid() + "." + files[0].name.split(".").pop();

          const imageFile = new File([imageBlob], newFilename, {
            type: files[0].type,
          });
          this.uploadAndProgressSingle(imageFile);
        } else {
          var param = { fileId: "", file: "" };
          this.onFileUploaded.emit(param);
        }
      });
    }
  }

  newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  //this will fail since file.io dosen"t accept this type of upload
  //but it is still possible to upload a file with this style
  uploadAndProgressSingle(file: File) {
    var formData = new FormData();
    formData.append("file", file);
    this.http
      .post(environment.baseUrl + "/file/upload", formData, {
        reportProgress: true,
        observe: "events",
      })
      .subscribe((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.percentDone = Math.round((100 * event.loaded) / event.total);
        } else if (event instanceof HttpResponse) {
          this.uploadSuccess = true;
          var param = { fileId: event.body.file, file: file };
          this.onFileUploaded.emit(param);
        }
      });
  }
}
