import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../../../services/api.service";
import {  MatTableDataSource } from "@angular/material";

@Component({
  selector: "app-user-bot",
  templateUrl: "./user-bot.component.html",
  styleUrls: ["./user-bot.component.scss"],
})
export class UserBotComponent implements OnInit {
  @ViewChild("tabGroup") tabGroup;

  pramsuserid: any;
  public selectedIndex: Number = 0;
  botSuccess: any;
  botHide: boolean = false;
  botRestrict: any;
  getBotData: any;
  botList: MatTableDataSource<PeriodicElement>;

  constructor(private route: ActivatedRoute, private api: ApiService) {}

  ngOnInit() {
    const paramsData = this.route.snapshot.params;
    this.pramsuserid = paramsData.id;

    let tabUrl = this.route.url["value"][0].path;
    if (tabUrl == "users-bot") {
      this.api.getBotsByUsrId("MAIN", this.pramsuserid).then((getBotsData) => {
        // Magane-Bots For API //
        this.botSuccess = getBotsData.success;
        if (this.botSuccess == false) {
          this.botHide = true;
          this.botRestrict = getBotsData.msg;
        } else if (this.botSuccess == true) {
          this.botHide = false;
          this.getBotData = getBotsData.data;
          const ELEMENT_DATA: PeriodicElement[] = this.getBotData;
          this.botList = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
        }
      });
    }
  }
}

export interface PeriodicElement {
  botId: number;
  createdAt: string;
  name: string;
  entity: any;
  process: any;
  activate: string;
  geoTag: string;
  action: string;
}
