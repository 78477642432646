import { Router } from "@angular/router";
import { APP_ROUTES } from "app/main/shared-module/constants/AppRoutes";
import { CookieService } from "ngx-cookie-service";

export default class NavigatePages {
  constructor(private router: Router, private cookieService: CookieService) {}

  public routePage(pageUrl: string, params = null) {
    this.router.navigateByUrl(pageUrl);
    let loginFlag = this.cookieService.get("token");
    if (loginFlag) {
      let userStorage = JSON.parse(this.cookieService.get("user"));

      let userFlag = userStorage.userKYCFlag || userStorage.user.userKYCFlag;
      if (
        params &&
        params.updateValidateKYC &&
        (userFlag === undefined || !userFlag)
      ) {
        this.router.navigateByUrl("/" + APP_ROUTES.COMPLETE_PROFILE);
      } else {
        if (params != null)
          this.router.navigate([pageUrl], { queryParams: params });
        else this.router.navigateByUrl(pageUrl);
      }
    } else {
      this.router.navigateByUrl("/" + APP_ROUTES.LOGIN);
    }
  }
}
