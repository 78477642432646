import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-phrase-dialog',
  templateUrl: './delete-phrase-dialog.component.html',
  styleUrls: ['./delete-phrase-dialog.component.scss']
})
export class DeletePhraseDialogComponent implements OnInit {

  public dialogType: string;
  public dialogTitle: string;
  public dialogMessage: string;
  constructor() {}
  ngOnInit() {
    this.dialogType = localStorage.getItem("dialogType");
    this.dialogTitle = localStorage.getItem("dialogTitle");
    this.dialogMessage = localStorage.getItem("dialogMessage");
  }

}
