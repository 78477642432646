import { User, Otp } from "./user.model";

export class SetUser {
  static readonly type = "[User] Set Store";

  constructor(public payload: User) {}
}
export class SetOtp {
  static readonly type = "[Otp] Otp Store";

  constructor(public payload: Otp) {}
}

export class UpdateProfileUser {
  static readonly type = "[User] Update Profile";

  constructor(public payload: any, public isNewUSer: boolean) {}
}

export class CompleteProfileUser {
  static readonly type = "[User] complete Profile";

  constructor(public payload: any, public isNewUSer: boolean) {}
}

export class VerifyOTP {
  static readonly type = "[User] Verify OTP";
  constructor(public payload: any) {}
}

export class VerifyOTPCancel {
  static readonly type = "[User] Verify OTP Cancel";
  constructor(public payload: any) {}
}

export class GetOTP {
  static readonly type = "[User] Get OTP";

  constructor(public payload: any) {}
}
