import { MaterialModule } from "../../../../app/main/shared-module/modules/material.module";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatToolbarModule,
  MatInputModule,
  MatFormFieldModule,
  MatListModule,
  MatSelectModule,
  MatCardModule,
  MatProgressSpinnerModule,
} from "@angular/material";

import { FuseSearchBarModule, FuseShortcutsModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";

import { ToolbarComponent } from "app/layout/components/toolbar/toolbar.component";
import { DiagnosticInfoComponent } from "app/layout/components/diagnostic-info/diagnostic-info.component"
import { SearchbarfilterComponent } from "app/main/shared-module/common/searchbarfilter/searchbarfilter.component";
// import { ProfileComponent } from "app/main/profile/profile.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

const routes: Routes = [
  { path: "searchbarfilter", component: SearchbarfilterComponent },
  // {path: "search", component: ProfileComponent}
];

@NgModule({
  declarations: [ToolbarComponent, SearchbarfilterComponent],
  imports: [
    RouterModule.forRoot(routes),
    //RouterModule
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    InfiniteScrollModule,
    MaterialModule
  ],
  exports: [ToolbarComponent, SearchbarfilterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ToolbarModule {
  chatnotificationCount = 2;
  notificationCount = true;
}
