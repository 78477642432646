import {
  Component,
  ViewEncapsulation,  
} from "@angular/core";
import { FuseConfigService } from "@fuse/services/config.service";
import { Actions,  Store } from "@ngxs/store";
import { ActivatedRoute, Router } from "@angular/router";
import { CompleteProfileUser, SetUser } from "app/store/user/user.actions";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatDialog,  } from "@angular/material";
import { fuseAnimations } from "../../../../@fuse/animations/index";
import csc from "country-state-city";
import { ApiService } from "app/services/api.service";
import { Navigate } from "@ngxs/router-plugin";
import { ToastrService } from "ngx-toastr";
import { APP_ROUTES } from "app/main/shared-module/constants/AppRoutes";
import { CookieService } from 'ngx-cookie-service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: "complete-profile",
  templateUrl: "./complete-profile.component.html",
  styleUrls: ["./complete-profile.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class CompleteProfileComponent {
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  title: String = "CREATE AND ACCOUNT";
  genders = ["Male", "Female", "Others"];
  config: any;
  gender: any;
  kycDocs: any = {};
  userFromLocation: null;
  update = false;
  formaticEmailaddress: any;
  countryCodes: Array<any> = csc.getAllCountries();
  step: number = 1;
  showKYCStep: boolean = false;
  params: any;
  public imageFormData: any;

  emailFormControl = new FormControl({ value: "" }, [
    Validators.required,
    Validators.email,
  ]);

  genderFormControl = new FormControl("", [Validators.required]);

  firstNameFormControl = new FormControl("", [Validators.required]);

  lastNameFormControl = new FormControl("", [Validators.required]);

  locationFormControl = new FormControl("", [Validators.required]);

  photoFormControl = new FormControl("", [Validators.required]);

  dobFormControl = new FormControl("", [Validators.required]);

  kycFormControl = new FormControl("", [Validators.required]);

  // tslint:disable-next-line:variable-name
  MyErrorStateMatcher: string;

  public image: string = "assets/images/avatars/no-photo.png";
  imageDisplay: string = "block";

  public imageNew: string = "assets/images/avatars/no-photo.png";

  constructor(
    private _fuseConfigService: FuseConfigService,
    public actions: Actions,
    private router: Router,
    public store: Store,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private api: ApiService,
    private toastr: ToastrService,
    private cookieService: CookieService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.params = params;
      if (params.showKYCStep) {
        this.showKYCStep = params.showKYCStep;
        this.step = 2;
      }
    });

    this.title = "UPDATE YOUR ACCOUNT";

    //   }
    // });

    // Fully customizable surroundings for this particular component
    this._fuseConfigService.config = {
      layout: {
        style: "vertical-layout-1",
        width: "fullwidth",
        navbar: {
          primaryBackground: "fuse-navy-700",
          secondaryBackground: "fuse-navy-900",
          folded: false,
          hidden: true,
          position: "left",
          variant: "vertical-style-1",
        },
        toolbar: {
          customBackgroundColor: false,
          background: "fuse-white-500",
          hidden: true,
          position: "below-static",
        },

        sidepanel: {
          hidden: true,
          position: "right",
        },
      },
      customScrollbars: true,
    };
  }

  ngOnInit() {
    this.store
      .select((s) => s.user.user)
      .subscribe((u) => {
        if (u == null) {
          this.store.dispatch(new Navigate(["/login"]));
          return;
        }

        if (
          u.completeProfileFlag == undefined ||
          u.completeProfileFlag == false
        ) {
          this.store.dispatch(
            new Navigate(["/" + APP_ROUTES.COMPLETE_PROFILE])
          );
        } else {
          this.store.dispatch(new Navigate(["/profile"]));
        }
      });

    this.store
      .select((s: any) => s.user.user)
      .subscribe(async (user: any) => {
        this.emailFormControl.setValue(user.userEmailID);
        this.firstNameFormControl.setValue(user.userFirstName);
        this.lastNameFormControl.setValue(user.userLastName);
        this.locationFormControl.setValue(user.userFromLocation);
        this.dobFormControl.setValue(user.userBirthDate);
        this.genderFormControl.setValue(user.userGender);
        this.gender = user.userGender;
        if (user.userEmailID) {
          this.emailFormControl.disable();
        }
        if (user.userKYCFlag) {
          if (user.userFirstName) {
            if (user.userFirstName != undefined && user.userFirstName != "") {
              this.firstNameFormControl.disable();
            }
          }
          if (user.userLastName) {
            if (user.userLastName != undefined && user.userLastName != "") {
              this.lastNameFormControl.disable();
            }
          }
          if (user.userFromLocation) {
            if (
              user.userFromLocation != undefined &&
              user.userFromLocation != ""
            ) {
              this.locationFormControl.disable();
            }
          }
          if (user.userBirthDate) {
            if (user.userBirthDate != undefined && user.userBirthDate != "") {
              this.dobFormControl.disable();
            }
          }
          if (user.userGender) {
            if (user.userGender != undefined && user.userGender != "") {
              this.genderFormControl.disable();
            }
          }
        }

        if (user !==null && user.profilePicture) {
          this.imageNew = user.profilePicture;
        } else {
          this.imageNew = "assets/images/avatars/no-photo.png";
        }
        this.imageDisplay = "block";
      });
  }

  onInit() {
    // Subscribe to config change
    this._fuseConfigService.config.subscribe((config) => {
      this.config = config;
    });
  }

  genderSelected(g, gender) {
    return g == gender ? "checked" : null;
  }

  async updateFileID(data, key) {
    this.imageDisplay = "block";
    if (data.fileId != "") {
      this.kycDocs[key] = data.fileId;
      const url = await this.readURL(data.file);
      this.image = String(url);
      this.imageNew = String(url); // data.file;
      this.imageFormData = new FormData();
      this.imageFormData.append("file", data.file);
    }
  }

  getImageNew() {
    if (this.imageNew == undefined || this.imageNew == "") {
      return "url(assets/images/avatars/no-photo.png)";
    }
    return "url(" + this.imageNew + ")";
  }

  ngOnDestroy() {
    this.dialog.closeAll();
  }

  submitForm1(event: any) {
    if (event.keyCode == 13) {
      return false;
    }
    this.submitRecord1();
  }
  async submitRecord1() {
    if (
      this.firstNameFormControl.value == "" ||
      this.firstNameFormControl.value == null ||
      this.lastNameFormControl.value == "" ||
      this.lastNameFormControl.value == null ||
      this.dobFormControl.value == "" ||
      this.dobFormControl.value == null ||
      this.genderFormControl.value == "" ||
      this.genderFormControl.value == null ||
      this.locationFormControl.value == "" ||
      this.locationFormControl.value == null ||
      this.emailFormControl.value == "" ||
      this.emailFormControl.value == null
    ) {
      return;
    }

    let values = {
      user: {
        userFirstName: this.firstNameFormControl.value,
        userLastName: this.lastNameFormControl.value,
        userGender: this.genderFormControl.value,
        userFromLocation: this.locationFormControl.value,
        userBirthDate: this.dobFormControl.value,
      },
      token: this.store.selectSnapshot((state: any) => state.login.token),
      update: this.update,
    };

    if (this.imageFormData != undefined) {
      let dt = await this.api
        .createProfileImage(this.imageFormData)
        .then((response) => {
          if (response.success) {
          }
        });
    }

    this.store
      .dispatch(new CompleteProfileUser(values, this.step === 1 ? true : false))
      .subscribe((result) => {
        if (result) {
          this.store
            .select((s) => s.user.user)
            .subscribe((u) => {
              var profileObject = {
                otp: null,
                user: u,
              };
              profileObject.user.completeProfileFlag = true;
              localStorage.setItem("user", JSON.stringify(profileObject));
              this.cookieService.set('user', JSON.stringify(profileObject));
              this.store.dispatch(new SetUser(profileObject.user));
            });
        }
        if (this.step === 1) {
          return;
        }
      });
  }

  readURL = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(reader.result);
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  };

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/jpeg" });
    return blob;
  }
}
