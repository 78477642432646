import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FuseSharedModule } from "@fuse/shared.module";
import { CompleteProfileComponent } from "./complete-profile.component";
import { FileUploadLoginComponent } from "./file-upload-component";
import {
  MatButtonModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatTableModule,
  MatTabsModule,
  MatInputModule,
  MatCardModule,
  MatRadioModule,
  MatDatepickerModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
} from "@angular/material";
import { ImageCropperComponent } from "./image-cropper/image-cropper.component";
import { CommonModule } from "@angular/common";
import { APP_ROUTES } from "app/main/shared-module/constants/AppRoutes";

const routes = [
  {
    path: APP_ROUTES.COMPLETE_PROFILE,
    component: CompleteProfileComponent,
  },
  {
    path: APP_ROUTES.UPDATE_PROFILE,
    component: CompleteProfileComponent,
  },
  // {
  //   path: APP_ROUTES.VERIFY_EMAIL,
  //   component: VerifyEmailComponent,
  // },
];

@NgModule({
  declarations: [
    CompleteProfileComponent,
    FileUploadLoginComponent,
    ImageCropperComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatInputModule,
    MatCardModule,
    MatRadioModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
  ],
  exports: [CompleteProfileComponent, FileUploadLoginComponent],
  entryComponents: [ImageCropperComponent],
})
export class CompleteProfileModule {}
