import { Component, OnInit, Inject } from "@angular/core";
import { ApiService } from "app/services/api.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Data } from "@angular/router";

@Component({
  selector: "app-mutual-list-modal",
  templateUrl: "./mutual-list-modal.component.html",
  styleUrls: ["./mutual-list-modal.component.scss"],
})
export class MutualListModalComponent implements OnInit {
  public dataMutualList: any = [];
  getMutualData: any;
  getMutualCount: any;
  MutualconList: any;

  public defaultImg: any = "/assets/images/avatars/no-photo.png";

  constructor(
    public dialogRef: MatDialogRef<Data>,
    @Inject(MAT_DIALOG_DATA)
    public data: Array<any>,
    public api: ApiService
  ) {}

  ngOnInit() {
    this.getMutualData = this.data["MutualconList"];
    this.getMutualCount = this.data.length;
  }

  getDefaultImage() {
    return "url(" + "../../../../assets/avatars/User_Avatar.png" + ")";
  }

  decline(): void {
    this.dialogRef.close(false);
  }
}
