import { Component, OnInit } from "@angular/core";
import { ApiService } from "app/services/api.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationModalComponent } from "../confirmation-modal/confirmation-modal.component";
import { countryCodes } from "../../../../utils/countryCodes";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import { FormControl, FormBuilder } from "@angular/forms";
import { SearchUserListModalComponent } from "./search-user-list-modal/search-user-list-modal.component";
import { MutualListModalComponent } from "../mutual-list-modal/mutual-list-modal.component";
import { fuseAnimations } from "../../../../../@fuse/animations/index";
import NavigatePages from "../../../../utils/navigatePages";
import { Router } from "@angular/router";
import { MarketBotDialogComponent } from "app/main/shared-module/common/market-bot-dialog/market-bot-dialog.component";
import { MarketEntityDialogComponent } from "app/main/shared-module/common/market-entity-dialog/market-entity-dialog.component";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-searchbarfilter",
  templateUrl: "./searchbarfilter.component.html",
  styleUrls: ["./searchbarfilter.component.scss"],
  animations: fuseAnimations,
})
export class SearchbarfilterComponent implements OnInit {
  public dataSend: any;
  public userId: any = environment.userId;
  public typesOfSearchFilter: string[] = ["Users", "Bots", "Entities"];
  public checkBoxBlank: any = [];
  public filterData: any;
  public filterDataGroup: any;
  public searchInput: any;
  public filterShow: Boolean = false;
  public userLoader: Boolean = true;
  public country: any;
  public userData: any;
  public showInputData: any = -1;
  public showData: any = false;
  public searchData: any = [];
  public searchUserIndex: any = 0;
  public searchUserDataList: any = [];
  public sendData_: any;
  filterUserData: any;
  botFilterData: any;
  mutualCount: any;
  searchBotCount: any;
  userCount: any;
  entityCount: any;
  entityFilterData: any;
  entityData: any;
  domainData: any;
  processData: any;
  CharacteristicData: any;
  mutualsData: any;
  allSearchData: any;
  usrindexof: number;
  entityindexof: number;
  botindexof: number;
  request_sent: any;
  connected: any;

  firstUserData: any;
  firstBotData: any;
  firstEntityData: any;

  totalSkip: number = 0;
  totalUserSkip: number = 0;
  totalBotSkip: number = 0;
  totalEntitySkip: number = 0;
  userCountMore: boolean = true;
  botCountMore: boolean = true;
  entityCountMore: boolean = true;
  filterTypeData: any;

  constructor(
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public dialog: MatDialog,
    public toastr: ToastrService,
    public router: Router,
    private cookieService: CookieService
  ) {}
  selectedOptions: string[] = ["Users", "Entities", "Bots"];

  ngOnInit() {
    let navigatePages = new NavigatePages(this.router, this.cookieService);
    navigatePages && navigatePages.routePage("/searchbarfilter");

    this.showLoader("userLoader");
    this.showInputData += 1;
    this.searchData = this.apiService.passInputValue();
    this.searchInput = new FormControl(this.searchData);
    this.filterDataGroup = this.formBuilder.group({
      filterEntityContents: [],
      filterDomainContents: [],
      filterCharacteristicContents: [],
      filterProcessContents: [],
      filterLocationContents: [],
    });
    this.country = countryCodes;
    this.getFilterEntityContent();
    this.getDomainContent();
    this.getProcessContent();
    this.getCharacteristicContent();
    if (this.searchData) {
      this.search(this.filterDataGroup, this.searchData);
    }
  }

  getFilterEntityContent(): void {
    this.apiService.getFilterEntityContent().then((data: any) => {
      this.entityData = data.data;
      this.hideLoader("userLoader");
    });
  }

  getDomainContent(): void {
    this.apiService.getDomainContent().then((data: any) => {
      this.domainData = data.data;
      this.hideLoader("userLoader");
    });
  }

  getProcessContent(): void {
    this.apiService.getProcessContent().then((data: any) => {
      this.processData = data.data;
      this.hideLoader("userLoader");
    });
  }

  getCharacteristicContent(): void {
    this.apiService.getCharacteristicContent().then((data: any) => {
      this.CharacteristicData = data.data;
      this.hideLoader("userLoader");
    });
  }

  openAcceptInvitationModal(data): void {
    const sendData = {
      header: "Accept Invitation",
      body: "Are you sure you want to accept the invitation ?",
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: "450px",
      data: sendData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sendData_ = {
          loggedin: environment.userId,
          tochange: data.sender,
          state: "accepted",
          userName: data.senderDetail[0].userName,
          _id: data._id,
        };
        this.updateInvitationStatus(this.sendData_);
      }
    });
  }

  openIgnoreInvitationModal(data): void {
    const sendData = {
      header: "Decline Invitation",
      body: "Are you sure you want to decline the invitation ?",
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: "450px",
      data: sendData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sendData_ = {
          loggedin: environment.userId,
          tochange: data.sender,
          state: "ignored",
          userName: data.senderDetail[0].userName,
          _id: data._id,
        };
        this.updateInvitationStatus(this.sendData_);
      }
    });
  }

  updateInvitationStatus(data): void {
    this.apiService.updateInvitationStatus(data).then((response: any) => {
      if (data.state === "accepted") {
        this.userData.map((item, i) => {
          if (data.tochange === item.sender) {
            this.userData[i]["accepted"] = true;
          }
        });
      } else if (data.state === "ignored") {
        this.userData.map((item, i) => {
          if (data.tochange === item.sender) {
            this.userData[i]["ignored"] = true;
          }
        });
      }
      if (data.state === "accepted") {
        this.toastr.success(data.userName + " Invitation Accepted ");
      } else if (data.state === "ignored") {
        this.toastr.success(data.userName + " Invitation Ignored");
      }
    });
  }

  openToConnectModal(_data, request_sent): void {
    const sendData = {
      header: "Connect",
      body: "Are you sure you want to send the invitation ?",
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: "450px",
      data: sendData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (!_data.sender) {
          const sendData_ = {
            sender: environment.userId,
            invitee: _data._id,
            state: "pending",
            userName: _data.userName,
          };
          this.sendInvitation(sendData_);
        } else if (_data.sender) {
          if (_data.sender === environment.userId) {
            const sendData_ = {
              sender: environment.userId,
              invitee: _data.invitee,
              state: "pending",
              userName: _data.userName,
            };
            this.sendInvitation(sendData_);
          } else if (_data.invitee === environment.userId) {
            const sendData_ = {
              sender: environment.userId,
              invitee: _data.sender,
              state: "pending",
              userName: _data.userName,
            };
            this.sendInvitation(sendData_);
          }
        }
      }
    });
  }

  sendInvitation(data: any): void {
    this.apiService.sendInvitationStatus(data).then((response: any) => {
      this.searchUserDataList.map((connectionItem, index_) => {
        if (connectionItem._id === data.invitee) {
          this.searchUserDataList[index_]["pending"] = true;
        }
      });
      this.searchUserDataList.map((connectionItem, index_) => {
        if (connectionItem.sender === data.invitee) {
          this.searchUserDataList[index_]["pending"] = true;
        }
      });
      this.searchUserDataList.map((connectionItem, index_) => {
        if (connectionItem.invitee === data.invitee) {
          this.searchUserDataList[index_]["pending"] = true;
        }
      });

      if (response.success) {
        this.search(this.filterDataGroup, this.searchInput.value);
        this.toastr.success(response.msg);
      }

      if (!response.success) {
        this.toastr.warning(response.msg);
      }
    });
  }

  search(data: any, _data: any): void {
    this.filterTypeData = data;

    this.showLoader("userLoader");
    this.showData = true;
    this.searchUserIndex = 0;
    this.totalSkip = 0;

    if (_data) {
      this.usrindexof = this.selectedOptions.indexOf("Users");
      this.entityindexof = this.selectedOptions.indexOf("Entities");
      this.botindexof = this.selectedOptions.indexOf("Bots");
      let sendDataa = {};

      if (
        this.usrindexof == -1 &&
        this.entityindexof == -1 &&
        this.botindexof == -1
      ) {
        this.toastr.error("Atleast One CheckBox Is Required");
        sendDataa = {
          text: _data,
          skip: 0,
          limit: 5,
          filters: {
            entityTypes: data.value.filterEntityContents || [],
            domains: data.value.filterDomainContents || [],
            characteristics: data.value.filterCharacteristicContents || [],
            processes: data.value.filterProcessContents || [],
            locations: data.value.filterLocationContents || [],
            all: true,
          },
        };
      } else {
        sendDataa = {
          text: _data,
          skip: 0,
          limit: 5,
          filters: {
            entityTypes: data.value.filterEntityContents || [],
            domains: data.value.filterDomainContents || [],
            characteristics: data.value.filterCharacteristicContents || [],
            processes: data.value.filterProcessContents || [],
            locations: data.value.filterLocationContents || [],
            users: this.usrindexof != -1 ? true : false,
            entities: this.entityindexof != -1 ? true : false,
            bots: this.botindexof != -1 ? true : false,
          },
        };

        this.apiService.userSearchAll(sendDataa).then((getData) => {
          this.userCount = getData.userCount;
          this.searchBotCount = getData.botCount;
          this.entityCount = getData.entityCount;

          for (let op = 0; op < getData.userData.length; op++) {
            var element = getData.userData[op];
            this.mutualCount = element.mutuals.length;
            this.mutualsData = element.mutuals;
          }

          this.filterUserData = getData.userData;
          this.botFilterData = getData.botData;
          this.entityFilterData = getData.entityData;

          this.firstUserData = getData.userData;
          this.firstBotData = getData.botData;
          this.firstEntityData = getData.entityData;
        });
      }

      this.totalSkip = 5;
      this.totalUserSkip = 5;
      this.totalBotSkip = 5;
      this.totalEntitySkip = 5;

      this.botCountMore = true;
      this.entityCountMore = true;
      this.userCountMore = true;
    } else {
      this.toastr.error("Search Text is Required");
    }
    this.hide();
    this.showInputData += 1;
    this.hideLoader("userLoader");
  }

  userMoreData(type): void {
    let searchData = this.searchInput.value;

    let data = this.filterTypeData;

    let sendDataa = {};

    if (type == "user") {
      this.botFilterData = this.firstBotData;
      this.entityFilterData = this.firstEntityData;
      this.totalBotSkip = 5;
      this.totalEntitySkip = 5;
      this.botCountMore = true;
      this.entityCountMore = true;

      sendDataa = {
        text: searchData,
        skip: this.totalUserSkip, //5
        limit: 10,
        filters: {
          entityTypes: data.value.filterEntityContents || [],
          domains: data.value.filterDomainContents || [],
          characteristics: data.value.filterCharacteristicContents || [],
          processes: data.value.filterProcessContents || [],
          locations: data.value.filterLocationContents || [],
          users: this.usrindexof != -1 ? true : false,
        },
      };
      this.apiService.userSearchAll(sendDataa).then((getData) => {
        if (getData.success) {
          this.filterUserData = this.filterUserData.concat(getData.userData);
          this.totalUserSkip = this.totalUserSkip + 10;
          if (this.totalUserSkip > this.userCount) {
            this.userCountMore = false;
          }
        }
      });
    }
    if (type == "bot") {
      this.filterUserData = this.firstUserData;
      this.entityFilterData = this.firstEntityData;
      this.totalUserSkip = 5;
      this.totalEntitySkip = 5;
      this.userCountMore = true;
      this.entityCountMore = true;

      sendDataa = {
        text: searchData,
        skip: this.totalBotSkip, //5
        limit: 10,
        filters: {
          entityTypes: data.value.filterEntityContents || [],
          domains: data.value.filterDomainContents || [],
          characteristics: data.value.filterCharacteristicContents || [],
          processes: data.value.filterProcessContents || [],
          locations: data.value.filterLocationContents || [],
          bots: this.botindexof != -1 ? true : false,
        },
      };
      this.apiService.userSearchAll(sendDataa).then((getData) => {
        if (getData.success) {
          this.botFilterData = this.botFilterData.concat(getData.botData);
          this.totalBotSkip = this.totalBotSkip + 10;

          if (this.totalBotSkip > this.searchBotCount) {
            this.botCountMore = false;
          }
        }
      });
    }
    if (type == "entity") {
      this.filterUserData = this.firstUserData;
      this.botFilterData = this.firstBotData;
      this.totalUserSkip = 5;
      this.totalBotSkip = 5;
      this.userCountMore = true;
      this.botCountMore = true;

      sendDataa = {
        text: searchData,
        skip: this.totalEntitySkip, //5
        limit: 10,
        filters: {
          entityTypes: data.value.filterEntityContents || [],
          domains: data.value.filterDomainContents || [],
          characteristics: data.value.filterCharacteristicContents || [],
          processes: data.value.filterProcessContents || [],
          locations: data.value.filterLocationContents || [],
          entities: this.entityindexof != -1 ? true : false,
        },
      };
      this.apiService.userSearchAll(sendDataa).then((getData) => {
        if (getData.success) {
          this.entityFilterData = this.entityFilterData.concat(
            getData.entityData
          );
          this.totalEntitySkip = this.totalEntitySkip + 10;
          if (this.totalEntitySkip > this.entityCount) {
            this.entityCountMore = false;
          }
        }
      });
    }
  }

  userNextData(): void {
    if (this.searchUserIndex < this.userData.length) {
      this.searchUserDataList = this.userData.slice(
        this.searchUserIndex,
        this.searchUserIndex + 5
      );
      this.searchUserIndex += 5;
    }
  }

  userPrevData(): void {
    if (this.searchUserIndex > 5) {
      this.searchUserDataList = this.userData.slice(
        this.searchUserIndex - 10,
        this.searchUserIndex - 5
      );
      this.searchUserIndex -= 5;
    }
  }

  openSearchUserModal(): void {
    const dialogRef = this.dialog.open(SearchUserListModalComponent, {
      width: "550px",
      data: {
        userData: this.filterUserData,
        botData: this.botFilterData,
        entitiesData: this.entityFilterData,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getFilterEntityContent();
        this.getDomainContent();
        this.getProcessContent();
        this.getCharacteristicContent();
      }
    });
  }

  openSearchModal(type) {
    this.dialog.open(SearchUserListModalComponent, {
      width: "650px",
      data: {
        userData: this.filterUserData,
        botData: this.botFilterData,
        entitiesData: this.entityFilterData,
        type: type,
      },
    });
  }

  openMutualUserModal(MutualData): void {
    this.dialog.open(MutualListModalComponent, {
      width: "550px",
      data: {
        MutualconList: MutualData,
        MutualCount: MutualData.length,
      },
    });
  }

  openRemoveConnectionModal(data): void {
    const removeData = {
      header: "Remove",
      body: "Are you sure you want to remove the connection?",
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: "450px",
      data: removeData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (data.invitee === environment.userId) {
        if (result) {
          this.dataSend = {
            loggedin: environment.userId,
            tochange: data.sender,
            state: "removed",
            userName: data.senderDetail[0].userName,
            _id: data._id,
          };
          this.sendRemoveConnection(this.dataSend);
        }
      } else if (data.sender === environment.userId) {
        if (result) {
          this.dataSend = {
            loggedin: environment.userId,
            tochange: data.invitee,
            state: "removed",
            userName: data.inviteeDetail[0].userName,
            _id: data._id,
          };
          this.sendRemoveConnection(this.dataSend);
        }
      }
    });
  }

  sendRemoveConnection(data): void {
    this.apiService.updateInvitationStatus(data).then((response: any) => {
      this.searchUserDataList.map((connectionItem, index_) => {
        if (connectionItem._id === data._id) {
          this.searchUserDataList[index_]["removed"] = true;
        }
      });
      if (environment.userId === data.loggedin) {
        this.toastr.success(data.userName + " is removed from connection");
      }
    });
  }

  clear(): void {
    this.searchInput.reset();
    this.filterDataGroup.reset();

    if (this.searchInput === 1) {
      this.searchData = null;
    }
    if (this.filterUserData) {
      this.filterUserData = null;
    }
    if (this.botFilterData) {
      this.botFilterData = null;
    }
    if (this.entityFilterData) {
      this.entityFilterData = null;
    }
    if (this.selectedOptions) {
      this.selectedOptions = this.checkBoxBlank;
    }

    this.botCountMore = false;
    this.entityCountMore = false;
    this.userCountMore = false;

    this.hide();
  }

  show(): void {
    this.filterShow = true;
    this.hideLoader("userLoader");
  }

  hide(): void {
    this.filterShow = false;
  }

  showLoader(data): void {
    if (data === "userLoader") {
      this.userLoader = true;
    }
  }

  hideLoader(data): void {
    if (data === "userLoader") {
      this.userLoader = false;
    }
  }

  openToOpenBot(id) {
    const dialogRef = this.dialog.open(MarketBotDialogComponent, {
      width: "650px",
      data: {
        id: id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.search(this.filterDataGroup, this.searchInput.value);
      this.botFilterData;
    });
  }

  openToOpenEntity(id, type) {
    const dialogRef = this.dialog.open(MarketEntityDialogComponent, {
      width: "650px",
      data: {
        id: id,
        cardType: type,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.search(this.filterDataGroup, this.searchInput.value);
      this.entityFilterData;
    });
  }
}
