import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent implements OnInit {
  public dialogType: string;
  public dialogTitle: string;
  public dialogMessage: string;
  constructor() {}
  ngOnInit() {
    this.dialogType = localStorage.getItem("dialogType");
    this.dialogTitle = localStorage.getItem("dialogTitle");
    this.dialogMessage = localStorage.getItem("dialogMessage");
  }
}
