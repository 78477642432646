import { CookieService } from "ngx-cookie-service";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription, Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";

import { navigation } from "app/navigation/navigation";
import { locale as navigationEnglish } from "app/navigation/i18n/en";
import { locale as navigationTurkish } from "app/navigation/i18n/tr";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MAT_SNACK_BAR_DATA } from "@angular/material";
import {
  Actions,
  Store,  
} from "@ngxs/store";
import { SocketServiceService } from "./services/socket-service.service";


// tslint:disable
@Component({
  selector: "app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  private subscriptions = new Subscription();
  snackbarMessage$: Observable<string[]>;
  user$: Observable<any>;
  // Private
  private _unsubscribeAll: Subject<any>;
  token;
  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {FuseSplashScreenService} _fuseSplashScreenService
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   * @param {Platform} _platform
   * @param {TranslateService} _translateService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private snackbar: MatSnackBar,
    private actions: Actions,
    private socketService: SocketServiceService,
    private store: Store,
    private socketservice: SocketServiceService,
    private cookiesService: CookieService
  ) {
    // Get default navigation
    this.navigation = navigation;

    // Register the navigation to the service
    this._fuseNavigationService.register("main", this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation("main");

    // Add languages
    this._translateService.addLangs(["en", "tr"]);

    // Set the default language
    this._translateService.setDefaultLang("en");

    // Set the navigation translations
    this._fuseTranslationLoaderService.loadTranslations(
      navigationEnglish,
      navigationTurkish
    );

    // Use a language
    this._translateService.use("en");
    this.token = this.cookiesService.get("token");

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix Start
     * ----------------------------------------------------------------------------------------------------
     */

    /**
     * If you are using a language other than the default one, i.e. Turkish in this case,
     * you may encounter an issue where some of the components are not actually being
     * translated when your app first initialized.
     *
     * This is related to ngxTranslate module and below there is a temporary fix while we
     * are moving the multi language implementation over to the Angular's core language
     * service.
     **/

    // Set the default language to 'en' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.
    /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix End
     * ----------------------------------------------------------------------------------------------------
     */

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add("is-mobile");
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.getSocketData();
    this.snackbarMessage$ = this.store.select((state) => state.snackbar);
    this.user$ = this.store.select((state) => state.user.user);
    this.user$.subscribe((user: any) => {
      if (user) {
        switch (user.memberType) {
          case "governance": {
            this._fuseNavigationService.updateNavigationItem("kyc", {
              hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("registries", {
              hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem("escrow", {
              hidden: true,
            });
            break;
          }
          case "curation": {
            this._fuseNavigationService.updateNavigationItem("prtocol", {
              hidden: true,
            });
            this._fuseNavigationService.updateNavigationItem(
              "curationCouncil",
              { hidden: true }
            );
            this._fuseNavigationService.updateNavigationItem("requests", {
              hidden: true,
            });
          }
        }
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.subscriptions && this.subscriptions.unsubscribe();
    const sockectConnection = this.socketservice
      .getSocketData()
      .subscribe((res: any) => {});
    // Subscribe to config changes
    this.subscriptions.add(sockectConnection);
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === "boxed") {
          this.document.body.classList.add("boxed");
        } else {
          this.document.body.classList.remove("boxed");
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (let i = 0; i < this.document.body.classList.length; i++) {
          const className = this.document.body.classList[i];

          if (className.startsWith("theme-")) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });

    // listen to snack bar

   
  }

  getSocketData(): void {
   
  }

  /**
   * On destroy
   */

  showSnackBar(type: string, message: string) {
    if (message === null) {
      return;
    }

    setTimeout(() => {
      this.snackbar.openFromComponent(SnackbarComponent, {
        data: { type: type, message: message },
        duration: 3000,
      });
    }, 0);
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}

// snackbar components

@Component({
  selector: "snackbar",
  template: `<span
    [ngClass]="{
      error: data.type == 'ERROR',
      warn: data.type == 'WARNING',
      success: data.type == 'SUCCESS'
    }"
  >
    {{ data.type }}: {{ data.message }}
  </span>`,
  styles: [
    `
      .error {
        color: red;
      }
      .warn {
        color: orange;
      }
      .success {
        color: green;
      }
    `,
  ],
})
export class SnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}
