export const locale = {
  lang: "en",
  data: {
    NAV: {
      APPLICATIONS: "Applications",
      PROFILE: {
        TITLE: "Profile",
        BADGE: "25",
      },
      MANAGE_BOTS: {
        TITLE: "Manage Bots",
        BADGE: "30",
      },
      MANAGE_ENTITIES: {
        TITLE: "Manage Entities",
        BADGE: "30",
      },
      TIMELINE: {
        TITLE: "TIMELINE",
        BADGE: "35",
      },
      CHAT: {
        TITLE: "Chat",
        BADGE: "40",
      },
      CHAT_EMULATOR: {
        TITLE: "Chat Emulator",
        BADGE: "30",
      },
      LISTING: {
        TITLE: "Listings",
        BADGE: "30",
      },
      ANALYTICS: {
        TITLE: "Analytics",
        BADGE: "30",
      },
      MARKET_PLACE: {
        TITLE: "Market Place",
        BADGE: "30",
      },
      REGISTRIES: {
        TITLE: "Registries",
        BADGE: "30",
      },
      KYC: {
        TITLE: "KYC",
        BADGE: "30",
      },
      VAULT: {
        TITLE: "Vault",
        BADGE: "30",
      },
      ESCROW: {
        TITLE: "Escrow",
        BADGE: "30",
      },
      PROTOCOL: {
        TITLE: "Protocol",
        BADGE: "30",
      },
      COUNCIL: {
        TITLE: "Curation Council",
        BADGE: "30",
      },
      REQUESTS: {
        TITLE: "Requests",
        BADGE: "30",
      },
    },
  },
};
