import { Component, OnInit, Inject,Pipe, PipeTransform } from '@angular/core';



import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Pipe({name: 'json', pure: false})
export class JsonPipe implements PipeTransform {
  transform(value: any): string {
    return JSON.stringify(value, null, 2);
  }
}

@Component({
  selector: 'app-diagnostic-info',
  templateUrl: './diagnostic-info.component.html',
  styleUrls: ['./diagnostic-info.component.scss']
})
export class DiagnosticInfoComponent implements OnInit {

  public dialogData : any;
  public JsonDialogData : any;
  public originalData : any;
  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
  public dialog: MatDialog,
  public dialogRef: MatDialogRef<DiagnosticInfoComponent>) {

 

    this.originalData = data;
    
     this.dialogData = JSON.stringify(data); 
    

   }

  ngOnInit() {
  }
  copy_clip_bord(data)
  {  
      document.addEventListener('copy', (e) => {
      e.clipboardData.setData('text/plain', data);      
      e.preventDefault();
      document.removeEventListener('copy', null);
      }, true);
      document.execCommand('copy'); 
    
  }
}
