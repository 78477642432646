import { environment } from "../../../environments/environment";
const SERVER_URL = environment.serverUrl;

export const getAllBots = SERVER_URL + "/bot/user-bots/self"; //1
export const createBots = SERVER_URL + "/bot"; //2 //3
export const createBotFeature = SERVER_URL + "/bot/feature"; //6

//Delegate Bots API
export const createDelegateBots = SERVER_URL + "/delegate-bots"; //1
export const getAllDelegateBots = SERVER_URL + "/delegate-bots"; //2
export const updateDelegateBots = SERVER_URL + "/delegate-bots"; //2

export const getDelegateBotsDropdown = SERVER_URL + "/dropdown/delegate_bots"; //4

export const createTraining = SERVER_URL + "/nlp-training"; //1
export const getAllTraining = SERVER_URL + "/nlp-training"; //2
export const updateTraining = SERVER_URL + "/nlp-training"; //2

export const getProcessIntent = SERVER_URL + "/dropdown/process-intent"; //2

export const getAllIntegrationsBots = SERVER_URL + "/bot-integration"; //2

export const getAllEntities = SERVER_URL + "/user-created-entity/self-entities"; //1

export const deactivateEntity =
  SERVER_URL + "/user-created-entity/deactivate-entity"; //4
export const createEntityFeature = SERVER_URL + "/user-created-entity/feature"; //new added

export const getProcessByEntityID = SERVER_URL + "/user-created-process/self"; //5
export const createProcess = SERVER_URL + "/user-created-process"; //6
export const editProcess = SERVER_URL + "/user-created-process"; // 7

export const deactivateProcess =
  SERVER_URL + "/user-created-process/deactivate-process"; //8

export const getUserCreatedCharacteristic =
  SERVER_URL + "/user-created-characteristic/self"; //9
export const createCharacteristic = SERVER_URL + "/user-created-characteristic"; // 10
export const editCharacteristic = SERVER_URL + "/user-created-characteristic"; // 11
