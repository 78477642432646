import { environment } from "../../../environments/environment";
const SERVER_URL = environment.serverUrl;

export const getAllEntities = SERVER_URL + "/user-created-entity/self-entities"; //1
export const createEntity = SERVER_URL + "/user-created-entity"; //2 and 3

export const deactivateEntity =
  SERVER_URL + "/user-created-entity/deactivate-entity"; //4
export const createEntityFeature = SERVER_URL + "/user-created-entity/feature"; //new added

export const getProcessByEntityID = SERVER_URL + "/user-created-process/self"; //5
export const createProcess = SERVER_URL + "/user-created-process"; //6
export const editProcess = SERVER_URL + "/user-created-process"; // 7

export const deactivateProcess =
  SERVER_URL + "/user-created-process/deactivate-process"; //8

export const getUserCreatedCharacteristic =
  SERVER_URL + "/user-created-characteristic/self"; //9
export const createCharacteristic = SERVER_URL + "/user-created-characteristic"; // 10
export const editCharacteristic = SERVER_URL + "/user-created-characteristic"; // 11

export const deactivateCharacteristic =
  SERVER_URL + "/user-created-characteristic/deactivate-characteristic"; //12

export const getProcessIntent = SERVER_URL + "/process-intent"; //13
export const createProcessIntent = SERVER_URL + "/process-intent"; //14
export const editProcessIntent = SERVER_URL + "/process-intent"; //15

export const getProcessKnowledgeBases = SERVER_URL + "/knowledge-base"; //
export const createProcessKnowledgeBases = SERVER_URL + "/knowledge-base"; //
export const editProcessKnowledgeBases = SERVER_URL + "/knowledge-base"; //
export const createKnowledgeDocument = SERVER_URL + "/knowledge-base";
export const deleteKnowledgeDocument = SERVER_URL + "/knowledge-base";

export const getCharacteristicEntity = SERVER_URL + "/characteristic-entity"; //16
export const createCharacteristicEntity = SERVER_URL + "/characteristic-entity"; //17
export const editCharacteristicEntity = SERVER_URL + "/characteristic-entity"; //18

export const getEntityTypeMaster = SERVER_URL + "/dropdown/entity_type_master"; //19

export const getProcessTypeMaster = SERVER_URL + "/dropdown/process_type/entity"; //20

export const getCharacteristicTypeMaster =
  SERVER_URL + "/dropdown/characteristic_type/entity"; // 21

export const getProcessRelationship =
  SERVER_URL + "/dropdown/process_relationship"; //22

export const getCharacteristicRelationship =
  SERVER_URL + "/dropdown/characteristic_relationship"; // 23

export const getEntityDomain = SERVER_URL + "/dropdown/entity_domains"; //24
export const getParentEntities = SERVER_URL + "/dropdown/parent_entities"; //25 default 0 pass
export const getParentProcessByEntityID =
  SERVER_URL + "/dropdown/parent_processes"; //26

export const getRelationshipByCode =
  SERVER_URL + "/dropdown/entity_parent_relationship/"; //29
export const getCharacteristicProcess =
  SERVER_URL + "/dropdown/characteristic_processes"; //30

export const createRequestType = SERVER_URL + "/requests/"; // 31

export const getAssociateByEntityId = SERVER_URL + "/associate-entity";
export const createAssociate = SERVER_URL + "/associate-entity";
export const deactivatAssociate = SERVER_URL + "/associate-entity";

export const ownerEntities = SERVER_URL + "/dropdown/owner_entities";
export const ownerProcesses = SERVER_URL + "/dropdown/owner_processes";

//Entity Validation Requests(Associate/Parent/Delegate)
export const getAllParentEntities = SERVER_URL + "/entity-request";
export const approveRequest = SERVER_URL + "/entity-request/approve-request";
export const rejectRequest = SERVER_URL + "/entity-request/reject-request";
export const withdrawRequest = SERVER_URL + "/entity-request/withdraw-request";

export const getAllProcesses = SERVER_URL + "/entity/process";

export const getEntityById = SERVER_URL + "/entity/details/";

export const getDomainMaster = SERVER_URL + "/dropdown/domain_master";

export const getHierarchy = SERVER_URL + "/entity/getHierarchy/";
export const getEntityTypeById = SERVER_URL + "/entity/entityType/";

export const getCharacteristicByEntityID =
  SERVER_URL + "/entity/getCharacteristicByEntityID/";
