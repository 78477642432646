export class APP_ROUTES {
  public static LOGIN = "login";
  public static ANALYTICS_MODULE = "analytics";
  public static MANAGE_BOTS_MODULE = "manage-bots";
  public static CHAT_MODULE = "chat";
  public static CHAT_EMULATOR_MODULE = "chat-emulator";
  public static PROFILE_MODULE = "profile";
  public static USER_PROFILE_MODULE = "user-profile";
  public static LISTING_MODULE = "listing";
  public static TIMELINE_MODULE = "timeline";
  public static USER_TIMELINE_MODULE = "user-timeline";
  public static ENTITY_MODULE = "entity";
  public static MANAGE_ENTITY_MODULE = "manage-entities";
  public static MARKET_PLACE_MODULE = "marketplace";
  public static COMPLETE_PROFILE = "complete-profile";
  public static PROFILE = "profile";
  public static UPDATE_PROFILE = "update-profile";
  public static VERIFY_EMAIL = "verify-email";
  public static MARKET_PLACES_ROUTE = "marketplaces";
  public static MARKET_PLACES = `${APP_ROUTES.MARKET_PLACE_MODULE}/${APP_ROUTES.MARKET_PLACES_ROUTE}`;
}
