import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../../../../../services/api.service";
import { MatDialog } from "@angular/material/dialog";
import { MarketEntityDialogComponent } from "../../../../shared-module/common/market-entity-dialog/market-entity-dialog.component";

@Component({
  selector: "app-user-entity",
  templateUrl: "./user-entity.component.html",
  styleUrls: ["./user-entity.component.scss"],
})
export class UserEntityComponent implements OnInit {
  @ViewChild("tabGroup") tabGroup;

  pramsuserid: any;
  public selectedIndex: Number = 0;
  entitySuccess: any;
  entityHide: boolean = false;
  btnDisabled: boolean = true;
  enityRestrict: any;
  entityData: any;
  data: any;
  imgDefault: string;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    const paramsData = this.route.snapshot.params;
    this.pramsuserid = paramsData.id;

    let tabUrl = this.route.url["value"][0].path;
    if (tabUrl == "users-entity") {
      this.api.getEntityById("MAIN", this.pramsuserid).then((res) => {
        // Manage-Entity For API //
        this.entitySuccess = res.success;
        if (this.entitySuccess == false) {
          this.entityHide = true;
          this.enityRestrict = res.msg;
        } else if (this.entitySuccess == true) {
          this.entityData = res.data;

          if (res.data.length == 0) {
          } else if (res.data.length >= 1) {
            this.entityHide = false;
            this.data = [
              {
                entityName: res.data[0].entityName,
                _id: res.data[0]._id,
                entityLocation: res.data[0].entityLocation,
                fromDate: res.data[0].fromDate,
                entityTypeId: res.data[0].entityTypeID,
                status: res.data[0].status,
              },
            ];
          }
        }
      });
    }
  }

  openToBuyEntity(id, type) {
    const dialogRef = this.dialog.open(MarketEntityDialogComponent, {
      width: "650px",
      data: {
        id: id,
        cardType: type,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }
}
