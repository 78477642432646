// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  userId: localStorage.getItem("userId"),
  // Server URL

  // serverUrl: "http://3.7.87.129:8080",
  // baseUrl: "http://3.7.87.129:8080",
  serverUrl: "https://api.hopabot.com",
  baseUrl: "https://api.hopabot.com",
  domain: '', //.hopabot.com
  

  //serverUrl: "http://localhost:8080",
  //baseUrl: "http://localhost:8080",

  //serverUrl: "http://1a5f-123-201-148-161.ngrok.io",
  //baseUrl: "http://1a5f-123-201-148-161.ngrok.io",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
