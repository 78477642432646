import { FuseNavigation } from "@fuse/types";
import { APP_ROUTES } from "app/main/shared-module/constants/AppRoutes";
import { ApiService } from "app/services/api.service";

export const navigation: FuseNavigation[] = [
  {
    id: "applications",
    title: "Applications",
    translate: "NAV.APPLICATIONS",
    type: "group",
    children: [
      {
        id: "manage-entities",
        title: "Manage Entities",
        translate: "NAV.MANAGE_ENTITIES.TITLE",
        type: "item",
        icon: "view_stream",
        url: "/manage-entities",
        // badge    : {
        //     title    : "",
        //     translate: "",
        //     bg       : "",
        //     fg       : ""
        // }
      },
      {
        id: "manageBots",
        title: "Manage Bots",
        translate: "NAV.MANAGE_BOTS.TITLE",
        type: "item",
        icon: "bug_report",
        url: "/manage-bots",
        // badge    : {
        //     title    : "",
        //     translate: "",
        //     bg       : "",
        //     fg       : ""
        // }
      },
      {
        id: "chatEmulator",
        title: "Chat Emulator",
        translate: "NAV.CHAT_EMULATOR.TITLE",
        type: "item",
        icon: "question_answer",
        url: "/chat-emulator",
        // badge: {
        //   title: "",
        //   translate: "",
        //   bg: "",
        //   fg: ""
        // }
      },
      {
        id: "listing",
        title: "Listing",
        translate: "NAV.LISTING.TITLE",
        type: "item",
        icon: "format_list_bulleted",
        url: "/listing",
        // badge: {
        //   title: "",
        //   translate: "",
        //   bg: "",
        //   fg: ""
        // }
      },
      {
        id: "profile",
        title: "Profile",
        translate: "NAV.PROFILE.TITLE",
        type: "item",
        icon: "account_circle",
        url: "/" + APP_ROUTES.PROFILE,
        // badge    : {
        //     title    : "",
        //     translate: "NAV.PROFILE.BADGE",
        //     bg       : "",
        //     fg       : ""
        // }
      },
      {
        id: "timline",
        title: "Timeline",
        // translate: "NAV.PROFILE.TITLE",
        type: "item",
        icon: "timeline",
        url: "/timeline",
        // badge    : {
        //     title    : "",
        //     translate: "",
        //     bg       : "",
        //     fg       : ""
        // }
      },
      {
        id: "chat",
        title: "Messages",
        translate: "",
        type: "item",
        icon: "chat",
        url: "/chat",
        // badge    : {
        //     title    : "0",
        //     translate: "",
        //     bg       : "#F44336",
        //     fg       : "#FFFFFF"
        // }
      },
      {
        id: "analytics",
        title: "Analytics",
        translate: "NAV.ANALYTICS.TITLE",
        type: "item",
        icon: "assessment",
        url: "/analytics",
        // badge    : {
        //     title    : "",
        //     translate: "",
        //     bg       : "",
        //     fg       : ""
        // }
      },
      {
        id: "marketplace",
        title: "Market Place",
        translate: "NAV.MARKET_PLACE.TITLE",
        type: "item",
        icon: "storefront",
        url: "/marketplace",
       
      },

     
    ],
  },
];
