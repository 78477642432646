import { APP_ROUTES } from "app/main/shared-module/constants/AppRoutes";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService as AuthGuard } from "app/main/shared-module/services/auth.guard.service";

const routes: Routes = [
  {
    path: "",
    redirectTo: APP_ROUTES.LOGIN,
    pathMatch: "full",
  },
  // {
  //   path: APP_ROUTES.PROFILE,
  //   redirectTo: APP_ROUTES.PROFILE,
  //   pathMatch: "full",
  // },
  {
    path: APP_ROUTES.LOGIN,
    redirectTo: APP_ROUTES.LOGIN,
    pathMatch: "full",
  },
  {
    path: APP_ROUTES.COMPLETE_PROFILE,
    redirectTo: APP_ROUTES.COMPLETE_PROFILE,
    pathMatch: "full",
  },
  {
    path: APP_ROUTES.UPDATE_PROFILE,
    redirectTo: APP_ROUTES.UPDATE_PROFILE,
    pathMatch: "full",
  },
  {
    path: APP_ROUTES.VERIFY_EMAIL,
    redirectTo: APP_ROUTES.VERIFY_EMAIL,
    pathMatch: "full",
  },
  {
    path: APP_ROUTES.ANALYTICS_MODULE,
    loadChildren: () =>
      import("./main/analytics-module/analytics.module").then(
        (m) => m.AnalyticsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: APP_ROUTES.MANAGE_BOTS_MODULE,
    loadChildren: () =>
      import("./main/manage-bots/manage-bots.module").then(
        (m) => m.ManageBotsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: APP_ROUTES.CHAT_MODULE,
    loadChildren: () =>
      import("./main/chat-module/chat.module").then((m) => m.ChatModule),
    canActivate: [AuthGuard],
  },
  {
    path: APP_ROUTES.CHAT_EMULATOR_MODULE,
    loadChildren: () =>
      import("./main/chat-emulator/chat-emulator.module").then(
        (m) => m.ChatEmulatorModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: APP_ROUTES.PROFILE_MODULE,
    loadChildren: () =>
      import("./main/profile/profile.module").then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: APP_ROUTES.LISTING_MODULE,
    loadChildren: () =>
      import("./main/listing/listing.module").then((m) => m.ListingModule),
    canActivate: [AuthGuard],
  },
  {
    path: APP_ROUTES.TIMELINE_MODULE,
    loadChildren: () =>
      import("./main/timeline/timeline.module").then((m) => m.TimelineModule),
    canActivate: [AuthGuard],
  },
  {
    path: APP_ROUTES.MANAGE_ENTITY_MODULE,
    loadChildren: () =>
      import("./main/manage-entities/manage-entities.module").then(
        (m) => m.ManageEntityModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: APP_ROUTES.MARKET_PLACE_MODULE,
    loadChildren: () =>
      import("./main/market-place/market-place.module").then(
        (m) => m.MarketPlaceModule
      ),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
