import { Injectable, Output, EventEmitter } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpRequest,
} from "@angular/common/http";
import { environment } from "environments/environment";
import { catchError, map } from "rxjs/operators";
import { Store } from "@ngxs/store";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError } from "rxjs";
import { promise } from "protractor";
import Fortmatic from "fortmatic";
import { APP_ROUTES } from "app/main/shared-module/constants/AppRoutes";
import { ErrorHandleService } from "app/services/error.handle.service";

@Injectable({
  providedIn: "root",
})
export class ChatServiceService {
  @Output() openChat = new EventEmitter();
  @Output() createdGroup = new EventEmitter();
  @Output() upDateLastMessage = new EventEmitter();
  @Output() readUnread = new EventEmitter();

  value;
  chatOpenedStatus: Boolean = false;
  httpOptions: any = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    }),
  };

  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private he: ErrorHandleService
  ) {}

  setOpenChatData(data) {
    this.value = data;
  }
  getOpenChatData(cb) {
    cb(this.value);
  }

  getChatList(userId): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/message/allchat")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getconnectionlist(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/message/search", data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getUsers(userId): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/message/user-connection")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getChatById(chatid): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/message/get-chat/" + chatid)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  sendMessage(chatid, data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/message/send-message/" + chatid, data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  createGroup(data, chatId): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/message/create-group/" + chatId, data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  addGroupParticipant(data, chatId): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/message/add-participant/" + chatId, data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  removeGroupParticipant(data, chatId): Promise<any> {
    return this.http
      .put(environment.baseUrl + "/message/remove-participant/" + chatId, data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  addToChatList(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/message/create-chat", data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  deleteChat(chatId): Promise<any> {
    return this.http
      .put(environment.baseUrl + "/message/delete-chat/" + chatId, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  deleteGroup(chatId): Promise<any> {
    return this.http
      .put(environment.baseUrl + "/message/delete-group/" + chatId, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  exitChatRemove(chatID): Promise<any> {
    return this.http
      .delete(environment.baseUrl + "/message/exit-chat/" + chatID)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  exitGroupRemove(chatID): Promise<any> {
    return this.http
      .delete(environment.baseUrl + "/message/exit-group/" + chatID)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getAllUnreadMessage(userId): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/message-notification/notificationCount")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  deleteNotification(data, chatID): Promise<any> {
    return this.http
      .delete(environment.baseUrl + "/message-notification/remove/" + chatID)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  
}
