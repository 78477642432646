import {
  CreateCharacteristicFailed,
  CreateCharacteristicRequest,
  CreateCharacteristicSuccess,
  CreateEntityFailed,
  CreateEntityRequest,
  CreateEntitySuccess,
  CreateProcessFailed,
  CreateProcessRequest,
  CreateProcessSuccess,
  CreateRelationRequest,
  CreateRequestTypeFailed,
  CreateRequestTypeRequest,
  CreateRequestTypeSuccess,
  GetAllEntities,
  GetProcessByEntityID,
  GetProcessByEntityIDFailed,
  GetProcessByEntityIDSuccess,
  GetAllEntitiesFailed,
  GetAllEntitiesSuccess,
  GetAllProcesses,
  GetAllProcessesFailed,
  GetAllProcessesSuccess,
  GetCharactTypeMasterRequest,
  GetDomainMasterRequest,
  GetEntityDetailsRequest,
  GetEntityTypeMasterRequest,
  GetEntityTypeRequest,
  GetHierarchyRequest,
  GetProcessTypeMasterRequest,
  GetProcessByEntityIdRequest,
  GetLocationRequest,
  GetParentEntities,
  GetParentEntitiesSuccess,
  GetParentEntitiesFailed,
  GetUserCreatedCharacteristic,
  GetCharacteristicRelationshipByCodeRequest,
  GetCharacteristicProcess,
  GetEntityDomain,
  GetProcessRelationship,
  GetAssociateByEntityId,
  OwnerEntities,
  OwnerProcess,
  GetProcessIntent,
  GetProcessKnowledgeBases,
  GetCharacteristicEntity,
  GetAllParentEntities,
  GetCharateristicByEntityIdRequest,
  GetRelationshipByCodeRequest,
} from "./entity.action";

import { RelationshipModel } from "./entity.model";
import { EntityService } from "./entity.service";
import { State, Selector, Action, StateContext } from "@ngxs/store";
import {  catchError } from "rxjs/operators";
import {  throwError } from "rxjs";
import { HttpClient,  } from "@angular/common/http";
import { Store } from "@ngxs/store";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ErrorHandleService } from "app/services/error.handle.service";

interface EntityStateModel {
  allEntities: [];
  allProcesses: any[];
  allCharacteristics: any[];
  allAssociateByEntityId: any[];
  isEntityCreated: boolean;
  isEntityCreateFailed: boolean;
  IsCharacteristicCreated: boolean;
  IsCharacteristicCreatedFailed: boolean;
  IsProcessCreated: boolean;
  IsProcessCreatedFailed: boolean;
  IsRequestTypeCreated: boolean;
  IsRequestTypeCreatedFailed: boolean;
  entityTypeMasterList: [];
  processTypeMasterList: [];
  charactTypeMasterList: [];
  domainMasterList: [];
  entityDetails: [];
  hierarchyList: [];
  entityTypeDetails: {};
  processItemDetails: {};
  locationDetails: {};
  characteristicDetailByEntityId: {};
  relationshipTypeMaster: [];
  characteristicRelationship: [];
  processRelationship: [];
  processByEntityID: [];
  parentEntities: [];
  characteristicProcess: [];
  entityDomain: [];
  ownerEntities: [];
  ownerProcess: [];
  processIntentList: [];
  processKnowledgeBasesList: [];
  characteristicEntityList: [];
  allParentEntities: [];
}

@State<EntityStateModel>({
  name: "EntityState",
  defaults: {
    allEntities: [],
    allProcesses: [],
    allCharacteristics: [],
    isEntityCreated: false,
    isEntityCreateFailed: false,
    IsCharacteristicCreated: false,
    IsCharacteristicCreatedFailed: false,
    IsProcessCreated: false,
    IsProcessCreatedFailed: false,
    IsRequestTypeCreated: false,
    IsRequestTypeCreatedFailed: false,
    entityTypeMasterList: [],
    processTypeMasterList: [],
    charactTypeMasterList: [],
    domainMasterList: [],
    entityDetails: [],
    hierarchyList: [],
    entityTypeDetails: {},
    processItemDetails: {},
    locationDetails: {},
    characteristicDetailByEntityId: {},
    relationshipTypeMaster: [],
    characteristicRelationship: [],
    processRelationship: [],
    processByEntityID: [],
    parentEntities: [],
    characteristicProcess: [],
    entityDomain: [],
    allAssociateByEntityId: [],
    ownerEntities: [],
    ownerProcess: [],
    processIntentList: [],
    processKnowledgeBasesList: [],
    characteristicEntityList: [],
    allParentEntities: [],
  },
})
export class EntityListState {
  constructor(
    private entityService: EntityService,
    private http: HttpClient,
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private he: ErrorHandleService
  ) {}

  @Selector()
  static getAllEntities(state: EntityStateModel): any {
    return state.allEntities;
  }

  @Selector()
  static getParentEntities(state: EntityStateModel): any {
    return state.parentEntities;
  }

  @Selector()
  static getProcessByEntityId(state: EntityStateModel): any {
    return state.processByEntityID;
  }

  @Selector()
  static getAllProcesses(state: EntityStateModel): any {
    return state.allProcesses;
  }
  @Selector()
  static isEntityCreated(stete: EntityStateModel): any {
    return stete.isEntityCreated;
  }

  @Selector()
  static isEntityCreateFailed(stete: EntityStateModel): any {
    return stete.isEntityCreateFailed;
  }

  @Selector()
  static IsCharacteristicCreated(stete: EntityStateModel): any {
    return stete.IsCharacteristicCreated;
  }

  @Selector()
  static IsCharacteristicCreatedFailed(stete: EntityStateModel): any {
    return stete.IsCharacteristicCreatedFailed;
  }

  @Selector()
  static IsProcessCreated(stete: EntityStateModel): any {
    return stete.IsProcessCreated;
  }

  @Selector()
  static IsProcessCreatedFailed(stete: EntityStateModel): any {
    return stete.IsProcessCreatedFailed;
  }

  @Selector()
  static IsRequestTypeCreated(stete: EntityStateModel): any {
    return stete.IsRequestTypeCreated;
  }

  @Selector()
  static IsRequestTypeCreatedFailed(stete: EntityStateModel): any {
    return stete.IsRequestTypeCreatedFailed;
  }

  @Selector()
  static getProcessTypeMaster(stete: EntityStateModel): any {
    return stete.processTypeMasterList;
  }

  @Selector()
  static getdomainMaster(stete: EntityStateModel): any {
    return stete.domainMasterList;
  }
  @Selector()
  static getEntityDomain(stete: EntityStateModel): any {
    return stete.entityDomain;
  }

  @Selector()
  static getOwnerEntities(stete: EntityStateModel): any {
    return stete.ownerEntities;
  }

  @Selector()
  static getOwnerProcess(stete: EntityStateModel): any {
    return stete.ownerProcess;
  }

  @Selector()
  static getEntityDetails(stete: EntityStateModel): any {
    return stete.entityDetails;
  }

  @Selector()
  static getHierarchy(stete: EntityStateModel): any {
    return stete.hierarchyList;
  }

  @Selector()
  static getCharactTypeMaster(stete: EntityStateModel): any {
    return stete.charactTypeMasterList;
  }
  @Selector()
  static getUserCreatedCharacteristic(stete: EntityStateModel): any {
    return stete.allCharacteristics;
  }

  @Selector()
  static getAssociateByEntityId(stete: EntityStateModel): any {
    return stete.allAssociateByEntityId;
  }

  @Selector()
  static getEntityTypeDetails(stete: EntityStateModel): any {
    return stete.entityTypeDetails;
  }

  @Selector()
  static getEntityTypeMaster(stete: EntityStateModel): any {
    return stete.entityTypeMasterList;
  }

  @Selector()
  static getCharacteristicByEntity(state: EntityStateModel): any {
    return state.characteristicDetailByEntityId;
  }

  @Selector()
  static getCharacteristicProcess(stete: EntityStateModel): any {
    return stete.characteristicProcess;
  }

  @Selector()
  static getProcessByEntity(state: EntityStateModel): any {
    return state.processItemDetails;
  }

  @Selector()
  static getLocationDetails(state: EntityStateModel): any {
    return state.locationDetails;
  }

  @Selector()
  static getRelationshipTypeMaster(state: EntityStateModel): any {
    return state.relationshipTypeMaster;
  }

  @Selector()
  static getCharacteristicRelationship(state: EntityStateModel): any {
    return state.characteristicRelationship;
  }
  @Selector()
  static getProcessRelationship(state: EntityStateModel): any {
    return state.processRelationship;
  }

  @Selector()
  static getProcessIntent(stete: EntityStateModel): any {
    return stete.processIntentList;
  }

  @Selector()
  static getProcessKnowledgeBases(stete: EntityStateModel): any {
    return stete.processKnowledgeBasesList;
  }

  @Selector()
  static getCharacteristicEntity(stete: EntityStateModel): any {
    return stete.characteristicEntityList;
  }

  @Selector()
  static getAllParentEntities(state: EntityStateModel): any {
    return state.allParentEntities;
  }

  @Action(GetAllEntities)
  getAllEntities(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: GetAllEntities
  ): any {
    return this.entityService
      .getAllEntities()
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.dispatch(new GetAllEntitiesSuccess(res.data));
        } else {
          ctx.dispatch(new GetAllEntitiesFailed(res.message));
        }
      });
  }

  // @Action(GetAllEntities)
  // getAllEntities(
  //   ctx: StateContext<Partial<EntityStateModel>>,
  //   action: GetAllEntities
  // ): any {
  //   return this.entityService.getAllEntities().pipe(
  //     tap(res => {
  //       if (res.success) {
  //         ctx.dispatch(new GetAllEntitiesSuccess(res.data));
  //       } else {
  //         ctx.dispatch(new GetAllEntitiesFailed(res.message));
  //       }
  //     }),
  //     catchError(e =>
  //       throwError(this.he.handleError(e))
  //     )
  //   );
  // }

  @Action(GetAllEntitiesSuccess)
  getAllEntitiesSuccess(
    ctx: StateContext<any>,
    action: GetAllEntitiesSuccess
  ): void {
    ctx.patchState({
      allEntities: action.response,
    });
  }

  @Action(GetAllEntitiesFailed)
  getAllEntitiesFailed(ctx: StateContext<Partial<EntityStateModel>>): void {
    ctx.patchState({
      allEntities: [],
    });
  }

  /***************  Parent Entities ***********/
  @Action(GetParentEntities)
  getParentEntities(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: GetParentEntities
  ): any {
    return this.entityService
      .getParentEntities()
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.dispatch(new GetParentEntitiesSuccess(res.data));
        } else {
          ctx.dispatch(new GetParentEntitiesFailed(res.message));
        }
      });
  }

  @Action(GetParentEntitiesSuccess)
  getParentEntitiesSuccess(
    ctx: StateContext<any>,
    action: GetParentEntitiesSuccess
  ): void {
    ctx.patchState({
      parentEntities: action.response,
    });
  }

  @Action(GetParentEntitiesFailed)
  getParentEntitiesFailed(ctx: StateContext<Partial<EntityStateModel>>): void {
    ctx.patchState({
      parentEntities: [],
    });
  }

  @Action(GetProcessByEntityID)
  getProcessByEntityID(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: GetProcessByEntityID
  ): any {
    return this.entityService
      .getProcessByEntityID(localStorage.getItem("entityId"))
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.dispatch(new GetProcessByEntityIDSuccess(res.data));
        } else {
          ctx.dispatch(new GetProcessByEntityIDFailed(res.message));
        }
      });
  }

  @Action(GetProcessByEntityIDSuccess)
  getProcessByEntityIDSuccess(
    ctx: StateContext<any>,
    action: GetAllEntitiesSuccess
  ): void {
    ctx.patchState({
      processByEntityID: action.response,
    });
  }

  @Action(GetProcessByEntityIDFailed)
  getProcessByEntityIDFailed(
    ctx: StateContext<Partial<EntityStateModel>>
  ): void {
    ctx.patchState({
      processByEntityID: [],
    });
  }

  @Action(CreateEntityRequest)
  createEntityRequest(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: CreateEntityRequest
  ): any {
    return this.entityService
      .createEntity(action.payload)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.dispatch(new CreateEntitySuccess(true));
          ctx.dispatch(new GetAllEntities());
          const myPayload: RelationshipModel = res.data;
          ctx.dispatch(new CreateRelationRequest(myPayload));
        } else {
          ctx.dispatch(new GetAllEntitiesFailed(true));
        }
      });
  }

  @Action(CreateEntitySuccess)
  createEntitySuccess(
    ctx: StateContext<any>,
    action: CreateEntitySuccess
  ): void {
    ctx.patchState({
      isEntityCreated: action.IsEntityCreated,
    });
    this.resetSnacker(ctx, {
      isEntityCreated: "",
    });
  }

  @Action(CreateEntityFailed)
  createEntityFailed(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: CreateEntityFailed
  ): void {
    ctx.patchState({
      isEntityCreateFailed: action.IsEntityCreatedFailed,
    });
    this.resetSnacker(ctx, {
      isEntityCreateFailed: "",
    });
  }

  @Action(GetAllProcesses)
  getAllProcesses(ctx: StateContext<Partial<EntityStateModel>>): any {
    return this.entityService
      .getAllProcesses()
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.dispatch(new GetAllProcessesSuccess(res.data));
        } else {
          ctx.dispatch(new GetAllProcessesFailed(res.message));
        }
      });
  }

  @Action(GetAllProcessesSuccess)
  getAllProcessesSuccess(
    ctx: StateContext<any>,
    action: GetAllProcessesSuccess
  ): void {
    ctx.patchState({
      allProcesses: action.response,
    });
  }

  @Action(GetAllProcessesFailed)
  getAllProcessesFailed(ctx: StateContext<Partial<EntityStateModel>>): void {
    ctx.patchState({
      allProcesses: [],
    });
  }

  @Action(CreateCharacteristicRequest)
  createCharacteristicRequest(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: CreateCharacteristicRequest
  ): any {
    return this.entityService
      .createCharacteristic(action.payload)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.dispatch(new CreateCharacteristicSuccess(true));
          const myPayload: RelationshipModel = res.data;
          //ctx.dispatch(new CreateRelationRequest(myPayload));
        } else {
          return ctx.dispatch(new CreateCharacteristicFailed(true));
          // ctx.dispatch(new GetAllEntitiesFailed(true));
        }
      });
  }

  @Action(CreateCharacteristicSuccess)
  createCharacteristicSuccess(
    ctx: StateContext<any>,
    action: CreateCharacteristicSuccess
  ): void {
    ctx.patchState({
      IsCharacteristicCreated: action.IsCharacteristicCreated,
    });
    this.resetSnacker(ctx, {
      IsCharacteristicCreated: "",
    });
  }

  @Action(CreateCharacteristicFailed)
  createCharacteristicFailed(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: CreateCharacteristicFailed
  ): void {
    ctx.patchState({
      IsCharacteristicCreatedFailed: action.IsCharacteristicCreatedFailed,
    });
    this.resetSnacker(ctx, {
      IsCharacteristicCreatedFailed: "",
    });
  }

  @Action(CreateProcessRequest)
  createProcessRequest(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: CreateProcessRequest
  ): any {
    return this.entityService
      .createProcess(action.payload)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.dispatch(new CreateProcessSuccess(true));
        } else {
          return ctx.dispatch(new CreateProcessFailed(true));
        }
      });
  }

  @Action(CreateProcessSuccess)
  createProcessSuccess(
    ctx: StateContext<any>,
    action: CreateProcessSuccess
  ): void {
    ctx.patchState({
      IsProcessCreated: action.IsProcessCreated,
    });
    this.resetSnacker(ctx, {
      IsProcessCreated: "",
    });
  }

  @Action(CreateProcessFailed)
  createProcessFailed(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: CreateProcessFailed
  ): void {
    ctx.patchState({
      IsProcessCreatedFailed: action.IsProcessCreatedFailed,
    });
    this.resetSnacker(ctx, {
      IsProcessCreatedFailed: "",
    });
  }

  @Action(CreateRequestTypeRequest)
  createRequestTypeRequest(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: CreateRequestTypeRequest
  ): any {
    return this.entityService
      .createRequestType(action.payload)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.dispatch(new CreateRequestTypeSuccess(true));
        } else {
          ctx.dispatch(new CreateRequestTypeFailed(true));
        }
      });
  }

  @Action(CreateRequestTypeSuccess)
  createRequestTypeSuccess(
    ctx: StateContext<any>,
    action: CreateRequestTypeSuccess
  ): void {
    ctx.patchState({
      IsRequestTypeCreated: action.IsRequestTypeCreated,
    });
    this.resetSnacker(ctx, {
      IsRequestTypeCreated: "",
    });
  }

  @Action(CreateRequestTypeFailed)
  createRequestTypeFailed(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: CreateRequestTypeFailed
  ): void {
    ctx.patchState({
      IsRequestTypeCreatedFailed: action.IsRequestTypeCreatedFailed,
    });
    this.resetSnacker(ctx, {
      IsRequestTypeCreatedFailed: "",
    });
  }

  @Action(GetEntityTypeMasterRequest)
  getEntityTypeMasterRequest(
    ctx: StateContext<Partial<EntityStateModel>>
  ): any {
    return this.entityService
      .getEntityTypeMaster()
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            entityTypeMasterList: res.data,
          });
        } else {
          ctx.patchState({
            entityTypeMasterList: [],
          });
        }
      });
  }

  @Action(GetProcessTypeMasterRequest)
  getProcessTypeMaster(ctx: StateContext<Partial<EntityStateModel>>): any {
    //var entityTypeID = localStorage.getItem("entityTypeID");
    var entityId = localStorage.getItem("entityId");
    return this.entityService
      .getProcessTypeMaster(entityId)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            processTypeMasterList: res.data,
          });
        } else {
          ctx.patchState({
            processTypeMasterList: [],
          });
        }
      });
  }

  @Action(GetCharactTypeMasterRequest)
  getCharactTypeMaster(ctx: StateContext<Partial<EntityStateModel>>): any {
    //var entityTypeID = localStorage.getItem("entityTypeID");
    var entityId = localStorage.getItem("entityId");
    return this.entityService
      .getCharactTypeMaster(entityId)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            charactTypeMasterList: res.data,
          });
        } else {
          ctx.patchState({
            charactTypeMasterList: [],
          });
        }
      });
  }

  @Action(GetUserCreatedCharacteristic)
  getUserCreatedCharacteristic(
    ctx: StateContext<Partial<EntityStateModel>>
  ): any {
    var entityId = localStorage.getItem("entityId");
    return this.entityService
      .getUserCreatedCharacteristic(entityId)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            allCharacteristics: res.data,
          });
        } else {
          ctx.patchState({
            allCharacteristics: [],
          });
        }
      });
  }

  @Action(GetAssociateByEntityId)
  getAssociateByEntityId(ctx: StateContext<Partial<EntityStateModel>>): any {
    var entityId = localStorage.getItem("entityId");
    return this.entityService
      .getAssociateByEntityId(entityId)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            allAssociateByEntityId: res.data,
          });
        } else {
          ctx.patchState({
            allAssociateByEntityId: [],
          });
        }
      });
  }

  @Action(GetEntityDetailsRequest)
  getEntityDetails(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: GetEntityDetailsRequest
  ): any {
    return this.entityService
      .getEntityById(action.payload)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            entityDetails: res.data,
          });
        } else {
          ctx.patchState({
            entityDetails: [],
          });
        }
      });
  }

  @Action(GetHierarchyRequest)
  getHierarchyRequest(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: GetHierarchyRequest
  ): any {
    return this.entityService
      .getHierarchy(action.payload)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            hierarchyList: res.data,
          });
        } else {
          ctx.patchState({
            hierarchyList: [],
          });
        }
      });
  }

  @Action(GetEntityTypeRequest)
  GetEntityTypeRequest(
    ctx: StateContext<EntityStateModel>,
    action: GetEntityTypeRequest
  ): any {
    return this.entityService
      .getEntityType(action.payload)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.status) {
          ctx.patchState({
            entityTypeDetails: res.data,
          });
        } else {
          ctx.patchState({
            entityTypeDetails: {},
          });
        }
      });
  }
  @Action(GetProcessByEntityIdRequest)
  getProcessByEntityId(
    ctx: StateContext<EntityStateModel>,
    action: GetProcessByEntityIdRequest
  ): any {
    return this.entityService
      .getParentProcessByEntityID(action.payload)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            processItemDetails: res.data,
          });
        } else {
          ctx.patchState({
            processItemDetails: {},
          });
        }
      });
  }

  @Action(GetCharateristicByEntityIdRequest)
  getCharateristicByEntityIdRequest(
    ctx: StateContext<EntityStateModel>,
    action: GetCharateristicByEntityIdRequest
  ): any {
    return this.entityService
      .getCharacteristicByEntityID(action.payload)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            characteristicDetailByEntityId: res.data,
          });
        } else {
          ctx.patchState({
            characteristicDetailByEntityId: {},
          });
        }
      });
  }

  @Action(GetDomainMasterRequest)
  getDomainMaster(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: GetDomainMasterRequest
  ): any {
    return this.entityService
      .getDomainMaster(action.payload)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            domainMasterList: res.data,
          });
        } else {
          ctx.patchState({
            domainMasterList: [],
          });
        }
      });
  }

  @Action(GetEntityDomain)
  getEntityDomain(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: GetEntityDomain
  ): any {
    return this.entityService
      .getEntityDomain(action.payload)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            entityDomain: res.data,
          });
        } else {
          ctx.patchState({
            entityDomain: [],
          });
        }
      });
  }

  @Action(OwnerEntities)
  getOwnerEntities(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: OwnerEntities
  ): any {
    return this.entityService
      .getOwnerEntities()
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            ownerEntities: res.data,
          });
        } else {
          ctx.patchState({
            ownerEntities: [],
          });
        }
      });
  }

  @Action(OwnerProcess)
  getOwnerProcess(
    ctx: StateContext<Partial<EntityStateModel>>,
    action: OwnerProcess
  ): any {
    const entityId = action.payload;
    return this.entityService
      .getOwnerProcess(entityId)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            ownerProcess: res.data,
          });
        } else {
          ctx.patchState({
            ownerProcess: [],
          });
        }
      });
  }

  @Action(GetLocationRequest)
  getLocationRequest(
    ctx: StateContext<EntityStateModel>,
    action: GetProcessByEntityIdRequest
  ): any {
    if (action.payload !== undefined && action.payload !== null) {
      ctx.patchState({
        locationDetails: action.payload,
      });
    } else {
      ctx.patchState({
        locationDetails: {},
      });
    }
  }

  @Action(GetRelationshipByCodeRequest)
  getRelationshipByCodeRequest(
    ctx: StateContext<EntityStateModel>,
    action: GetRelationshipByCodeRequest
  ): any {
    return this.entityService
      .getRelationshipByCode(action.start, action.end)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            relationshipTypeMaster: res.data,
          });
        } else {
          ctx.patchState({
            relationshipTypeMaster: [],
          });
        }
      });
  }

  @Action(GetCharacteristicProcess)
  getCharacteristicProcess(
    ctx: StateContext<EntityStateModel>,
    action: GetCharacteristicProcess
  ): any {
    return this.entityService
      .getCharacteristicProcess(
        action.payload.entityID,
        action.payload.entityTypeID,
        action.payload.characteristicTypeID,
        action.payload.characteristicRelationshipID,
        action.payload.characteristicDirectionRelationshipID
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            characteristicProcess: res.data,
          });
        } else {
          ctx.patchState({
            characteristicProcess: [],
          });
        }
      });
  }

  @Action(GetCharacteristicRelationshipByCodeRequest)
  getCharacteristicRelationshipByCodeRequest(
    ctx: StateContext<EntityStateModel>,
    action: GetCharacteristicRelationshipByCodeRequest
  ): any {
    return this.entityService
      .getCharacteristicRelationship(
        action.entityTypeID,
        action.characteristicTypeID
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            characteristicRelationship: res.data,
          });
        } else {
          ctx.patchState({
            characteristicRelationship: [],
          });
        }
      });
  }

  @Action(GetProcessRelationship)
  getProcessRelationship(
    ctx: StateContext<EntityStateModel>,
    action: GetProcessRelationship
  ): any {
    return this.entityService
      .getProcessRelationship(action.entityTypeID, action.processTypeID)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            processRelationship: res.data,
          });
        } else {
          ctx.patchState({
            processRelationship: [],
          });
        }
      });
  }

  @Action(GetProcessIntent)
  getProcessIntent(ctx: StateContext<Partial<EntityStateModel>>): any {
    var processId = localStorage.getItem("processId");
    return this.entityService
      .getProcessIntent(processId)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            processIntentList: res.data,
          });
        } else {
          ctx.patchState({
            processIntentList: [],
          });
        }
      });
  }

  @Action(GetProcessKnowledgeBases)
  getProcessKnowledgeBases(ctx: StateContext<Partial<EntityStateModel>>): any {
    var processId = localStorage.getItem("processId");
    return this.entityService
      .getProcessKnowledgeBases(processId)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            processKnowledgeBasesList: res.data,
          });
        } else {
          ctx.patchState({
            processKnowledgeBasesList: [],
          });
        }
      });
  }

  @Action(GetCharacteristicEntity)
  getCharacteristicEntity(ctx: StateContext<Partial<EntityStateModel>>): any {
    var characteristicID = localStorage.getItem("characteristicID");
    return this.entityService
      .getCharacteristicEntity(characteristicID)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            characteristicEntityList: res.data,
          });
        } else {
          ctx.patchState({
            characteristicEntityList: [],
          });
        }
      });
  }

  @Action(GetAllParentEntities)
  getAllParentEntities(ctx: StateContext<Partial<EntityStateModel>>): any {
    return this.entityService
      .getAllParentEntities()
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            allParentEntities: res.data,
          });
        } else {
          ctx.patchState({
            allParentEntities: [],
          });
        }
      });
  }

  resetSnacker(ctx: StateContext<any>, item): any {
    setTimeout(() => {
      ctx.patchState(item);
    }, 2000);
  }
}
