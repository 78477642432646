import { M } from "@angular/cdk/keycodes";
import { environment } from "environments/environment";
const expiryDate = () => {
    const date = new Date()
    const month = (date.getMonth() + 1) % 12;
    return month;
}
export const VARIABLE_CONSTANT = {
    expiryDate: expiryDate(),
    defaultPath: '/',
    DOMAIN: environment.domain,
    statict: 'Strict',
}