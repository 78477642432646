export const locale = {
  lang: "tr",
  data: {
    NAV: {
      APPLICATIONS: "Programlar",
      PROFILE: {
        TITLE: "Profil",
        BADGE: "15",
      },
      MANAGE_BOTS: {
        TITLE: "Botları Yönet",
        BADGE: "30",
      },
      TIMELINE: {
        TITLE: "TIMELINE",
        BADGE: "35",
      },
      CHAT: {
        TITLE: "Chat",
        BADGE: "40",
      },
      CHAT_EMULATOR: {
        TITLE: "Sohbet Emülatörü",
        BADGE: "30",
      },
    },
  },
};
