// user.action.ts

import { State, Action, StateContext, Selector, Store } from "@ngxs/store";
import { Navigate } from "@ngxs/router-plugin";
import { environment } from "environments/environment";
import { LoginFortmatic, SetAccessToken } from "./login.actions";
import { Login, FortmaticLogin } from "../login/login.model";
import { SharedApiService } from "app/main/shared-module/services/shared.api.service";
import { APP_ROUTES } from "app/main/shared-module/constants/AppRoutes";

@State<FortmaticLogin>({
  name: "login",
  defaults: {
    isLoggedIn: false,
    userID: null,
    userEmail: null,
    token: null,
  },
})
export class LoginState {
  socket: any;
  constructor(private store: Store, private api: SharedApiService) {}

  ngxsOnInit(ctx: StateContext<Login>) {
    let state = ctx.getState();
    if (state.token) this.store.dispatch(new Navigate(["/profile"]));
  }

  @Selector()
  static getToken(state: FortmaticLogin) {
    return state.token;
  }

  @Action(LoginFortmatic)
  loginUserFortmatic(
    { getState, patchState }: StateContext<FortmaticLogin>,
    { payload }: any
  ) {
    let state = getState();
    let data = {
      access_token: payload.token,
      isLoggedIn: payload.isLoggedIn,
      userID: payload.userID,
      userEmail: payload.userEmail,
    };
    this.store.dispatch(new SetAccessToken(payload));
  }

  @Action(SetAccessToken)
  setAccessToken(
    { getState, patchState }: StateContext<FortmaticLogin>,
    { payload }: any
  ) {
    if (!payload) return;
    patchState({
      token: payload.token,
      isLoggedIn: payload.isLoggedIn,
      userID: payload.userID,
      userEmail: payload.userEmail,
    });
    if (payload.isLoggedIn) {
      this.store.dispatch(new Navigate(["/profile"]));
    } else {
      this.store.dispatch(new Navigate(["/" + APP_ROUTES.COMPLETE_PROFILE]));
    }
  }
}
