import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ToastrService } from "ngx-toastr";
import { Observable, Subscription, throwError } from "rxjs";
import { ErrorHandleService } from "app/services/error.handle.service";
import { CookieService } from 'ngx-cookie-service';

const SERVER_URL = environment.baseUrl;
@Injectable({
  providedIn: "root",
})
export class ApiService { 
  @Output() particularPost: EventEmitter<any> = new EventEmitter();
  @Output() totalUnreadMessageCount = new EventEmitter();
  @Output() profileImgFunction = new EventEmitter();

  subsVar: Subscription;
  public data: any;
  public options: any;
  public particularPostId: any;
  public totalUnreadMessage: Number = 0;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private he: ErrorHandleService,
    private cookieService: CookieService
  ) {}

  profileImage() {
    this.profileImgFunction.emit();
  }

  loginFortmatic(data): Promise<any> {
    let userData = {
      fortmatic_id: data.fortmatic_id,
      userEmailID: data.userEmailID,
    };
    return this.http
      .post(environment.baseUrl + "/user-auth/fortmatic/login", userData)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  logout() {
    return this.http
      .get(environment.baseUrl + "/user-auth/logout")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  } 
  accessCodeApi(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/access-code", data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getChatImage(key): Promise<any> {
    return this.http
      .get(
        environment.baseUrl + "/bot-attachments/" + key
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getInvitationList(): Promise<any> {
    return this.http
      .get(
        environment.baseUrl + "/connection/user/invitations/pending-invitations"
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getFilterEntityContent(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/entity_type_master")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getDomainContent(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/domain_master")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getProcessContent(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/process_type_master")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getCharacteristicContent(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/characteristic_type_master")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getCharacterSticByEntId(entityId, type): Promise<any> {
    return this.http
      .get(
        environment.baseUrl +
        "/user-created-characteristic/user/" +
        entityId +
        "/" +
        type
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  sendFilterContents(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/user/filterSearch", data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getKycImageContent(imageId): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/file/image/" + imageId)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getKycFilesImageContent(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/user/user-kyc-doc")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  updateInvitationStatus(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/connection/updateConnection", data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  acceptInvitation(invitationID: string): Promise<any> {
    return this.http
      .post(
        environment.baseUrl + "/connection/accept-invitation/" + invitationID,
        null
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  ignoreInvitation(invitationID: string) {
    return this.http
      .post(
        environment.baseUrl + "/connection/ignore-invitation/" + invitationID,
        null
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  removeInvitation(invitationID: string) {
    return this.http
      .post(
        environment.baseUrl + "/connection/remove-connection/" + invitationID,
        null
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  cancelInvitation(invitationID: string) {
    return this.http
      .post(
        environment.baseUrl + "/connection/cancel-invitation/" + invitationID,
        null
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  sendInvitationStatus(data): Promise<any> {
    return this.http
      .post(
        environment.baseUrl + "/connection/send-invitation/" + data.invitee,
        null
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getProposalList(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/user/user-proposals")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getConnectionList(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/connection/user/connections/self")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getSentInvitations(): Promise<any> {
    return this.http
      .get(
        environment.baseUrl + "/connection/user/invitations/sent-invitations"
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  cancelSentInvitation(invitationsId: any): Promise<any> {
    return this.http
      .get(
        environment.baseUrl + "/connection/cancel-invitation/" + invitationsId
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getFriendListNew(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/user_connections")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  searchInputData(data: any): void {
    this.data = data;
  }

  passInputValue(): void {
    return this.data;
  }

  getchatlist(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  // get post data
  getPostData(userId, page): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/timeline/posts/" + page)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getPostDataNew(page): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/timeline/posts/" + page)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getFeaturedPost(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/timeline/featured-post")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getSingleFeaturedPost(postId): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/timeline/single-featured-post/" + postId)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getPostDataById(type, userId, page): Promise<any> {
    return this.http
      .get(
        environment.baseUrl +
        "/timeline/posts/" +
        type +
        "/" +
        userId +
        "/" +
        page
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getParticularPost(data, status): void {
    if (status) {
      this.particularPostId = null;
      this.particularPost.emit(data);
    } else {
      this.particularPostId = data.postId;
    }
  }

  // like post
  likePost(postId): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/timeline/like-post/" + postId, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  sharePost(postId): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/timeline/share-post/" + postId, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  commentOnPost(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/timeline/comment-post-1/", data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  commentOnPostNew(data, postId): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/timeline/comment-post/" + postId, data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getPreviousComments(len, postId): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/timeline/post-comments/" + postId)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getSelfPostData(userId, page): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/timeline/self-posts/" + page)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getSelfFeaturedPost(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/timeline/self-featured-posts")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  deletePostComment(commentId, postId): Promise<any> {
    return this.http
      .post(
        environment.baseUrl +
        "/timeline/delete-comment/" +
        postId +
        "/" +
        commentId,
        {}
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getProfileData(userId): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/user/user-details/self")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getAllNotificationCount(userId): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/notification/unread-count")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getAllNotification(userId, offset): Promise<any> {
    return this.http
      .get(
        environment.baseUrl +
        "/notification/get-notifications/" +
        offset +
        "/10"
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  deleteNotification(postId): Promise<any> {
    return this.http
      .post(
        environment.baseUrl + "/notification/delete-notification/" + postId,
        {}
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  readNotification(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/notification/mark-as-read", data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getParticularPostData(postId): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/timeline/single-post/" + postId)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getLikedUsers(postId): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/timeline/like-post/" + postId, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getLikedUsersList(postId): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/timeline/post-likes/" + postId)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  deletePost(postId): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/timeline/delete-post/" + postId, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  senderUsersId(id, type): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/user/user-details/" + id + "/" + type)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  // Manage-Bots API //
  getBotsByUsrId(type, id): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/bot/user-bots/" + type + "/" + id)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  createBot(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/bot", data, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  saveIntegrations(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/bot-integration", data, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getBotById(botId, type): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/bot/single/" + botId + "/" + type)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getBotByEntId(entityId, type): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/bot/entity-bots/" + entityId + "/" + type)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getOwnerBot(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/owner/bots")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  // Manage-Bots API //

  // Manage-Entity API //
  createEntity(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/user-created-entity", data, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  editEntity(data, editEntity): Promise<any> {
    return this.http
      .put(environment.baseUrl + "/user-created-entity/" + editEntity, data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  deactivateEntity(id, data): Promise<any> {
    return this.http
      .post(
        environment.baseUrl + "/user-created-entity/deactivate-entity/" + id,
        data,
        {}
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  createRequests(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/requests/", data, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  createFeaturedEntity(id, data): Promise<any> {
    return this.http
      .post(
        environment.baseUrl + "/user-created-entity/feature/" + id,
        data,
        {}
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  createKnowledgeDocument(data, id): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/knowledge-base/" + id, data, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  // End Manage-Entity API //

  getEntityById(type, userId): Promise<any> {
    return this.http
      .get(
        environment.baseUrl + "/user-created-entity/user/" + type + "/" + userId
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getEntityByIdSingle(entityId, type): Promise<any> {
    return this.http
      .get(
        environment.baseUrl +
        "/user-created-entity/single/" +
        entityId +
        "/" +
        type
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  timelineLikePost(postId) {
    return this.http
      .post(environment.baseUrl + "/timeline/like-post/" + postId, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  timelineSharePost(postId) {
    return this.http
      .post(environment.baseUrl + "/timeline/share-post/" + postId, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  // Manage-Connection API //
  getUserConnectionById(id, type): Promise<any> {
    return this.http
      .get(
        environment.baseUrl +
        "/connection/user/user-connections/" +
        id +
        "/" +
        type
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  // Mangage Settings API
  getSelfSetting(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/user/profile-settings/self")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  // Mangage Settings API

  // Privacy Policy API
  updatePrivacyPolicy(data): Promise<any> {
    return this.http
      .put(environment.baseUrl + "/user/update-profile-settings", data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  deactivateUser(): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/user/deactivate", {}) //
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  // Privacy Policy API

  // Search All API
  userSearchAll(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/user/search-all", data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  // Search All API

  createProfileImage(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/user/upload-profile-photo", data, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  createkycConsentImage(data): Promise<any> {
    return this.http
      .post(
        environment.baseUrl + "/user/upload-kyc-doc-consent-photo",
        data,
        {}
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  kycVerifyAPI(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/user-auth/user/kyc/verify", data, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  requestOTP(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/user-auth/user/otp/request", data, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  verifyOTPRequest(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/user-auth/user/otp/verify", data, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  verifyOTPCancelRequest(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/user-auth/user/otp/cancel", data, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  marketPlaceEntity(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/marketplace/entities")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  marketPlaceBot(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/marketplace/bots")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getParentEntity(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/parent_entities/0")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getParentProcess(entityID): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/parent_processes/" + entityID)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getProcessByEntId(entityId, type): Promise<any> {
    return this.http
      .get(
        environment.baseUrl +
        "/user-created-process/user/" +
        entityId +
        "/" +
        type
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getEntityProcessRelationShip(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/entity_parent_relationship")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getMarketplaceUsrReq(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/marketplace/user-requests")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getMarketPlaceApprovalReq(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/marketplace/approver-requests")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getBotTargetEntity(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/owner_entities")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getBotTargetProcess(entityID): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/owner_processes/" + entityID)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getMarketTransfer(entityID, data): Promise<any> {
    return this.http
      .post(
        environment.baseUrl + "/marketplace/entity-transfer/" + entityID,
        data
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getMarketClone(entityID, data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/marketplace/entity-clone/" + entityID, data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getMarketTransaction(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/marketplace/transactions")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  marketWithDrawReq(req_id): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/marketplace/withdraw-request/" + req_id, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  requestApproval(req_id): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/marketplace/approve-request/" + req_id, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  rejectApproval(req_id): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/marketplace/reject-request/" + req_id, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  marketBotTransfer(botID, data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/marketplace/bot-transfer/" + botID, data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  marketBotClone(botID, data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/marketplace/bot-clone/" + botID, data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  // create timeline popup api
  getFriendList(): Promise<any> {
    let userId = this.cookieService.get('userId') || environment.userId;
    return this.http
      .get(
        environment.baseUrl + "/connection/connections/" + userId
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getfilterContent(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/user/filterContent")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  creatPost(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/timeline/createPost-1", data)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  creatPostNew(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/timeline/create", data, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  createPostFeatured(data): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/timeline/create-featured-post", {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getEntity(userId): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/owner_entities")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getEntityNew(userId): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/owner_entities")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getProcess(entityId): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/owner_bots")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getProcessNew(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/owner_bots")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  // end timeline popup api

  getRichText(type): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/registries/rich-text" + "/" + type)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getAccociateProcess(processTypeID, EntityID): Promise<any> {
    return this.http
      .get(
        environment.baseUrl +
        "/user-created-process/associate-process/" +
        processTypeID +
        "/" +
        EntityID
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getCharacteristicEntity(processID, EntityID): Promise<any> {
    return this.http
      .get(
        environment.baseUrl +
        "/dropdown/characteristic-entity/" +
        processID +
        "/" +
        EntityID
      )
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  getLanguage(): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/language" + "/")
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getNluModel(id): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/process-intent" + "/" + id)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }
  retrainBot(formData, botId): Promise<any> {
    return this.http
      .post(environment.baseUrl + "/bot/retrain/" + botId, formData, {})
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }


  getChooseIntent(id): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/process-intent" + "/" + id)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  getFalbackIntent(id): Promise<any> {
    return this.http
      .get(environment.baseUrl + "/dropdown/process-intent" + "/" + id)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise();
  }

  get(urlAddress: string, options?: any): Observable<any> {
    return this.http
      .get<any>(SERVER_URL + urlAddress)
      .pipe(map((data) => data));
  }

  post(urlAddress: string, payload?: any, options?: any): Observable<any> {
    return this.http
      .post<any>(SERVER_URL + urlAddress, payload)
      .pipe(map((data) => data));
  }

  put(urlAddress: string, payload: any, options?: any): any {
    return this.http
      .put<any>(SERVER_URL + urlAddress, payload)
      .pipe(map((data) => data));
  }

  delete(urlAddress: string, options?: any): any {
    return this.http
      .delete<any>(SERVER_URL + urlAddress)
      .pipe(map((data) => data));
  }

  sendGetFileRequest(url): any {
    let headers = new HttpHeaders();
    headers = headers.append("Accept", "text/csv; charset=utf-8");
    return this.http.get(url, {
      headers: headers,
      observe: "response",
      responseType: "text",
    });
  }

  onStatusCraterialCheck(param1, param2) {
    const params1 = { ...param1 };
    const params2 = { ...param2 };
    const paramsOne = { ...param1 };
    const paramsTwo = { ...param2 };
    delete params1["status"];
    delete params2["status"];

    if (JSON.stringify(param1) === JSON.stringify(param2)) {
      return false;
    } else if (
      param1.status !== param2["status"] &&
      JSON.stringify(params1) !== JSON.stringify(params2)
    ) {      
      this.toastr.info(
        "No other field except for Comment can be updated while Status update."
      );
      return false;
    } else {
      for (let label in param1) {
        if (paramsOne[label] === paramsTwo[label]) {
          delete paramsTwo[label];
        }
      }
      return paramsTwo;
    }
  }
}
