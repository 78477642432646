import { Component, OnInit } from "@angular/core";
import {  FormGroup, FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material";
import { DatePipe } from "@angular/common";
import { ApiService } from "app/services/api.service";
import { MarketBotDialogComponent } from "app/main/shared-module/common/market-bot-dialog/market-bot-dialog.component";
import { environment } from "environments/environment";
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: "app-bot-profile",
  templateUrl: "./bot-profile.component.html",
  styleUrls: ["./bot-profile.component.scss"],
})
export class BotProfileComponent implements OnInit {
  title = "Bot Details";
  rootTitle = "Manage Bots";
  botId = "";
  currentBot: any = {};
  pramsuserid: any;
  botData: any;
  botsForm: FormGroup;
  cloneable: any;
  live: any;
  getUserDetails: any;
  owner: string;
  processName: any;
  processDescription: any;
  clonedFrom: any;
  entityDescription: any;
  entityName: any;
  modifiedBy: any;
  avatar: any;
  successMsg: any;
  successValue: any;
  ownerId: any;
  getAccessValue: any;
  getAccessMsg: any;
  clonedFromId: any;
  singleBotData: any;
  public createdByUser: boolean = false;
  public ownerUser: boolean = false;
  userId: any;

  constructor(    
    private api: ApiService,
    private route: ActivatedRoute,
    private _form: FormBuilder,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.userId = this.cookieService.get('userId') || environment.userId
    const paramsData = this.route.snapshot.params;
    this.pramsuserid = paramsData.id;

    // bot_API_calling
    this.api.getBotById(this.pramsuserid, "MAIN").then((data) => {
      this.getAccessValue = data.access;
      this.getAccessMsg = data.msg;
      this.successValue = data.success;
      if (data.success == false) {
        this.successMsg = data.msg;
      } else {
        this.singleBotData = data.data;

        this.botData = data.data;
        var userFirstName = this.botData.userDetails[0].userFirstName;
        var userLastName = this.botData.userDetails[0].userLastName;

        var name = this.botData.name;
        this.avatar = this.botData.avatar;
        this.ownerId = this.botData.owner;
        this.owner = userFirstName + " " + userLastName;
        var status = this.botData.status;
        var summary = this.botData.summary;
        var createdBy = this.botData.createdBy;
        var createdOn = this.botData.createdOn;
        var toconvertDate = new Date(createdOn);
        var hostIP = this.botData.hostIP;
        var clone = this.botData.cloneable;

        var cloneValue: any;
        if (clone) {
          cloneValue = "Yes";
        }
        if (!clone) {
          cloneValue = "No";
        }

        this.clonedFrom =
          this.botData.clonedFromBotDetails.length > 0
            ? this.botData.clonedFromBotDetails[0].name
            : "";
        //this.botData.clonedFromBotDetails[0].name

        this.clonedFromId =
          this.botData.clonedFromBotDetails.length > 0
            ? this.botData.clonedFromBotDetails[0]._id
            : "";
        //this.botData.clonedFromBotDetails[0]._id
        this.cloneable = this.botData.cloned;
        this.live = this.botData.live;
        this.processName =
          this.botData.processDetails.length > 0
            ? this.botData.processDetails[0].processName
            : "";
        this.processDescription =
          this.botData.processDetails.length > 0
            ? this.botData.processDetails[0].processDescription
            : "";
        this.entityDescription =
          this.botData.entityDetails.length > 0
            ? this.botData.entityDetails[0].entityDescription
            : "";
        this.entityName = this.botData.entityDetails;
        this.modifiedBy = this.botData.modifiedBy;

        this.owner =
          this.singleBotData.userDetails &&
          this.singleBotData.userDetails.length > 0
            ? this.singleBotData.userDetails[0].userFirstName +
              " " +
              this.singleBotData.userDetails[0].userLastName
            : "";

        this.botsForm = this._form.group({
          name: this.singleBotData.name,
          summary: this.singleBotData.summary, //[{ value: summary, disabled: true }],
          owner: this.owner, //[{ value: '' }],
          status: this.singleBotData.status,
          createdBy:
            this.singleBotData.createdByDetails.length > 0
              ? this.singleBotData.createdByDetails[0].userFirstName +
                " " +
                this.singleBotData.createdByDetails[0].userLastName
              : "",
          createdOn: this.datePipe.transform(
            this.singleBotData.createdOn,
            "d MMMM y HH:MM:SS"
          ),
          entity:
            this.singleBotData.entityDetails.length > 0
              ? this.singleBotData.entityDetails[0].entityName +
                " " +
                this.singleBotData.entityDetails[0].entityDescription
              : "",
          process:
            this.singleBotData.processDetails.length > 0
              ? this.singleBotData.processDetails[0].processName +
                " " +
                this.singleBotData.processDetails[0].processDescription
              : "",
          clone: [{ value: cloneValue, disabled: true }],
          hostIP: this.singleBotData.hostIP,
          clonedFromId: this.clonedFrom,
        });

        if (this.singleBotData.createdByDetails.length > 0) {
          if (this.singleBotData.createdByDetails[0]._id == this.userId) {
            this.createdByUser = true;
          }
        }

        if (this.singleBotData.owner == this.userId) {
          this.ownerUser = true;
        }

        this.botsForm.controls["name"].disable();
        this.botsForm.controls["summary"].disable();
        this.botsForm.controls["owner"].disable();
        this.botsForm.controls["status"].disable();
        this.botsForm.controls["createdBy"].disable();
        this.botsForm.controls["createdOn"].disable();
        this.botsForm.controls["entity"].disable();
        this.botsForm.controls["process"].disable();
        this.botsForm.controls["clone"].disable();
        this.botsForm.controls["hostIP"].disable();
        this.botsForm.controls["clonedFromId"].disable();
      }
    });
  }

  getImage() {
    return "url(assets/images/avatars/botDavatar.png)";
  }

  getProperImage() {
    return "url(" + this.avatar + ")";
  }

  openToBuyBot(id, type) {
    const dialogRef = this.dialog.open(MarketBotDialogComponent, {
      width: "650px",
      data: {
        id: id,
        cardType: type,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }
}
