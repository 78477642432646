import { Component, OnInit, ViewChild, Input, ElementRef } from "@angular/core";
import Cropper from "cropperjs";
import { MatDialogRef } from "@angular/material";

@Component({
  selector: "app-image-cropper",
  templateUrl: "./image-cropper.component.html",
  styleUrls: ["./image-cropper.component.scss"],
})
export class ImageCropperComponent implements OnInit {
  @ViewChild("image") imageElement: ElementRef;

  public imageSource: string;

  public imageDestination: string;
  private cropper: Cropper;

  public constructor(public dialogRef: MatDialogRef<ImageCropperComponent>) {
    this.imageDestination = "";
  }

  public ngAfterViewInit() {
    this.cropper = new Cropper(this.imageElement.nativeElement, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      crop: () => {
        const canvas = this.cropper.getCroppedCanvas();
        this.imageDestination = canvas.toDataURL("image/png");
      },
    });
  }

  public ngOnInit() {}

  onClose(status: boolean): void {
    this.dialogRef.close({
      dialogHeader: "confirmation",
      content: status ? this.imageDestination : "",
    });
  }
}
