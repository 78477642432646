import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Data,  } from "@angular/router";
import { ApiService } from "app/services/api.service";
import { MatDialog } from "@angular/material/dialog";
import { FormGroup, FormBuilder, } from "@angular/forms";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-market-entity-dialog",
  templateUrl: "./market-entity-dialog.component.html",
  styleUrls: ["./market-entity-dialog.component.scss"],
})
export class MarketEntityDialogComponent {
  entityData: any;
  botData: any;
  checkType: any;
  requestForm: FormGroup;
  ef: any;
  entityID: any;
  TransferProcessMsg: any;

  constructor(
    public dialogRef: MatDialogRef<Data>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    public toastr: ToastrService,    
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.checkType = this.data.cardType;
    this.entityID = this.data.id;
    this.entityData = this.data.data;
    this.botData = this.data.data;
  }

  decline(): void {
    this.dialogRef.close(false);
  }

  submitBuy() {
    this.api.getMarketTransfer(this.data.id, "").then((data) => {
      if (data.success == false) {
        this.TransferProcessMsg = data.msg;
        this.toastr.error(this.TransferProcessMsg);
        this.dialogRef.close(false);
        
      } else {
        this.TransferProcessMsg = data.msg;
        this.toastr.success(this.TransferProcessMsg);
        this.dialogRef.close(false);
        
      }
    });
  }

  submitClone() {
    this.api.getMarketClone(this.data.id, "").then((data) => {
      if (data.success == false) {
        this.TransferProcessMsg = data.msg;
        this.toastr.error(this.TransferProcessMsg);
        
        this.dialogRef.close(false);
      } else {
        this.TransferProcessMsg = data.msg;
        this.toastr.success(this.TransferProcessMsg);
        
        this.dialogRef.close(false);
      }
    });
  }

  cancelFun() {
    this.dialogRef.close(false);
  }
}
