import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  FuseSidebarModule,
  FuseThemeOptionsModule,
  FuseProgressBarModule,
} from "@fuse/components";
import { TranslateModule } from "@ngx-translate/core";
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { LoginState } from "./store/login/login.state";
import { UserState } from "./store/user/user.state";
import { fuseConfig } from "app/fuse-config";
import { AppComponent, SnackbarComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { LoginModule } from "app/main/userlogin/login/login.module";
import { ToastrModule } from "ngx-toastr";
import { CompleteProfileModule } from "./main/userlogin/complete-profile/complete-profile.module";
import { EntityListState } from "./store/entity/entity.state";
import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import { SocketServiceService } from "./services/socket-service.service";
import { FuseInnerScrollDirective } from "@fuse/directives/fuse-inner-scroll/fuse-inner-scroll.directive";
import {
  NgxsStoragePluginModule,
  NgxsStoragePluginOptions,
} from "@ngxs/storage-plugin";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import "hammerjs";
import { AgmCoreModule } from "@agm/core";
import { BotState } from "./store/bot/bot.state";
import { BotService } from "./store/bot/bot.service";
import { MaterialModule } from "./main/shared-module/modules/material.module";
import { ApiService } from "./services/api.service";
import { JwtInterceptor } from "./main/shared-module/services/auth.interceptor";
import { AuthGuardService as AuthGuard } from "app/main/shared-module/services/auth.guard.service";
import { AppRoutingModule } from "./app-routing.module";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { DetailsScreenModule } from "./main/details-screen-module/details-screen.module";
import { ConnectionServiceModule } from "ng-connection-service";
 
// ngxs storage plugin options
const pluginOptions: NgxsStoragePluginOptions = {
  key: ["login", "user"],
};

@NgModule({
  declarations: [AppComponent, SnackbarComponent],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    MaterialModule,
    LayoutModule,
    DetailsScreenModule,
    LoginModule,
    CompleteProfileModule,
    NgxsStoragePluginModule.forRoot(pluginOptions),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    AppRoutingModule,
    NgxsModule.forRoot([LoginState, UserState, EntityListState, BotState]),
    TranslateModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "IzaSyB5tSOBpFlOkQGgiv0pKMjImUkd12mw_iE",
      libraries: ["places"],
    }),
    ToastrModule.forRoot({
      positionClass: "toast-top-center",
      closeButton: true,
    }),
    BrowserAnimationsModule,
    ConnectionServiceModule,
  ],
  exports: [],
  providers: [
    ApiService,
    BotService,
    SocketServiceService,
    FuseInnerScrollDirective,
    SocketServiceService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [SnackbarComponent],
})
export class AppModule {}
