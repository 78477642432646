import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Data } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ApiService } from "app/services/api.service";
import { ToastrService } from "ngx-toastr";
import { ConfirmationModalComponent } from "../confirmation-modal/confirmation-modal.component";
import { environment } from "environments/environment";
import { MutualListModalComponent } from "../mutual-list-modal/mutual-list-modal.component";

@Component({
  selector: "app-proposal-list-modal",
  templateUrl: "./proposal-list-modal.component.html",
  styleUrls: ["./proposal-list-modal.component.scss"],
})
export class ProposalListModalComponent {
  public getMutualListData: any;
  public defaultImg: any = "/assets/images/avatars/no-photo.png";

  constructor(
    public dialogRef: MatDialogRef<Data>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    public toastr: ToastrService,
    public dialog: MatDialog
  ) {}

  decline(): void {
    this.dialogRef.close(false);
  }

  getMutualList(data: any): void {
    this.getMutualListData = data.mutuals;
    const dialogRef = this.dialog.open(MutualListModalComponent, {
      width: "550px",
      data: {
        MutualCount: this.getMutualListData.length,
        MutualconList: this.getMutualListData,
      },
      //data: this.getMutualListData
    });
  }

  openToConnectModal(data): void {
    const sendData = {
      header: "Connect",
      body: "Are you sure you want to send the invitation ?",
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: "450px",
      data: sendData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const sendData_ = {
          sender: environment.userId,
          invitee: data.user_id,
          state: "pending",
          userName: `${data.first_name} ${data.last_name}`,
        };
        this.sendInvitation(sendData_);
      }
    });
  }

  sendInvitation(data): void {
    this.api.sendInvitationStatus(data).then((response: any) => {
      this.data.map((connectionItem, index_) => {
        if (connectionItem.user_id === data.invitee) {
          this.data[index_]["pending"] = true;
        }
      });

      if (response.success && data.userName) {
        this.toastr.success("Request Sent to " + data.userName);
      }

      if (!response.success) {
        this.toastr.warning(response.msg);
      }
    });
  }
}
