import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../services/api.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationModalComponent } from "../../shared-module/common/confirmation-modal/confirmation-modal.component";
import { environment } from "../../../../environments/environment";
import { ToastrService } from "ngx-toastr";
import { MutualListModalComponent } from "../../shared-module/common/mutual-list-modal/mutual-list-modal.component";
import { MarketEntityDialogComponent } from "../../shared-module/common/market-entity-dialog/market-entity-dialog.component";
import { MarketBotDialogComponent } from "../../shared-module/common/market-bot-dialog/market-bot-dialog.component";
import { APP_ROUTES } from "app/main/shared-module/constants/AppRoutes";
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent implements OnInit {
  /**
   *
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  @ViewChild("tabGroup") tabGroup;

  requestForm: FormGroup;
  public state: any = false;
  public userId: any;
  //public defaultImg: any = "/assets/images/avatars/profile.jpg";
  public defaultImg: any = "/assets/images/avatars/no-photo.png";
  public allInvitationList: any = [];
  public dataInvitationList: any = [];
  public invitationIndex: any = 0;
  public allProposalList: any = [];
  public dataProposalList: any = [];
  public proposalIndex: any = 0;
  public invitationLoader: Boolean = true;
  public proposalLoader: Boolean = true;
  public connectionLoader: Boolean = true;
  public allConnectionList: any = [];
  public getMutualListData: any;
  public dataSend: any;
  public index: any = -1;
  public sendData_: any;
  public _sendData_: any;
  public fileData: any;
  public timeline = [];
  public timelinePage = 1;
  public timelinePages = 1;
  public timelinePageLoading = false;
  public getTimelineStatus = false;
  public timeLineMorePost = false;
  public inputFieldCreatePost: "";
  public textData: string;
  public picCount: Array<String> = [];
  public imgSelected = false;
  public imgURL: any;
  public selectedIndex: Number = 0;
  public getAllPostDataStatus: Boolean = false;
  public openConnection: Boolean = false;
  public searchConnectionList: any[] = [];
  public messageList: any[] = [];
  public friends: any[] = [];
  public searchfriends: any[] = [];
  public toShow: boolean = false;
  public showEntityList: boolean = true;
  public toShowBot: boolean = false;
  public toShowEntity: boolean = false;

  public menTionedFriend = {
    postId: "",
    value: [],
  };
  public getAllConnectionList = [];
  public getingSinglePost = false;
  public likedUserList = [];
  public searchUserText = "";
  profilePhotoUrl: any;
  connectionFName: any;
  connectionLName: any;
  connectionMailID: any;
  connectionContact: any;
  backTo: boolean = false;
  userBirthDate: any;
  userGender: any;
  profilePicture: any;
  userFromLocation: any;
  userDescription: any;
  // docKYCFileIDUrl: any;
  // docKYCFileID: any;
  consentKYCFileIDUrl: any;
  // consentKYCFileID: any;
  // userKYCFlag: any;
  kycStatus: boolean;
  userKYCFlag: any;
  inviteeID: any;
  timelineData: any;
  senderUserId: any;
  userSenderId: string;
  displayedColumns: string[] = [];
  title = "Bots List";
  rootTitle = "Manage Bots";
  getBotData: any;
  users: PeriodicElement[] = [];
  data: any;
  userConnection: any;
  timelineRestrict: any;
  timelinehide: boolean = false;
  timelineSuccess: any;
  entityHide: boolean = false;
  entitySuccess: any;
  enityRestrict: any;
  botSuccess: any;
  botHide: boolean = false;
  botRestrict: any;
  bTnConnectHide: boolean = false;
  btnConnect: any;
  discovrable: any;
  hide_timeline: any;
  allow_messages: any;
  hide_entities: any;
  hide_bots: any;
  hide_connections: any;
  connectRestrict: any;
  connectionSuccess: any;
  entityData: any;
  pramsuserid: any;
  searchUsrData: any;
  bTnSearchUsr: any;
  showSearchMsg: any;
  idUser: any;
  showMutualConnections: any;
  showMutualLength: any;
  mutualConnection: any;
  mutualConnectLength: any;
  chkConnection: any;
  mutualCount: any;
  panelOpenState = false;
  likesPostCount: any;
  request_sent: any;
  restrictedMsg: any;
  view_profile: any;
  successRestricted: any;
  accessMsg: any;
  accessValue: any;
  accept_request: any;
  mobileVFlag: any;
  emailVFlag: any;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _form: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    public dialog: MatDialog,
    public toastr: ToastrService,
    private cookieService: CookieService
  ) {
    //this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit() {
    //this.router.navigate(["profile"]);
    if (!this.route.url["value"][0]) {
      this.router.navigate([APP_ROUTES.PROFILE]);
      return;
    }
    let tabUrl = this.route.url["value"][0].path;

    if (tabUrl == "users-post") {
      this.selectedIndex = 1;
    } else if (tabUrl == "users-connections") {
      this.selectedIndex = 2;
    } else if (tabUrl == "users-entity") {
      this.selectedIndex = 3;
    } else if (tabUrl == "users-bot") {
      this.selectedIndex = 4;
    } else {
      this.selectedIndex = 0;
    }
    const paramsData = this.route.snapshot.params;

    this.pramsuserid = paramsData.id;
    if (paramsData.name == "bot") {
      this.toShowBot = true;
      this.api.getBotsByUsrId("MAIN", this.pramsuserid).then((getBotsData) => {
        // Magane-Bots For API //
      });
      return;
    } else {
    }

    if (paramsData.name == "profile") {
      this.userDetails();
    } else {
    }

    this.tabGroup = paramsData.id ? paramsData.id : 0;
    this.showEntityList = true;
    this.route.url.subscribe((params) => {});
  }

  test(e) {
    if (e == 1) {
      this.router.navigateByUrl(`/users-post/${this.pramsuserid}/profile`);
    } else if (e == 2) {
      this.router.navigateByUrl(
        `/users-connections/${this.pramsuserid}/profile`
      );
    } else if (e == 3) {
      this.router.navigateByUrl(`/users-entity/${this.pramsuserid}/profile`);
    } else if (e == 4) {
      this.router.navigateByUrl(`/users-bot/${this.pramsuserid}/profile`);
    } else {
      this.router.navigateByUrl(`/users-details/${this.pramsuserid}/profile`);
    }
  }
  userDetails() {
    this.api.senderUsersId(this.pramsuserid, "MAIN").then((data) => {
      this.accessValue = data.access;
      this.accessMsg = data.msg;
      this.successRestricted = data.success;
      if (data.success == false) {
        this.restrictedMsg = data.msg;
      } else {
        this.searchUsrData = data.userData;

        this.idUser = this.searchUsrData._id;
        this.profilePicture = this.searchUsrData.profilePicture;
        this.connectionFName = this.searchUsrData.userFirstName;
        this.connectionLName = this.searchUsrData.userLastName;
        this.userBirthDate = this.searchUsrData.userBirthDate;
        this.userGender = this.searchUsrData.userGender;
        this.userFromLocation = this.searchUsrData.userFromLocation;
        this.userDescription = this.searchUsrData.userDescription;
        this.connectionMailID = this.searchUsrData.userEmailID;
        this.connectionContact = this.searchUsrData.userMobileNo;
        this.mobileVFlag = this.searchUsrData.mobileVFlag;
        this.emailVFlag = this.searchUsrData.emailVFlag;
        this.mutualConnection = this.searchUsrData.mutuals;
        this.mutualConnectLength = this.searchUsrData.mutuals.length;
        this.userKYCFlag = this.searchUsrData.userKYCFlag;
        // this.toShow = true;

        this.kycStatus = this.userKYCFlag ? true : false;
        this.requestForm = this._form.group({
          fName: [{ value: this.connectionFName, disabled: true }],
          lName: [{ value: this.connectionLName, disabled: true }],
          dob: [{ value: this.userBirthDate, disabled: true }],
          gender: [{ value: this.userGender, disabled: true }],
          location: [{ value: this.userFromLocation, disabled: true }],
          userDescription: [{ value: this.userDescription, disabled: true }],
          connectionMailID: [{ value: this.connectionMailID, disabled: true }],
          connectionContact: [
            { value: this.connectionContact, disabled: true },
          ],
        });

        this.btnConnect = this.searchUsrData.connected;
        this.request_sent = this.searchUsrData.request_sent;
        this.allow_messages =
          this.searchUsrData.ownerPrivacySettings.allow_messages;
        this.discovrable = this.searchUsrData.ownerPrivacySettings.discoverable;
        this.hide_entities =
          this.searchUsrData.ownerPrivacySettings.hide_entities;
        this.hide_bots = this.searchUsrData.ownerPrivacySettings.hide_bots;
        this.hide_connections =
          this.searchUsrData.ownerPrivacySettings.hide_connections;
        this.hide_timeline =
          this.searchUsrData.ownerPrivacySettings.hide_timeline;
        this.view_profile =
          this.searchUsrData.ownerPrivacySettings.view_profile;
        this.accept_request = this.searchUsrData.accept_request;
      }
    });
  }

  getDefaultImage() {
    return "url(" + "../../../assets/images/avatars/no-photo.png" + ")";
  }

  getPorperImage() {
    return "url(" + this.profilePicture + ")";
  }

  getProposalList(): void {
    this.showLoader("proposal");
    this.proposalIndex = 0;
    this.api.getProposalList().then((data: any) => {
      this.hideLoader("proposal");
      this.allProposalList = data;
      this.dataProposalList = this.allProposalList.slice(
        this.proposalIndex,
        this.proposalIndex + 3
      );
      this.proposalIndex += 3;
    });
  }

  showLoader(data): void {
    if (data === "invitation") {
      this.invitationLoader = true;
    } else if (data === "proposal") {
      this.proposalLoader = true;
    } else if (data === "connection") {
      this.connectionLoader = true;
    } else if (data === "all") {
      this.invitationLoader = true;
      this.proposalLoader = true;
      this.connectionLoader = true;
    }
  }

  hideLoader(data): void {
    if (data === "invitation") {
      this.invitationLoader = false;
    } else if (data === "proposal") {
      this.proposalLoader = false;
    } else if (data === "connection") {
      this.connectionLoader = false;
    } else if (data === "all") {
      this.invitationLoader = false;
      this.proposalLoader = false;
      this.connectionLoader = false;
    }
  }

  openToConnectModal(_data): void {
    const sendData = {
      header: "Connect",
      body: "Are you sure you want to send the invitation ?",
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: "450px",
      panelClass: "myapp-no-padding-dialog",
      data: sendData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._sendData_ = {
          sender: this.cookieService.get('userId') || environment.userId,
          invitee: _data._id,
          state: "pending",
          userName: _data.userName,
        };
        this.sendInvitation(this._sendData_);
      }
    });
  }

  proposalNextData(): void {
    if (this.proposalIndex < this.allProposalList.length) {
      this.dataProposalList = this.allProposalList.slice(
        this.proposalIndex,
        this.proposalIndex + 3
      );
      this.proposalIndex += 3;
    }
  }

  proposalPrevData(): void {
    if (this.proposalIndex > 3) {
      this.dataProposalList = this.allProposalList.slice(
        this.proposalIndex - 6,
        this.proposalIndex - 3
      );
      this.proposalIndex -= 3;
    }
  }

  sendInvitation(data): void {
    this.api.sendInvitationStatus(data).then((response: any) => {
      this.allProposalList.map((item, i) => {
        if (data.invitee === item._id) {
          this.index = i;
        }
      });
      this.dataProposalList.map((item, i) => {
        if (data.invitee === item._id) {
          this.index = i;
        }
      });
      if (
        this.dataProposalList.length === 0 &&
        this.allProposalList.length > 0
      ) {
        this.proposalPrevData();
      }
      if (
        this.dataProposalList.length === 2 &&
        this.allProposalList.length > this.proposalIndex - 3
      ) {
        if (this.proposalIndex === 3 && this.allProposalList.length === 3) {
          this.dataProposalList = this.allProposalList;
        } else if (
          this.proposalIndex === 3 &&
          this.allProposalList.length > 3
        ) {
          this.proposalNextData();
          this.proposalPrevData();
        } else {
          this.proposalPrevData();
          this.proposalNextData();
        }
      }
      this.allProposalList.map((connectionItem, index_) => {
        if (connectionItem.proposal._id === data.invitee) {
          this.allProposalList[index_]["pending"] = true;
        }
      });
      if (data.userName) {
        this.toastr.success("Request Sent to " + data.userName);
        this.userDetails();
      } else {
        this.toastr.success("Request Sent");
        this.userDetails();
      }
    });
  }

  openToBuyEntity(id, type) {
    this.dialog.open(MarketEntityDialogComponent, {
      width: "650px",
      data: {
        id: id,
        cardType: type,
      },
    });
  }

  openMutualUserModal(MutualData): void {
    if (MutualData) {
      this.dialog.open(MutualListModalComponent, {
        width: "550px",
        data: {
          MutualconList: MutualData,
          MutualCount: MutualData.length,
        },
      });
    } else {
      this.dialog.open(MutualListModalComponent, {
        width: "550px",
        data: {
          MutualCount: this.mutualConnectLength,
          MutualconList: this.mutualConnection,
        },
      });
    }
  }

  openToBuyBot(id, type) {
    this.dialog.open(MarketBotDialogComponent, {
      width: "650px",
      data: {
        id: id,
        cardType: type,
      },
    });
  }
}

export interface PeriodicElement {
  botId: number;
  createdAt: string;
  name: string;
  entity: any;
  process: any;
  activate: string;
  geoTag: string;
  action: string;
}
