import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,  
} from "@angular/core";
import { ApiService } from "../../../../../services/api.service";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { SocketServiceService } from "app/services/socket-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { FormGroup, FormBuilder } from "@angular/forms";
import { environment } from "environments/environment";
import * as _moment from "moment";
import { CookieService } from 'ngx-cookie-service';
const moment = _moment;

@Component({
  selector: "app-user-timeline",
  templateUrl: "./user-timeline.component.html",
  styleUrls: ["./user-timeline.component.scss"],
})
export class UserTimelineComponent implements OnInit {
  @ViewChild("tabGroup") tabGroup;
  @ViewChild("comment") comment: ElementRef;

  @ViewChild("ta") textarea: ElementRef;
  @ViewChild("img") img: ElementRef;
  @ViewChild("pinput") imgInput: ElementRef;

  //public defaultImg: any = "/assets/images/avatars/profile.jpg";
  public defaultImg: any = "/assets/images/avatars/no-photo.png";
  public allInvitationList: any = [];
  public dataInvitationList: any = [];
  public invitationIndex: any = 0;
  public allProposalList: any = [];
  public dataProposalList: any = [];
  public proposalIndex: any = 0;
  public invitationLoader: Boolean = true;
  public proposalLoader: Boolean = true;
  public connectionLoader: Boolean = true;
  public allConnectionList: any = [];
  public getMutualListData: any;

  private byNotificationClick = false;
  private subscriptions = new Subscription();
  private dataComplate = false;

  public dataSend: any;
  public index: any = -1;
  public sendData_: any;
  public _sendData_: any;
  public fileData: any;
  public timeline = [];
  public timelinePage = 0;
  //public timelinePages = 1;
  public timelinePageLoading = false;
  public getTimelineStatus = false;
  public timeLineMorePost = false;
  public inputFieldCreatePost: "";
  public loggedInUser: string;
  public textData: string;
  public picCount: Array<String> = [];
  public imgSelected = false;
  public imgURL: any;
  public selectedIndex: Number = 0;
  public getAllPostDataStatus: Boolean = false;
  private subscription = new Subscription();
  public openConnection: Boolean = false;
  public searchConnectionList: any[] = [];
  public messageList: any[] = [];
  public friends: any[] = [];
  public searchfriends: any[] = [];
  public toShow: boolean = false;
  public showEntityList: boolean = true;
  public toShowBot: boolean = false;
  public toShowEntity: boolean = false;
  public timelineSelfFeatured = [];

  public menTionedFriend = {
    postId: "",
    value: [],
  };
  public getAllConnectionList = [];
  public noMorePost = false;
  public getingSinglePost = false;
  public likedUserList = [];
  public searchUserText = "";
  profilePhotoUrl: any;
  connectionFName: any;
  connectionLName: any;
  connectionMailID: any;
  connectionContact: any;
  backTo: boolean = false;
  userBirthDate: any;
  userGender: any;
  profilePicture: any;
  userFromLocation: any;
  userLivesIn: any;
  userDescription: any;
  requestForm: FormGroup;
  secondaryEmailID: any;
  consentKYCFileIDUrl: any;
  kycStatus: boolean;
  userKYCFlag: any;
  inviteeID: any;
  timelineData: any;
  senderUserId: any;
  userSenderId: string;
  data: any;
  userConnection: any;
  timelineRestrict: any;
  timelinehide: boolean = false;
  timelineSuccess: any;
  entityHide: boolean = false;
  entitySuccess: any;
  enityRestrict: any;
  botSuccess: any;
  botHide: boolean = false;
  botRestrict: any;
  bTnConnectHide: boolean = false;
  btnConnect: any;
  discovrable: any;
  hide_timeline: any;
  allow_messages: any;
  hide_entities: any;
  hide_bots: any;
  hide_connections: any;
  connectRestrict: any;
  connectionSuccess: any;
  entityData: any;
  pramsuserid: any;
  searchUsrData: any;
  bTnSearchUsr: any;
  showSearchMsg: any;
  idUser: any;
  showMutualConnections: any;
  showMutualLength: any;
  mutualConnection: any;
  mutualConnectLength: any;
  chkConnection: any;
  mutualCount: any;
  panelOpenState = false;
  featureddata = false;
  likesPostCount: any;
  userId;

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private api: ApiService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    private socketService: SocketServiceService,
    private sanitizer: DomSanitizer,
    private _form: FormBuilder,
    private router: Router,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    let tabUrl = this.route.url["value"][0].path;
    this.userId = this.cookieService.get('userId') || this.userId;
    if (tabUrl == "users-post") {
      this.loggedInUser = this.userId;
      this.getUserConnections();
      this.getPostData(this.userId);
      this.getFeaturedPost();
    }
  }

  resetGetPostDataStatus(): any {
    setTimeout(() => {
      this.getAllPostDataStatus = false;
    }, 500);
  }

  getPostData(userId): any {
    this.byNotificationClick = false;
    if (this.getAllPostDataStatus) {
      this.resetGetPostDataStatus();
      return false;
    }
    if (this.timelinePage === 0) {
      this.getTimelineStatus = true;
    }
    this.textarea ? (this.textarea.nativeElement.value = null) : null;
    this.img ? (this.img.nativeElement.value = null) : null;
    this.imgInput ? (this.imgInput.nativeElement.value = null) : null;
    this.picCount = [];
    this.fileData = [];

    const paramsData = this.route.snapshot.params;
    this.pramsuserid = paramsData.id;

    //this.api.getSelfPostData(userId, this.timelinePage).then((res) => {
    this.api
      .getPostDataById("MAIN", this.pramsuserid, this.timelinePage)
      .then((res) => {
        // if (res.data.length == 0) {
        //     this.dataComplate = true;
        // }

        if (res.success == true) {
          this.timelineSuccess = true;
          this.getTimelineStatus = false;
          if (this.timelinePage === 0) {
            this.timeline = res.data;
          } else {
            this.timeline = this.timeline.concat(res.data);
            setTimeout(() => {
              this.timelinePageLoading = false;
            }, 500);
          }
          // this.getFeaturedPost();

          //this.getUserConnections();
          //  this.getConnectionList();
          this.getPostLikedOrNot();
        }
        if (res.success == false) {
          this.timelineSuccess = false;
          this.hide_timeline = false;
          this.timelineRestrict = res.msg;
        }
      });
  }

  async getUserConnections() {
    this.api.getFriendListNew().then((response: any) => {
      response.data.filter((item: any) => {
        if (item.userFirstName && item.userLastName) {
          this.friends.push(item);
        }
      });
      //this.setFriendCheckStatus(this.friends);
      this.searchfriends = this.friends;
    });
  }
  getFeaturedPost(): any {
    this.api.getSelfFeaturedPost().then((res) => {
      this.featureddata = true;
      // this.timeline.push(res.data);
      this.timelineSelfFeatured = res.data;
      // this.timeline = res.data;
      // this.timeline = this.timeline.concat(res.data);
    });
  }

  getPostLikedOrNot() {
    this.timeline.filter((item) => {
      let liked = false;
      if (item.likedBy && item.likedBy.length) {
        item.likedBy.filter((like) => {
          if (like.likedBy === this.userId) {
            liked = true;
          }
        });
      }
      item["youLiked"] = liked;
    });
  }

  getConnectionList(): void {
    // this.showLoader("connection");
    this.api.getConnectionList().then((data: any) => {
      // this.hideLoader("connection");
      // this.getFriends(data);
      this.allConnectionList = data;
    });
  }

  removeimg(i): void {
    this.picCount.splice(i, 1);
    this.fileData.splice(i, 1);
  }

  getimg(event): void {
    if (event.length === 0) {
      return;
    }
    this.picCount = [];
    this.fileData = [];
    const data = event.target.files[0];
    this.fileData.push(data);
    this.imgSelected = true;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      this.picCount.push(this.imgURL);
    };
  }

  likePost(item): void {
    item["enableLikes"] = true;

    // const postdata = this.getLikeStatus(item);

    // const data = {
    //   postId: item._id,
    //   likedBy: config.userId,
    //   postOf: item.userId,
    // };
    this.api.likePost(item._id).then((res) => {
      //item.likesCount = res.likesCount;
      if (res.likesCount < 0) {
        item.likesCount = 0;
      } else {
        item.likesCount = res.likesCount;
      }

      if (res.msg == "Post Liked") {
        item.yourLikeDetails = [{ is_liked: true }];
        this.toastr.success("Post Liked");
      } else {
        item.yourLikeDetails = [{ is_liked: false }];
        this.toastr.error(res.msg, " ", {
          timeOut: 1000,
        });
      }

      setTimeout(function () {
        item["enableLikes"] = false;
      }, 2000);
    });
  }

  getLikeStatus(post): any {
    const data = {
      status: false,
      index: undefined,
    };
    post.likedBy.filter((item, i) => {
      if (item.likedBy === this.userId) {
        data.status = true;
      }
    });
    return data;
  }

  // for connection filter
  getFriends(response) {
    this.friends = [];
    response.connections.filter((item) => {
      item.mutuals.filter((mutualsdata) => {
        if (
          mutualsdata.userName &&
          mutualsdata.userFirstName &&
          mutualsdata.userLastName
        ) {
          this.friends.push(mutualsdata);
        }
      });

      if (item.sender === this.userId) {
        item.inviteeDetail.filter((invitee) => {
          if (
            invitee.userName &&
            invitee.userFirstName &&
            invitee.userLastName
          ) {
            this.friends.push(invitee);
          }
        });
      }
      if (item.invitee === this.userId) {
        item.senderDetail.filter((sender) => {
          if (sender.userName && sender.userFirstName && sender.userLastName) {
            this.friends.push(sender);
          }
        });
      }
    });
    this.searchfriends = this.friends;
  }

  mentionOnComment(data, type, post) {
    if (
      this.menTionedFriend.postId &&
      this.menTionedFriend.postId === post._id &&
      this.menTionedFriend.value.length > 0
    ) {
      let match = false;
      this.menTionedFriend.value.filter((mention) => {
        //if (mention._id === data._id) {
        if (mention.user_id === data.user_id) {
          match = true;
        }
      });
      if (!match) {
        this.menTionedFriend.value.push(data);
      }
    } else {
      this.menTionedFriend = {
        postId: "",
        value: [],
      };
      this.menTionedFriend.postId = post._id;
      this.menTionedFriend.value.push(data);
    }
  }

  removeUserFromComment(index, post) {
    if (this.menTionedFriend.postId === post._id) {
      this.menTionedFriend.value.splice(index, 1);
    }
  }

  updateLikeData(data, type): void {
    if (this.timeline.length) {
      this.timeline.filter((post) => {
        if (post._id === data._id) {
          if (type.toLowerCase() === "like") {
            post.likes = data.likes;
            post.likedBy = data.likedBy;
            this.getPostLikedOrNot();
          }
          if (type.toLowerCase() === "comment") {
            post.commentsCount = data.commentsCount;
            post.comments = data.comments;
          }
          if (type.toLowerCase() === "share") {
            post.shareCount = data.shareCount;
            post.sharePost = data.sharePost;
          }
        }
      });
    }
    // }
  }

  sharePost(item): void {
    const sharedTo = [];
    this.getAllConnectionList.filter((item) => {
      if (item._id) {
        sharedTo.push(item._id);
      }
    });
    const data = {
      postId: item._id,
      sharedBy: this.userId,
      sharedTo: sharedTo,
    };

    this.api.sharePost(item._id).then((res) => {
      if (res && res.msg) {
        if (res.msg == "Post Shared") {
          this.toastr.success("Post Shared");
          item.sharesCount += 1;
        } else {
          this.toastr.error(res.msg, "Error", {
            timeOut: 3000,
          });
        }
      }
    });
  }

  commentOnPost(value, item): void {
    let tempTags = [];
    if (
      this.menTionedFriend &&
      this.menTionedFriend.postId &&
      this.menTionedFriend.value &&
      this.menTionedFriend.value.length &&
      item._id === this.menTionedFriend.postId
    ) {
      this.menTionedFriend.value.filter((item) => {
        tempTags.push(item.user_id);
      });
    }
    const data = {
      postId: item._id,
      commentedBy: this.userId,
      comment: value,
      tags: tempTags,
    };

    const newData = {
      text: value,
      userTags: tempTags,
    };

    this.api.commentOnPostNew(newData, item._id).then((res) => {
      if (res.success) {
        item["commentsCount"] += 1;

        this.comment && this.comment.nativeElement.value
          ? (this.comment.nativeElement.value = "")
          : null;
        this.menTionedFriend.postId = "";
        this.menTionedFriend.value = [];
      }
    });
  }

  deletePostComment(comment, $event, post, i) {
    this.api.deletePostComment(comment._id, comment.postID).then((res) => {
      this.getPrevious(post.latestComment, post);
    });
  }

  loadMorePost() {
    this.timelinePage += 1;
    this.timelinePageLoading = true;
    this.getPostData(this.userId);
  }

  getPrevious(latestComment, post) {
    if (post) {
      // && latestComment.length
      this.api.getPreviousComments(post, post._id).then((res) => {
        if (res.success) {
          if (res.data.length) {
            post.commentsCount = res.data.length;
            post.latestComment = res.data;
          }
        } else {
          post.commentsCount = 0;
          post.latestComment = [];
        }
      });
    }
  }

  getTimeLinePost(data) {
    this.timelinePage = 0;
    this.getPostData(data[0]._id);
  }

  deletePost(postId) {
    const data = {
      postId: postId,
    };

    this.api.deletePost(postId).then((res) => {
      if (res && res.success) {
        this.toastr.success("Post Deleted");
        this.backToTimeLine();
      } else {
        this.toastr.error("Failed to delete post.", "Error", {
          timeOut: 3000,
        });
      }
    });
  }

  getMessageByTag(data, type) {
    if (type === "entity") {
      this.router.navigate(["./entity-profile/" + data._id + "/entity"]);
    } else if (type === "process") {
      this.router.navigate(["./botProfile/" + data._id + "/bot"]);
    } else if (type === "users") {
      this.router.navigate(["./users-details/" + data._id + "/profile"]);
    }
  }
  getLikedUsers(post) {
    this.api.getLikedUsersList(post._id).then((res) => {
      this.likedUserList = res.data;
    });
  }
  backToTimeLine(): void {
    this.timelinePage = 0;
    this.timeline = [];
    this.getingSinglePost = false;
    this.getAllPostDataStatus = false;
    this.getPostData(this.userId);
  }
  scrollHandler(event) {
    if (this.byNotificationClick) {
      return false;
    }
    if (this.dataComplate) {
      return false;
    }
    if (
      event.srcElement.clientHeight + event.srcElement.scrollTop ===
        event.srcElement.scrollHeight &&
      !this.timelinePageLoading &&
      !this.noMorePost &&
      !this.getTimelineStatus
    ) {
      this.loadMorePost();
    }
  }

  searchUser(event, text, type) {
    if (text) {
      this.searchfriends = this.friends.filter(
        (item) =>
          item.userFirstName.toLowerCase().indexOf(text.toLowerCase()) !== -1
      );
    } else {
      this.searchfriends = this.friends;
    }
    if (type === "close") {
      event.stopPropagation();
    }
  }
}
