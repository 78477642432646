import { Component, ViewEncapsulation } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { Navigate } from "@ngxs/router-plugin";
import { FuseConfigService } from "@fuse/services/config.service";
import { Actions, Store } from "@ngxs/store";
import { SetUser } from "../../../store/user/user.actions";
import { LoginFortmatic } from "app/store/login/login.actions";
import { fuseAnimations } from "../../../../@fuse/animations/index";
import { ApiService } from "../../../services/api.service";
import { MatDialog, } from "@angular/material";
import Fortmatic from "fortmatic";
import { environment } from "../../../../environments/environment";
import NavigatePages from "../../../utils/navigatePages";
import { AccessCodeComponent } from "./access-code/access-code.component";
import { ToastrService } from "ngx-toastr";
import { CookieService } from "ngx-cookie-service";
import { VARIABLE_CONSTANT } from "app/utils/constant";

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class LoginComponent {
  //    @param {FuseTranslationLoaderService} _fuseTranslationLoaderService

  config: any;
  loginForm: any;
  _formBuilder: FormBuilder;
  accessCodeVerified: boolean = false;
  public connectionLoader: Boolean = false;
  constructor(
    private api: ApiService,
    private _fuseConfigService: FuseConfigService,
    public actions: Actions,
    private router: Router,
    public store: Store,
    public dialog: MatDialog,    
    private toastr: ToastrService,
    private cookieService: CookieService
  ) {
    //Fully customizable surroundings for this particular component

    this._fuseConfigService.config = {
      layout: {
        style: "vertical-layout-1",
        width: "fullwidth",
        navbar: {
          primaryBackground: "fuse-navy-700",
          secondaryBackground: "fuse-navy-900",
          folded: false,
          hidden: true,
          position: "left",
          variant: "vertical-style-1",
        },
        toolbar: {
          customBackgroundColor: false,
          background: "fuse-white-500",
          hidden: true,
          position: "below-static",
        },

        sidepanel: {
          hidden: true,
          position: "right",
        },
      },
      customScrollbars: true,
    };
  }

  ngOnInit(): void {
    this.store
      .select((s) => s.user.user)
      .subscribe((u) => {
        if (u != "undefined" && u != null && u != "") {
          this.store.dispatch(new Navigate(["/profile"]));
        } else {
          this.store.dispatch(new Navigate(["/login"]));

          const token = this.cookieService.get("token");
          if (token) {
            this.loginWithCookies();
          } else {
            if (this.accessCodeVerified) {
            } else {
              this.accessCodeLogin();
            }
          }
        }
      });
    //navigatePages.routePage("/" + APP_ROUTES.PROFILE)
  }

  onInit(): void {
    // Subscribe to config change

    this._fuseConfigService.config.subscribe((config) => {
      this.config = config;
    });
  }

 

  loginWithCookies(): void {
    const token = this.cookieService.get("token");
    const userId = this.cookieService.get("userId");
    const login: any = {
      isLoggedIn: true,
      token: this.cookieService.get("token"),
    };
    const user: any = {
      otp: null,
      user: JSON.parse(this.cookieService.get("user")),
    };

    localStorage.setItem("token", token);
    localStorage.setItem("userId", userId);
    environment.userId = userId;
    localStorage.setItem("login", JSON.stringify(login));
    localStorage.setItem("user", JSON.stringify(user));
    this.cookieService.set('user', JSON.stringify(user));

  }

  accessCodeLogin(): void {
    const dialogRef = this.dialog.open(AccessCodeComponent, {
      width: "550px",
      data: {
        ReturnData: "",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.success) {
        this.accessCodeVerified = true;
        this.loginWithFortmatic();
      } else {
        this.accessCodeVerified = false;
        this.toastr.error(result.msg);
      }

      //
    });
  }

  showLoader(data): void {
    if (data === "all") {
      this.connectionLoader = true;
    }
  }
  hideLoader(data): void {
    if (data === "all") {
      this.connectionLoader = false;
    }
  }

  loginWithFortmatic(): void {
    
    const fm = new Fortmatic("pk_live_DF44F7660C691718"); //pk_test_5751EB76847A1B12

    this.showLoader("all");
    fm.getProvider().isFortmatic;
    //const web3 = new Web3(fm.getProvider());
    let isUserLoggedIn = fm.user.isLoggedIn();
    //web3.currentProvider.isFortmatic

    fm.configure({ primaryLoginOption: "email" }).then((res) => {
      this.hideLoader("all");
      let accessToken = "";
      let LoggedIn = false;
      fm.user.login().then((token) => {
        if (token != "undefined" || token != "") {
          let userData = fm.user
            .getUser()
            .then(async (userdata) => {
              let fData = {
                token: "",
                isLoggedIn: LoggedIn,
                fortmatic_id: userdata.userId,
                userEmailID: userdata.email,
              };

              await this.api.loginFortmatic(fData).then(async (res) => {
                if (res && res.data.user) {
                  await localStorage.setItem("userId", res.data.user._id);
                  await localStorage.setItem("token", res.data.token);

                  this.cookieService.set(
                    "userId",
                    res.data.user._id,
                    VARIABLE_CONSTANT.expiryDate,
                    VARIABLE_CONSTANT.defaultPath,
                    VARIABLE_CONSTANT.DOMAIN
                  );
                  this.cookieService.set(
                    "token",
                    res.data.token,
                    VARIABLE_CONSTANT.expiryDate,
                    VARIABLE_CONSTANT.defaultPath,
                    VARIABLE_CONSTANT.DOMAIN
                  );

                  this.cookieService.set(
                    "user",
                    JSON.stringify(res.data.user),
                    VARIABLE_CONSTANT.expiryDate,
                    VARIABLE_CONSTANT.defaultPath,
                    VARIABLE_CONSTANT.DOMAIN
                  );

                  this.cookieService.set(
                    "userKYC",
                    res.data.user.userKYCFlag,
                    VARIABLE_CONSTANT.expiryDate,
                    VARIABLE_CONSTANT.defaultPath,
                    VARIABLE_CONSTANT.DOMAIN
                  );
                  if (res.data.user.userKYCFlag == undefined) {
                    this.cookieService.set(
                      "userKYC",
                      "false",
                      VARIABLE_CONSTANT.expiryDate,
                      VARIABLE_CONSTANT.defaultPath,
                      VARIABLE_CONSTANT.DOMAIN
                    );
                  }

                  environment.userId = res.data.user._id;
                  fData.token = res.data.token;
                  //if (res.data.user.isNewUser) {
                  if (!res.data.user.completeProfileFlag) {
                    fData.isLoggedIn = false;

                    await this.store.dispatch(new SetUser(res.data.user));
                  } else {
                    fData.isLoggedIn = true; //res.data.user.userKYCFlag;

                    await this.store.dispatch(new SetUser(res.data.user));
                  }
                }
              });
              this.store.dispatch(new LoginFortmatic(fData));
            })
            .catch((e) => {});
        }
      });
    });
  }
}
