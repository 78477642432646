import { CookieService } from "ngx-cookie-service";
import {
  Component,
  OnDestroy,  
  OnInit,
  ViewEncapsulation,  
  HostListener,
  ElementRef,
  ViewChild,
  AfterViewInit,  
  ChangeDetectorRef
} from "@angular/core";
import { Subject,  } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  
} from "@angular/forms";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { MatDialog } from "@angular/material";
import { navigation } from "app/navigation/navigation";
import { Store } from "@ngxs/store";
import {  Router } from "@angular/router";
import { ApiService } from "app/services/api.service";
import { Location } from "@angular/common";
import Fortmatic from "fortmatic";
import { environment } from "../../../../environments/environment";
import { SocketServiceService } from "app/services/socket-service.service";
import { DomSanitizer } from "@angular/platform-browser";
import { Navigate } from "@ngxs/router-plugin";
import { APP_ROUTES } from "app/main/shared-module/constants/AppRoutes";
import { ConnectionService } from "ng-connection-service";
import { DiagnosticInfoComponent } from "../diagnostic-info/diagnostic-info.component";
import { io } from "socket.io-client";
import { MatMenuTrigger } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { VARIABLE_CONSTANT } from "app/utils/constant";

@HostListener("window:scroll", ["$event"])
@Component({
  selector: "toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent implements OnInit, AfterViewInit, OnDestroy {
  // @ViewChild("botListOpen") matMenuTrigger: MatMenuTrigger;
  @ViewChild("menuBtn1", { read: MatMenuTrigger }) botListOpen: MatMenuTrigger;

  collapse: Boolean = false;
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  user: any;
  allNotification: any[];
  profilePhotoUrl: any;
  public chatnotificationCount: Number = 0;
  public notification: any[] = [];
  public kafkaToken = this.cookieService.get("token");
  public socketMenu;
  public socketMenuConversationId;
  public kafkaSocketMenu;
  public performData: any = {};
  public userSaysArea: boolean = false;
  public menuBotForm: FormGroup;
  public connectedHostDetails: any = {};
  // Private
  private _unsubscribeAll: Subject<any>;
  public userDetails: any;
  public defaultProfile = "assets/images/avatars/no-photo.png";
  public defaultAvatarUrl: string = "assets/images/avatars/botDavatar.png";
  public notificationCount: Number = 0;
  public notificationCountInterval: Number = 0;
  public loadMoreNotificationOffsets = 0;
  public endNotification = true;
  public ids: any;
  public notificationIconClick = true;
  public isConnected = true;
  public noInternetConnection: boolean;
  public ownerBotList: any = [];
  public open: boolean = true;
  public socketConnection: boolean = false;
  throttle = 300;
  scrollDistance = 2;
  scrollUpDistance = 50;
  direction = "";
  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private socketService: SocketServiceService,
    private store: Store,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private cookieService: CookieService,
    private connectionService: ConnectionService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private ref: ChangeDetectorRef
  ) {
    this.getNotificationCount();
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.userStatusOptions = [
      {
        title: "Online",
        icon: "icon-checkbox-marked-circle",
        color: "#4CAF50",
      },
      {
        title: "Away",
        icon: "icon-clock",
        color: "#FFC107",
      },
      {
        title: "Do not Disturb",
        icon: "icon-minus-circle",
        color: "#F44336",
      },
      {
        title: "Invisible",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#BDBDBD",
      },
      {
        title: "Offline",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#616161",
      },
    ];
    this.languages = [
      {
        id: "en",
        title: "English",
        flag: "us",
      },
      {
        id: "tr",
        title: "Turkish",
        flag: "tr",
      },
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // this.addBot.botDetailsEmitter.subscribe((data) => {

    // })
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get Notification Count
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === "top";
        this.rightNavbar = settings.layout.navbar.position === "right";
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });
    // Set the selected language from default languages
    this.notificationCount = this.socketService.notificationCount;

    this.api.totalUnreadMessageCount.subscribe((res) => {
      this.chatnotificationCount = res;
    });

    this.selectedLanguage = _.find(this.languages, {
      id: this._translateService.currentLang,
    });



    this.connectionService.monitor().subscribe((res) => {
      this.isConnected = res;
      if (this.isConnected) {
        this.noInternetConnection = false;
      } else {
        this.noInternetConnection = true;
      }
    });
    this.menuBotForm = this.fb.group({
      tryItNow: new FormControl("", Validators.required),
    });
    this.getOwnerBot();
    this.socketService.botDetailsData.subscribe((data) => {
      let item = {
        name: data.name,
        avatar: data.avatar,
        hostIP: data.hostIP,
        _id: data._id,
      };
      if (
        data.hostIP != "" &&
        data.hostIP != null &&
        data.hostIP != undefined
      ) {
        this.createConversation(item);
        // this.open = !this.open;
        this.open = false;
      }
    });

    // call profile Picture function from api
    this.api.profileImgFunction.subscribe((name: string) => {
      this.getProImg();
    });
    this.getProImg();
    // end profile Picture function from api
  }
  ngAfterViewInit() {
    // if (this.route.snapshot.queryParamMap.get("id") !== null) {
    //   this.botListOpen.openMenu(); // also closeMenu()
    // } else {
    //   this.botListOpen.closeMenu();
    // }
    // this.botListOpen.openMenu();
    //this.botListOpen.openMenu();
  }
  getProImg() {
    this.store
      .select((s) => s.user.user)
      .subscribe(async (user) => {
        this.user = user;

        if (this.user && this.user.profilePicture) {
          this.profilePhotoUrl = this.user.profilePicture;
        }
      });

  }
  stopPropagation1() {
    this.open = !this.open
  }
  getOwnerBot() {
    this.api.getOwnerBot().then((data) => {
      if (data.success) {
        this.ownerBotList = data.data;
      }
    });
  }
  deleteConversation(conversationId) {
    this.kafkaSocketMenu.emit(
      "deleteConversation",
      JSON.stringify({
        token: this.kafkaToken,
        conversationId: conversationId,
      })
    );
  }
  async createConversation(item: any) {
    this.socketConnection = false;
    this.performData = {};
    this.userSaysArea = false;

    this.menuBotForm.patchValue({
      tryItNow: "",
    });

    this.botListOpen.openMenu();
    if (this.socketMenuConversationId) {
      this.deleteConversation(this.socketMenuConversationId);
    }
    this.open = false; //!this.open;
    this.connectedHostDetails = item;

    // this.socketMenu = io("http://localhost:5005");
    // this.socketMenu = io("http://bot.hopabot.com/631ade819e2ea33cb7d24cae/");
    // this.socketMenu = io(this.connectedHostDetails.hostIP);

    let myArray = this.connectedHostDetails.hostIP.split("/");
    if (
      item.hostIP != "" &&
      item.hostIP != null &&
      item.hostIP != undefined &&
      myArray[3] != undefined &&
      myArray[3] != null &&
      myArray[3] != ""
    ) {
      //var r_path = "/" + item._id + "/socket.io";

      var r_path = "/" + myArray[3] + "/socket.io";
      // this.socketMenu = io("https://bot.hopabot.com/", { path: r_path });
      this.socketMenu = io(myArray[0] + "//" + myArray[2] + "/", { path: r_path });

      //this.socketConnection = true
      setTimeout(() => {
        this.socketConnection = this.socketMenu.connected;

        //this.ref.markForCheck();
      }, 10000);

      this.socketMenuConversationId = "test_" + this.generateUUID();
      //this.socketMenuConversationId = "test_4544560de06a7c8017fb20b42ec0c";

      this.socketMenu.emit("session_request", {
        session_id: this.socketMenuConversationId,
      });
      this.socketMenu.on("session_confirm", (data) => {
        this.socketMenu.on(data.topic, (data) => {});
        this.socketMenu.emit(data.topic + "-userbot", {
          message: "hello human agent",
        });
      });
      this.kafkaSocketMenu = io("https://kafka.hopabot.com", {
        query: {
          token: this.kafkaToken,
        },
      });
      this.kafkaSocketMenu.emit(
        "getConversationsHistory",
        JSON.stringify({
          token: this.kafkaToken,
          conversationId: this.socketMenuConversationId,
          index: 0,
          size: 10,
        })
      );
      this.kafkaSocketMenu
        .off("getConversationsHistory")
        .on("getConversationsHistory", (data) => {});

      let live_botId_userId_generateId = "live_" + item._id + "_" + (this.cookieService.get('userId') || environment.userId) + "_" + this.socketMenuConversationId;

      

      this.kafkaSocketMenu
        .off(          
          live_botId_userId_generateId
        )
        .on(
          
          live_botId_userId_generateId,
          (data) => {
            var data = JSON.parse(data);
            if (data.type == "user") {
              this.performData = Object.assign(this.performData, {
                userMessage: data.message,
              });
            }
            if (data.type == "user") {
              this.performData = Object.assign(this.performData, {
                intentName:
                  data &&
                  data.parse_data &&
                  data.parse_data.intent &&
                  data.parse_data.intent.name
                    ? data.parse_data.intent.name
                    : "Default Welcome Intent",
              });
            }

            if (data.type == "user") {
              this.performData = Object.assign(this.performData, {
                diagnostic_info: data,
              });
            }

            if (data.type == "user") {
              this.performData = Object.assign(this.performData, {
                entities:
                  data && data.parse_data && data.parse_data.entities
                    ? data.parse_data.entities
                    : [],
              });
            }

            if (data.type == "bot") {
              this.performData = Object.assign(this.performData, {
                botMessage: data.message,
              });
            }
          }
        );
    }
  }

  generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0; //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
  }

  sendMessagePerform(event) {
    if (event.keyCode == 13 && event.target.value != "") {
      this.socketMenu.emit("user_uttered", {
        session_id: this.socketMenuConversationId,
        message: event.target.value,
      });
      this.userSaysArea = true;
      this.menuBotForm.patchValue({
        tryItNow: "",
      });
    }
  }
  openDialog(data) {
    const dialogRef = this.dialog.open(DiagnosticInfoComponent, {
      width: "850px",
      height: "550px",
      data: data ? data : [],
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }
  onScroll() {}
  onScrollDown() {
    this.direction = "down";
  }
  onUp(ev) {
    this.direction = "up";
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
  /**
   * Search
   *
   * @param event
   */
  search(event): void {
    // Do your search here...
    if (event.keyCode === 13) {
      this.api.searchInputData(event.srcElement.value);
      if (this.router.url === "/searchbarfilter") {
        this.router.navigate(["/search"]).then(() => {
          this.router.navigateByUrl("/searchbarfilter");
        });
      } else {
        this.router.navigateByUrl("/searchbarfilter");
      }
    }
    if (this.collapse) {
      this.collapse = false;
    } else {
      this.collapse = true;
    }
    event.srcElement.value = null;
  }
  setLanguage(lang): void {
    this.selectedLanguage = lang;
    this._translateService.use(lang.id);
  }
  getProfileData(): void {
    let userId = this.cookieService.get('userId') || environment.userId;
    if (userId) {
      this.api.getProfileData(userId).then((res) => {
        this.userDetails = res ? res[0] : null;
      });
    }
  }
  getNotificationCount(): void {
    this.socketService.notification.subscribe((data) => {
      if (typeof data === "number") {
        this.notificationCount = data;
      }
    });
  }

  getAllNotificationCountInterval() {
    let userId = this.cookieService.get('userId') || environment.userId;
    if (userId != null) {
      this.api
        .getAllNotificationCount("")
        .then((data) => {
          this.notificationCount = data.count;
        })
        .catch((err) => {
          this.isConnected = false;
        });
    }
  }

  myscrollHandler = (event): void => {
    this.getNotificationData("", event);
  };

  getNotificationData(offset, event?): void {
    if (offset == "icon-click") {
      this.endNotification = true;
      this.loadMoreNotificationOffsets = 0;
    }

    let userId = this.cookieService.get('userId') || environment.userId;

    this.api
      .getAllNotification(userId, this.loadMoreNotificationOffsets)
      .then((res) => {
        if (res.success) {
          var read_items = new Array();
          for (var i = 0; i < res.data.length; i++) {
            if (!res.data[i].is_read) {
              read_items.push(res.data[i]._id);
            }
          }
          let data = { notificationIDs: read_items };
          if (read_items.length > 0) {
            this.api.readNotification(data).then((res) => {
              this.getNotificationCount();
            });
          }
        }

        if (!res.success) {
          this.endNotification = false;
        }
        if (this.loadMoreNotificationOffsets === 0) {
          this.allNotification = res.data;
        } else {
          this.allNotification = this.allNotification.concat(res.data);
        }
        this.notificationCount = 0;
        this.loadMoreNotificationOffsets =
          this.loadMoreNotificationOffsets + 10;
      });
  }

  deleteNotification(item, index, event): void {
    this.api.deleteNotification(item._id).then((res) => {
      this.allNotification.splice(index, 1);
    });
    event.stopPropagation();
  }

  getParticularTimelineData(item): void {
    if (!this.router.url.includes("/timeline")) {
      
      this.api.getParticularPost(item, false);
    } else {
      this.api.getParticularPost(item, true);
    }
  }

  redirectToProfile(): void {
    this.router.navigate(["/" + APP_ROUTES.PROFILE]);
  }
 
  logoutUser(): void {
    this.cookieService.delete("token", "");
    this.cookieService.delete("userId", "");
    this.cookieService.delete("user", "");
    this.cookieService.delete("userKYC", "");
    this.cookieService.delete("email", "");
    this.cookieService.set(
      "token",
      "",
      VARIABLE_CONSTANT.expiryDate,
      VARIABLE_CONSTANT.defaultPath,
      VARIABLE_CONSTANT.DOMAIN
    );
    this.cookieService.set(
      "userId",
      "",
      VARIABLE_CONSTANT.expiryDate,
      VARIABLE_CONSTANT.defaultPath,
      VARIABLE_CONSTANT.DOMAIN
    );
    this.cookieService.set(
      "user",
      "",
      VARIABLE_CONSTANT.expiryDate,
      VARIABLE_CONSTANT.defaultPath,
      VARIABLE_CONSTANT.DOMAIN
    );
    this.cookieService.set(
      "userKYC",
      "",
      VARIABLE_CONSTANT.expiryDate,
      VARIABLE_CONSTANT.defaultPath,
      VARIABLE_CONSTANT.DOMAIN
    );
    this.cookieService.set(
      "email",
      "",
      VARIABLE_CONSTANT.expiryDate,
      VARIABLE_CONSTANT.defaultPath,
      VARIABLE_CONSTANT.DOMAIN
    );

    const fm = new Fortmatic("pk_live_DF44F7660C691718"); //pk_test_5751EB76847A1B12
    fm.user.logout();

    this.store.reset({});
    var testObject = {
      otp: null,
      user: {},
    };
    var loginObject = {
      isLoggedIn: false,
      token: "",
    };
    this.api.logout().then((res) => {})
    //this.router.navigate(["/" + APP_ROUTES.LOGIN]);
    this.store.dispatch(new Navigate(["/login"]));
  }

  getMessageByTag(data, type) {
    if (type === "entity") {
    } else if (type === "process") {
    } else if (type === "users") {
      
    } else if (type === "NotificationUsers") {
      this.router.navigate(["/users-details/" + data.fromUser + "/profile"]);
    } else if (type === "timeline") {
      this.router.navigate(["/timeline/single-post/" + data.postID]);
    } else if (type === "AssociationRequest") {
      this.router.navigate(["/manage-entities/entityValidationRequests/"], {
        queryParams: { type: "AssociationRequest", id: data.entityRequestID },
      });
    } else if (type === "BotAssociationRequest") {
      this.router.navigate(["/manage-entities/entityValidationRequests/"], {
        queryParams: { type: "BotAssociationRequest", id: data.botRequestID },
      });
    } else if (type === "Marketplace") {
      this.router.navigate(["/marketplace"], {
        queryParams: {
          type: "marketplaceRequestID",
          id: data.marketplaceRequestID,
        },
      });
    }
  }

  menuFocusClose(key: any) {
    if (key.keyCode === 27) {
      this.botListOpen.closeMenu();
    }
  }
}
