import {
  GetAllBots,
  GetAllBotsFailed,
  GetAllBotsSuccess,
  GetAvatars,
  GetAvatarsFailed,
  GetAvatarsSuccess,
  OwnerBots,
  OwnerBotsSuccess,
  OwnerBotsFailed,
  GetAllDelegateBots,
  GetDelegateBotsDropdown,
  GetAllTraining,
  GetAllIntegrationsBots,
} from "./bot.action";
import { BotService } from "./bot.service";
import { State, Selector, Action, StateContext } from "@ngxs/store";
import {  catchError } from "rxjs/operators";
import {  throwError } from "rxjs";
import { ErrorHandleService } from "app/services/error.handle.service";

interface BotStateModel {
  allBots: any[];
  delegateBotsList: any[];
  integrationsBotsList: any[];
  trainingList: any[];
  getDelegateBotsDropdown: any[];
  avatars: any[];
  ownerBots: any;
}

@State<BotStateModel>({
  name: "BotState",
  defaults: {
    allBots: [],
    delegateBotsList: [],
    integrationsBotsList: [],
    getDelegateBotsDropdown: [],
    trainingList: [],
    avatars: [],
    ownerBots: null,
  },
})
export class BotState {
  constructor(
    private botService: BotService,    
    private he: ErrorHandleService
  ) {}

  @Selector()
  static getAllBots(state: BotStateModel): any {
    return state.allBots;
  }

  @Selector()
  static getAllDelegateBots(state: BotStateModel): any {
    return state.delegateBotsList;
  }

  @Selector()
  static getDelegateBotsDropdown(state: BotStateModel): any {
    return state.getDelegateBotsDropdown;
  }

  @Selector()
  static getAllTraining(state: BotStateModel): any {
    return state.trainingList;
  }

  @Selector()
  static getAllIntegrationsBots(state: BotStateModel): any {
    return state.integrationsBotsList;
  }

  @Selector()
  static getAvatars(state: BotStateModel): any {
    return state.avatars;
  }

  @Selector()
  static OwnerBots(state: BotStateModel): any {
    return state.ownerBots;
  }

  @Action(GetAllBots)
  getAllBots(
    ctx: StateContext<Partial<BotStateModel>>,
    action: GetAllBots
  ): any {
    return this.botService
      .getAllBots1()
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.dispatch(new GetAllBotsSuccess(res.data));
        } else {
          ctx.dispatch(new GetAllBotsFailed(res.msg));
        }
      });
  }

  @Action(GetAllBotsSuccess)
  getAllBotsSuccess(
    ctx: StateContext<Partial<BotStateModel>>,
    action: GetAllBotsSuccess
  ): void {
    ctx.patchState({
      allBots: action.response,
    });
  }

  @Action(GetAllBotsFailed)
  getAllBotsFailed(ctx: StateContext<Partial<BotStateModel>>): void {
    ctx.patchState({
      allBots: [],
    });
  }

  @Action(GetAllTraining)
  getAllTraining(
    ctx: StateContext<Partial<BotStateModel>>,
    action: GetAllTraining
  ): any {
    return this.botService
      .getAllTraining(action.response)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            trainingList: res.data,
          });
        } else {
          ctx.patchState({
            trainingList: [],
          });
        }
      });
  }

  @Action(GetAllDelegateBots)
  getAllDelegateBots(
    ctx: StateContext<Partial<BotStateModel>>,
    action: GetAllDelegateBots
  ): any {
    return this.botService
      .getAllDelegateBots(action.response)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            delegateBotsList: res.data,
          });
        } else {
          ctx.patchState({
            delegateBotsList: [],
          });
        }
      });
  }

  @Action(GetDelegateBotsDropdown)
  getDelegateBotsDropdown(
    ctx: StateContext<Partial<BotStateModel>>,
    action: GetDelegateBotsDropdown
  ): any {
    return this.botService
      .getDelegateBotsDropdown(action.response)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            getDelegateBotsDropdown: res.data,
          });
        } else {
          ctx.patchState({
            getDelegateBotsDropdown: [],
          });
        }
      });
  }

  @Action(GetAllIntegrationsBots)
  getAllIntegrationsBots(
    ctx: StateContext<Partial<BotStateModel>>,
    action: GetAllIntegrationsBots
  ): any {
    return this.botService
      .getAllIntegrationsBots(action.response)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((res) => {
        if (res.success) {
          ctx.patchState({
            integrationsBotsList: res.data,
          });
        } else {
          ctx.patchState({
            integrationsBotsList: [],
          });
        }
      });
  }

  @Action(GetAvatars)
  getAvatars(ctx: StateContext<Partial<BotStateModel>>): any {    
  }

  @Action(GetAvatarsSuccess)
  getAvatarsSuccess(
    ctx: StateContext<Partial<BotStateModel>>,
    action: GetAvatarsSuccess
  ): void {
    ctx.patchState({
      avatars: action.response,
    });
  }

  @Action(GetAvatarsFailed)
  getAvatarsFailed(ctx: StateContext<Partial<BotStateModel>>): void {
    ctx.patchState({
      avatars: [],
    });
  }

  @Action(OwnerBots)
  async OwnerBots(
    { getState, patchState }: StateContext<BotStateModel>,
    { payload }: any
  ) {
    var get_data = getState();
    if (get_data.ownerBots == null) {
    } else {
      return get_data;
    }
  }

  @Action(OwnerBotsSuccess)
  OwnerBotsSuccess(
    ctx: StateContext<Partial<BotStateModel>>,
    action: OwnerBotsSuccess
  ): void {
    ctx.patchState({
      ownerBots: action.response,
    });
  }

  @Action(OwnerBotsFailed)
  OwnerBotsFailed(ctx: StateContext<Partial<BotStateModel>>): void {
    ctx.patchState({
      ownerBots: null,
    });
  }
}
