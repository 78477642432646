import { environment } from "../../../environments/environment";
const SERVER_URL = environment.serverUrl;

export const updateProfilePhoto = SERVER_URL + "/user/upload-profile-photo"; //1
export const updateProfileUrl = SERVER_URL + "/user/profile/"; //2

export const updateKycPhoto = "/user/upload-consent-photo";
export const updateConsentPhoto = "/user/upload-kyc-doc";

export const requestOTPAPI = SERVER_URL + "/user/otp/request";

export function completeProfileDetails(): string {
  // work
  return "/user-auth/completeProfileDetails";
}
export function updateProfileDetails(): string {
  //work
  return "/user-auth/updateProfileDetails";
}

export function getFileById(id: string): string {
  return "/file/" + id;
}

export function getImageByID(id: string): string {
  return "/file/image/" + id;
}

export function requestOTP(): string {
  return "/user/otp/request";
}

export function verifyOTPRequest(): string {
  return "/user/otp/verify";
}
export function verifyOTPCancelRequest(): string {
  return "/user/otp/cancel";
}
