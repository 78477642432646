import { Login, FortmaticLogin } from "./login.model";

export class LoginFortmatic {
  static readonly type = "[FortmaticLogin] Formatic";

  constructor(public payload: any) {}
}

export class Logout {
  static readonly type = "[Logout]";

  constructor() {}
}
export class SetAccessToken {
  static readonly type = "[SetAccessToken]";

  constructor(public payload: any) {}
}
