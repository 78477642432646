// user.action.ts

import { State, Action, StateContext, Selector, Store } from "@ngxs/store";
import { User } from "./user.model";
import {
  GetOTP,
  SetUser,
  UpdateProfileUser,
  VerifyOTP,
  VerifyOTPCancel,
  CompleteProfileUser,
  SetOtp,
} from "./user.actions";
import {
  requestOTP,
  updateProfileDetails,
  verifyOTPRequest,
  verifyOTPCancelRequest,
  completeProfileDetails,
} from "./user.api";

import { Navigate } from "@ngxs/router-plugin";
import { ToastrService } from "ngx-toastr";
import { SharedApiService } from "app/main/shared-module/services/shared.api.service";
import { APP_ROUTES } from "app/main/shared-module/constants/AppRoutes";
import {  catchError } from "rxjs/operators";
import {  throwError } from "rxjs";
import { ErrorHandleService } from "app/services/error.handle.service";

export class UserStateModel {
  user: User;
  otp: {
    reqID: string;
  };
}

@State<UserStateModel>({
  name: "user",
  defaults: {
    user: null,
    otp: null,
  },
})
export class UserState {
  constructor(
    private api: SharedApiService,
    private store: Store,
    private toastr: ToastrService,
    private he: ErrorHandleService
  ) {}

  @Selector()
  static getUser(state: UserStateModel) {
    return state.user;
  }

  @Action(SetUser)
  setUser(
    { getState, patchState }: StateContext<UserStateModel>,
    { payload }: any
  ): void {
    patchState({
      user: payload,
    });
  }
  @Action(SetOtp)
  setOtp(
    { getState, patchState }: StateContext<UserStateModel>,
    { payload }: any
  ): void {
    patchState({
      otp: payload,
    });
  }

  @Action(UpdateProfileUser)
  updateProfileUser(
    { getState, patchState }: StateContext<UserStateModel>,
    { payload }: any,
    isNewUser: boolean
  ): void {
    this.api
      .post(updateProfileDetails(), payload.user)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((data: any) => {
        if (data.user.success) {
          this.toastr.success("Profile updated");
          this.store
            .select((s) => s.user.user)
            .subscribe((u) => {
              u.userFirstName =
                payload.user.userFirstName === undefined
                  ? u.userFirstName
                  : u.userFirstName;
              u.userLastName =
                payload.user.userLastName === undefined
                  ? u.userLastName
                  : u.userLastName;
              u.userGender =
                payload.user.userGender === undefined
                  ? u.userGender
                  : u.userGender;
              u.userFromLocation =
                payload.user.userFromLocation === undefined
                  ? u.userFromLocation
                  : u.userFromLocation;
              u.userDescription =
                payload.user.userDescription === undefined
                  ? u.userDescription
                  : u.userDescription;
              u.userBirthDate =
                payload.user.userBirthDate === undefined
                  ? u.userBirthDate
                  : u.userBirthDate;
              patchState({ user: u });
              this.store.dispatch(new SetUser(u));
            });
          this.store.dispatch(new Navigate(["/" + APP_ROUTES.PROFILE]));
        } else {
          this.toastr.error(data.user.msg, "Error", {
            timeOut: 2000,
          });
        }
      })
      .catch((err) => {
        this.toastr.error(err);
      });
  }

  @Action(CompleteProfileUser)
  completeProfileUser(
    { getState, patchState }: StateContext<UserStateModel>,
    { payload }: any,
    isNewUser: boolean
  ): void {
    this.api
      .post(completeProfileDetails(), payload.user)
      .pipe(catchError((e) => throwError(this.he.handleError(e))))
      .toPromise()
      .then((data: any) => {
        if (data.user.success) {
          this.toastr.success("Profile created successfully");
          this.store
            .select((s) => s.user.user)
            .subscribe((u) => {
              u.userFirstName = payload.user.userFirstName;
              u.userLastName = payload.user.userLastName;
              u.userGender = payload.user.userGender;
              u.userFromLocation = payload.user.userFromLocation;
              u.userBirthDate = payload.user.userBirthDate;

              patchState({ user: data.user.data });
              this.store.dispatch(new SetUser(data.user.data));
            });
          this.store.dispatch(new Navigate(["/" + APP_ROUTES.PROFILE]));
        } else {
          this.toastr.error(
            "Profile completion failed, try to submit information again",
            "Error",
            {
              timeOut: 2000,
            }
          );
        }
      })
      .catch((err) => {
        this.toastr.error(err);
      });
  }

  @Action(GetOTP)
  getOtp(
    { getState, patchState }: StateContext<UserStateModel>,
    { payload }: any
  ): void {
    this.api
      .post(requestOTP(), payload)
      .toPromise()
      .then((data: any) => {
        if (data.success) {
          this.toastr.success("OTP sent to mobile number");
          patchState({ otp: { reqID: data.requestID } });
        } else {
          this.toastr.error("", "OTP generation failed", {
            timeOut: 3000,
          });
        }
      })
      .catch((err) => {
        this.toastr.error("", "OTP generation failed", {
          timeOut: 7000,
        });
      });
  }

  @Action(VerifyOTP)
  VerifyOTP(
    { getState, patchState }: StateContext<UserStateModel>,
    { payload }: any
  ): void {
    this.api
      .post(verifyOTPRequest(), payload)
      .toPromise()
      .then((data: any) => {
        if (data.success) {
          this.toastr.success("Mobile number verified");
          patchState({ otp: data.user });
        } else {
          this.toastr.error("Error", "Mobile number verification failed", {
            timeOut: 2000,
          });
        }
      })
      .catch((err) => {
        this.toastr.error(err);
      });
  }

  @Action(VerifyOTPCancel)
  VerifyOTPCancel(
    { getState, patchState }: StateContext<UserStateModel>,
    { payload }: any
  ): void {
    this.api
      .post(verifyOTPCancelRequest(), payload)
      .toPromise()
      .then((data: any) => {
        if (data.success) {
          this.toastr.error("Mobile verification canceled");
          patchState({ otp: null });
        } else {
          this.toastr.error("Error", "Mobile verification cancelation failed", {
            timeOut: 2000,
          });
        }
      })
      .catch((err) => {
        this.toastr.error(err);
      });
  }
}
