import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { ApiService } from "app/services/api.service";
import { ToastrService } from "ngx-toastr";
import { Data } from "@angular/router";
import { ConfirmationModalComponent } from "../../confirmation-modal/confirmation-modal.component";
import { environment } from "environments/environment";
import { MarketBotDialogComponent } from "app/main/shared-module/common/market-bot-dialog/market-bot-dialog.component";
import { MarketEntityDialogComponent } from "app/main/shared-module/common/market-entity-dialog/market-entity-dialog.component";

@Component({
  selector: "app-search-user-list-modal",
  templateUrl: "./search-user-list-modal.component.html",
  styleUrls: ["./search-user-list-modal.component.scss"],
})
export class SearchUserListModalComponent implements OnInit {
  public dataSend: any;
  public sendData_: any;
  public userDataList: any;
  public userId: any = environment.userId;
  botDataList: any;
  entityDataList: any;
  type: any;
  public entityIndex: any = 0;
  public botIndex: any = 0;
  searchEntityAllData: any = [];
  searchBotAllData: any = [];
  // popUpEntityAllData: any;
  entityLengthCount: any;
  botDataLengthCount: any;

  constructor(
    public dialogRef: MatDialogRef<Data>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    public dialog: MatDialog,
    public toastr: ToastrService
  ) {}

  ngOnInit() {
    this.type = this.data.type;

    this.userDataList = this.data.userData;
    this.botDataList = this.data.botData;
    this.entityDataList = this.data.entitiesData;

    this.entityLengthCount = this.data.entitiesData.length;
    this.botDataLengthCount = this.data.botData.length;

    this.entityIndex = 0;
    this.searchEntityAllData = this.entityDataList.slice(
      this.entityIndex,
      this.entityIndex + 5
    );
    this.entityIndex += 5;

    this.botIndex = 0;
    this.searchBotAllData = this.botDataList.slice(
      this.botIndex,
      this.botIndex + 5
    );
    this.botIndex += 5;
  }

  EntityPrevData(): void {
    if (this.entityIndex > 5) {
      this.searchEntityAllData = this.entityDataList.slice(
        this.entityIndex - 10,
        this.entityIndex - 5
      );
      this.entityIndex -= 5;
    }
  }

  EntityNextData(): void {
    if (this.entityIndex < this.entityLengthCount) {
      this.searchEntityAllData = this.entityDataList.slice(
        this.entityIndex,
        this.entityIndex + 5
      );
      this.entityIndex += 5;
    }
  }

  botPrevData(): void {
    if (this.botIndex > 5) {
      this.searchBotAllData = this.botDataList.slice(
        this.botIndex - 10,
        this.botIndex - 5
      );
      this.botIndex -= 5;
    }
  }

  botNextData(): void {
    if (this.botIndex < this.botDataLengthCount) {
      this.searchBotAllData = this.botDataList.slice(
        this.botIndex,
        this.botIndex + 5
      );
      this.botIndex += 5;
    }
  }

  openAcceptInvitationModal(data): void {
    const sendData = {
      header: "Accept Invitation",
      body: "Are you sure you want to accept the invitation ?",
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: "450px",
      data: sendData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sendData_ = {
          loggedin: environment.userId,
          tochange: data.sender,
          state: "accepted",
          userName: data.senderDetail[0].userName,
          _id: data._id,
        };
        this.updateInvitationStatus(this.sendData_);
      }
    });
  }

  openIgnoreInvitationModal(data): void {
    const sendData = {
      header: "Decline Invitation",
      body: "Are you sure you want to decline the invitation ?",
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: "450px",
      data: sendData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sendData_ = {
          loggedin: environment.userId,
          tochange: data.sender,
          state: "ignored",
          userName: data.senderDetail[0].userName,
          _id: data._id,
        };
        this.updateInvitationStatus(this.sendData_);
      }
    });
  }

  updateInvitationStatus(data): void {
    this.api.updateInvitationStatus(data).then((response: any) => {
      if (data.state === "accepted") {
        this.userDataList.map((item, i) => {
          if (data.tochange === item.sender) {
            this.userDataList[i]["accepted"] = true;
          }
        });
      } else if (data.state === "ignored") {
        this.userDataList.map((item, i) => {
          if (data.tochange === item.sender) {
            this.userDataList[i]["ignored"] = true;
          }
        });
      }
      if (data.state === "accepted") {
        this.toastr.success(data.userName + " Invitation Accepted ");
      } else if (data.state === "ignored") {
        this.toastr.success(data.userName + " Invitation Ignored");
      }
    });
  }

  openToConnectModal(_data: any): void {
    const sendData = {
      header: "Connect",
      body: "Are you sure you want to send the invitation ?",
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: "450px",
      data: sendData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (!_data.sender) {
          const sendData_ = {
            sender: environment.userId,
            invitee: _data._id,
            state: "pending",
            userName: _data.userName,
          };
          this.sendInvitation(sendData_);
        } else if (_data.sender) {
          const sendData_ = {
            sender: environment.userId,
            invitee: _data.sender,
            state: "pending",
            userName: _data.userName,
          };
          this.sendInvitation(sendData_);
        }
      }
    });
  }
  sendInvitation(data): void {
    this.api.sendInvitationStatus(data).then(() => {
      let index = -1;
      this.userDataList.map((item, i) => {
        if (data.invitee === item._id) {
          index = i;
        }
      });
      this.userDataList.map((connectionItem, index_) => {
        if (connectionItem._id === data.invitee) {
          this.userDataList[index_]["pending"] = true;
        }
      });
      this.userDataList.map((connectionItem, index_) => {
        if (connectionItem.sender === data.invitee) {
          this.userDataList[index_]["pending"] = true;
        }
      });
      if (data.userName) {
        this.toastr.success("Request Sent to " + data.userName);
      } else {
        this.toastr.success("Request Sent");
      }
    });
  }

  openRemoveConnectionModal(data): void {
    const removeData = {
      header: "Remove",
      body: "Are you sure you want to remove the connection?",
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: "450px",
      data: removeData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (data.invitee === environment.userId) {
        if (result) {
          this.dataSend = {
            loggedin: environment.userId,
            tochange: data.sender,
            state: "removed",
            userName: data.senderDetail[0].userName,
            _id: data._id,
          };
          this.sendRemoveConnection(this.dataSend);
        }
      } else if (data.sender === environment.userId) {
        if (result) {
          this.dataSend = {
            loggedin: environment.userId,
            tochange: data.invitee,
            state: "removed",
            userName: data.inviteeDetail[0].userName,
            _id: data._id,
          };
          this.sendRemoveConnection(this.dataSend);
        }
      }
    });
  }

  sendRemoveConnection(data): void {
    this.api.updateInvitationStatus(data).then((response: any) => {
      this.userDataList.map((connectionItem, index_) => {
        if (connectionItem._id === data._id) {
          this.userDataList[index_]["removed"] = true;
        }
      });
      if (environment.userId === data.loggedin) {
        this.toastr.success(data.userName + " is removed from connection");
      }
    });
  }

  openToOpenBot(id) {
    this.dialog.open(MarketBotDialogComponent, {
      width: "650px",
      data: {
        id: id,
      },
    });
  }

  openToOpenEntity(id, type) {
    this.dialog.open(MarketEntityDialogComponent, {
      width: "650px",
      data: {
        id: id,
        cardType: type,
      },
    });
    // this.search(this.filterDataGroup, this.searchInput.value)
    // this.entityFilterData
  }

  decline(): void {
    this.dialogRef.close(false);
  }
}
