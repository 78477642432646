import { CdkTableModule } from "@angular/cdk/table";
import { MatTableModule } from "@angular/material/table";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  MatChipsModule,
  MAT_CHIPS_DEFAULT_OPTIONS,
} from "@angular/material/chips";
import { MatMenuModule } from "@angular/material/menu";
import { NgModule } from "@angular/core";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatExpansionModule } from "@angular/material/expansion";
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatSnackBarModule,
  MatFormFieldModule,
  MatNativeDateModule,
  MatDividerModule,
  MatTabsModule,
  MAT_DATE_FORMATS,
  MatCardModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonToggleModule,
  MatGridListModule,
  MatListModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatRadioModule,
  MatRippleModule,
  MatSidenavModule,
  MatSliderModule,
  MatSortModule,
  MatStepperModule,
  MatToolbarModule,
} from "@angular/material";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTreeModule } from "@angular/material/tree";
import {
  MatMomentDateModule,
  MomentDateModule,
} from "@angular/material-moment-adapter";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    CdkTableModule,
    MatSelectModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatDatepickerModule,
    MomentDateModule,
    MatNativeDateModule,
    MatMenuModule,
    MatTableModule,
    MatTooltipModule,
    DragDropModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatTabsModule,
    MatCardModule,
    MatTreeModule,
    MatExpansionModule,
    MatMomentDateModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatStepperModule,
    MatGridListModule,
    MatListModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatRippleModule,
    MatSidenavModule,
    MatSliderModule,
    MatSortModule,
    MatToolbarModule,
  ],
  exports: [
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    CdkTableModule,
    MatSelectModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatDatepickerModule,
    MomentDateModule,
    MatNativeDateModule,
    MatMenuModule,
    MatTableModule,
    MatTooltipModule,
    DragDropModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatTabsModule,
    MatCardModule,
    MatTreeModule,
    MatExpansionModule,
    MatMomentDateModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatStepperModule,
    MatGridListModule,
    MatListModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatRippleModule,
    MatSidenavModule,
    MatSliderModule,
    MatSortModule,
    MatToolbarModule,
  ],
  providers: [
    MatDatepickerModule,
    MatChipsModule,
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA],
      },
    },
  ],
})
export class MaterialModule {}
