import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../../../../../services/api.service";
import { MutualListModalComponent } from "../../../../shared-module/common/mutual-list-modal/mutual-list-modal.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-user-connections",
  templateUrl: "./user-connections.component.html",
  styleUrls: ["./user-connections.component.scss"],
})
export class UserConnectionsComponent implements OnInit {
  pramsuserid: any;
  public timelinePages = 1;
  public selectedIndex: Number = 0;
  connectionSuccess: any;
  connectRestrict: any;
  userConnection: any;
  mutualConnectLength: any;
  mutualConnection: any;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    const paramsData = this.route.snapshot.params;
    this.pramsuserid = paramsData.id;
    let tabUrl = this.route.url["value"][0].path;
    if (tabUrl == "users-connections") {
      this.api
        .getUserConnectionById(this.pramsuserid, "MAIN")
        .then((getUserConnect) => {
          // Show User Connections //
          this.connectionSuccess = getUserConnect.success;
          if (getUserConnect.success == false) {
            this.connectRestrict = getUserConnect.msg;
          } else {
            this.userConnection = getUserConnect.connections;
          }
        });
    }
  }

  openMutualUserModal(MutualData): void {
    if (MutualData) {
      const dialogRef = this.dialog.open(MutualListModalComponent, {
        width: "550px",
        data: {
          MutualconList: MutualData,
          MutualCount: MutualData.length,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.ngOnInit();
      });
    } else {
      const dialogRef = this.dialog.open(MutualListModalComponent, {
        width: "550px",
        data: {
          MutualCount: this.mutualConnectLength,
          MutualconList: this.mutualConnection,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.ngOnInit();
      });
    }
  }
}
