import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Data,  } from "@angular/router";
import { ApiService } from "app/services/api.service";
import { MatDialog } from "@angular/material/dialog";
import { FormGroup,  } from "@angular/forms";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-market-bot-dialog",
  templateUrl: "./market-bot-dialog.component.html",
  styleUrls: ["./market-bot-dialog.component.scss"],
})
export class MarketBotDialogComponent {
  botEntityid: any;
  requestForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<Data>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    public toastr: ToastrService,    
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.botEntityid = this.data.id;
  }

  decline(): void {
    this.dialogRef.close(false);
  }

  submitClone() {
    this.api.marketBotClone(this.data.id, "").then((data) => {
      if (data.success == false) {
        this.toastr.error(data.msg);
        this.dialogRef.close(false);        
      } else {
        this.toastr.success(data.msg);        
        this.dialogRef.close(false);
      }
    });
  }

  cancelFun() {
    this.dialogRef.close(false);
  }
}
