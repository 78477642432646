import { Component, OnInit } from "@angular/core";
import { FuseConfigService } from "@fuse/services/config.service";

@Component({
  selector: "app-page500",
  templateUrl: "./page500.component.html",
  styleUrls: ["./page500.component.scss"],
})
export class Page500Component implements OnInit {
  constructor(private _fuseConfigService: FuseConfigService) {
    this._fuseConfigService.config = {
      layout: {
        style: "vertical-layout-1",
        width: "fullwidth",
        navbar: {
          primaryBackground: "fuse-navy-700",
          secondaryBackground: "fuse-navy-900",
          folded: false,
          hidden: true,
          position: "left",
          variant: "vertical-style-1",
        },
        toolbar: {
          customBackgroundColor: false,
          background: "fuse-white-500",
          hidden: true,
          position: "below-static",
        },

        sidepanel: {
          hidden: true,
          position: "right",
        },
      },
      customScrollbars: true,
    };
  }

  ngOnInit() {}
}
