import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { MaterialModule } from "../../shared-module/modules/material.module";
import { FuseSharedModule } from "@fuse/shared.module";

import { ConfirmationDialogComponent } from "app/main/shared-module/common/confirmation-dialog/confirmation-dialog.component";

import { MarketEntityDialogComponent } from "app/main/shared-module/common/market-entity-dialog/market-entity-dialog.component";
import { MarketBotDialogComponent } from "app/main/shared-module/common/market-bot-dialog/market-bot-dialog.component";

import { MutualListModalComponent } from "app/main/shared-module/common/mutual-list-modal/mutual-list-modal.component";
import { ProposalListModalComponent } from "app/main/shared-module/common/proposal-list-modal/proposal-list-modal.component";
import { InvitationListModalComponent } from "app/main/shared-module/common/invitation-list-modal/invitation-list-modal.component";
import { SearchUserListModalComponent } from "app/main/shared-module/common/searchbarfilter/search-user-list-modal/search-user-list-modal.component";
import { ConfirmationModalComponent } from "app/main/shared-module/common/confirmation-modal/confirmation-modal.component";
import { SentInvitationModalListComponent } from "app/main/shared-module/common/sent-invitation-modal-list/sent-invitation-modal-list.component";
import { Page404Component } from "app/main/shared-module/common/page404/page404.component";
import { Page500Component } from "./page500/page500.component";
import { DeletePhraseDialogComponent } from './delete-phrase-dialog/delete-phrase-dialog.component';

const routes = [
  {
    path: "404",
    component: Page404Component,
  },
  {
    path: "500",
    component: Page500Component,
  },
];

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    MarketEntityDialogComponent,
    MarketBotDialogComponent,
    MutualListModalComponent,
    ProposalListModalComponent,
    InvitationListModalComponent,
    SearchUserListModalComponent,
    ConfirmationModalComponent,
    SentInvitationModalListComponent,
    Page404Component,
    Page500Component,
    DeletePhraseDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MaterialModule,
    FlexLayoutModule,
    FuseSharedModule,
    MatExpansionModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    ConfirmationDialogComponent,
    MarketEntityDialogComponent,
    MarketBotDialogComponent,
    MutualListModalComponent,
    ProposalListModalComponent,
    InvitationListModalComponent,
    SearchUserListModalComponent,
    ConfirmationModalComponent,
    SentInvitationModalListComponent,
    Page404Component,
    DeletePhraseDialogComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    ConfirmationDialogComponent,
    MarketEntityDialogComponent,
    MarketBotDialogComponent,
    MutualListModalComponent,
    ProposalListModalComponent,
    InvitationListModalComponent,
    SearchUserListModalComponent,
    ConfirmationModalComponent,
    SentInvitationModalListComponent,
    Page404Component,
    DeletePhraseDialogComponent
  ],
})
export class SharedCommonModule {}
