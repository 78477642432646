import { NgModule } from "@angular/core";


import { VerticalLayout1Module } from "app/layout/vertical/layout-1/layout-1.module";
import { VerticalLayout2Module } from "app/layout/vertical/layout-2/layout-2.module";
import { VerticalLayout3Module } from "app/layout/vertical/layout-3/layout-3.module";

import { HorizontalLayout1Module } from "app/layout/horizontal/layout-1/layout-1.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { DiagnosticInfoComponent,JsonPipe } from './components/diagnostic-info/diagnostic-info.component';
import { MatDialogModule } from '@angular/material'
@NgModule({
  imports: [
    VerticalLayout1Module,
    VerticalLayout2Module,
    VerticalLayout3Module,
    InfiniteScrollModule,
    HorizontalLayout1Module,
    MatDialogModule
  ],
  exports: [
    VerticalLayout1Module,
    VerticalLayout2Module,
    VerticalLayout3Module,
    JsonPipe,
    HorizontalLayout1Module,    
  ],
  entryComponents: [
    DiagnosticInfoComponent,    
  ],
  declarations: [DiagnosticInfoComponent,JsonPipe],
})
export class LayoutModule {}
