import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Store } from "@ngxs/store";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private store: Store,
    private cookieService: CookieService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let activateRoute = true;
    if (this.cookieService.get("token")) {
      if (state.url !== "/login") {
        activateRoute = true;
      } else if (state.url == "/login") {
        this.router.navigate(["/profile"]);
        activateRoute = false;
      }
    } else if (state.url !== "/login") {
      this.router.navigate(["/login"]);
      activateRoute = false;
    }
    return activateRoute;
  }
}
