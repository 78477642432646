import { EntityModel, RelationshipModel } from "./entity.model";

export class GetAllEntities {
  static readonly type = "[GETALLENTITIES] GET_ALL_ENTITIES";
  constructor(public payload?: string) {}
}

export class GetProcessByEntityID {
  static readonly type = "[GETPROCESSBYENTITYID] GET_ENTITY_PROCESS";
  constructor(public payload?: string) {}
}

export class GetProcessByEntityIDSuccess {
  static readonly type = "[GETPROCESSBYENTITYID] GET_ENTITY_PROCESS_SUCCESS";
  constructor(public response: any) {}
}

export class GetProcessByEntityIDFailed {
  static readonly type = "[GETPROCESSBYENTITYID] GET_ENTITY_PROCESS_FAILED";
  constructor(public payload?: any) {}
}

export class GetAllEntitiesSuccess {
  static readonly type = "[GETALLENTITIES] GET_ALL_ENTITIES_SUCCESS";
  constructor(public response: EntityModel) {}
}

export class GetAllEntitiesFailed {
  static readonly type = "[GETALLENTITIES] GET_ALL_ENTITIES_FAILED";
  constructor(public payload?: any) {}
}

export class GetAllProcesses {
  static readonly type = "[GETALLPROCESSES] GET_ALL_PROCESSES";
  constructor(public payload?: any) {}
}

export class GetAllProcessesSuccess {
  static readonly type = "[GETALLPROCESSES] GET_ALL_PROCESSES_SUCCESS";
  constructor(public response: any) {}
}

export class GetAllProcessesFailed {
  static readonly type = "[GETALLPROCESSES] GET_ALL_PROCESSES_FAILED";
  constructor(public payload?: any) {}
}

// action for create Entity //

export class CreateEntityRequest {
  static readonly type = "[CREATEENTITY] CREATE_ENTITY_REQUEST";
  constructor(public payload?: string) {}
}

export class CreateEntitySuccess {
  static readonly type = "[CREATEENTITY] CREATE_ENTITY_SUCCESS";
  constructor(public IsEntityCreated: boolean) {}
}

export class CreateEntityFailed {
  static readonly type = "[CREATEENTITY] CREATE_ENTITY_FAILED";
  constructor(public IsEntityCreatedFailed: boolean) {}
}

// action for create Characteristics //

export class CreateCharacteristicRequest {
  static readonly type =
    "[CREATECHARACTERISTICS] CREATE_CHARACTERISTICS_REQUEST";
  constructor(public payload?: string) {}
}

export class CreateCharacteristicSuccess {
  static readonly type =
    "[CREATECHARACTERISTICS] CREATE_CHARACTERISTICS_SUCCESS";
  constructor(public IsCharacteristicCreated: boolean) {}
}

export class CreateCharacteristicFailed {
  static readonly type =
    "[CREATECHARACTERISTICS] CREATE_CHARACTERISTICSENTITY_FAILED";
  constructor(public IsCharacteristicCreatedFailed: boolean) {}
}

// action for create Process //

export class CreateProcessRequest {
  static readonly type = "[CREATEPROCESS] CREATE_PROCESS_REQUEST";
  constructor(public payload?: string) {}
}

export class CreateProcessSuccess {
  static readonly type = "[CREATEPROCESS] CREATE_PROCESS_SUCCESS";
  constructor(public IsProcessCreated: boolean) {}
}

export class CreateProcessFailed {
  static readonly type = "[CREATEPROCESS] CREATE_PROCESS_FAILED";
  constructor(public IsProcessCreatedFailed: boolean) {}
}

// action for create Process //

export class CreateRequestTypeRequest {
  static readonly type = "[CREATEREQUESTTYPE] CREATE_REQUESTTYPE_REQUEST";
  constructor(public payload?: string) {}
}

export class CreateRequestTypeSuccess {
  static readonly type = "[CREATEREQUESTTYPE] CREATE_REQUESTTYPE_SUCCESS";
  constructor(public IsRequestTypeCreated: boolean) {}
}

export class CreateRequestTypeFailed {
  static readonly type = "[CREATEREQUESTTYPE] CREATE_REQUESTTYPE_FAILED";
  constructor(public IsRequestTypeCreatedFailed: boolean) {}
}

//action for create relationship //
export class CreateRelationRequest {
  static readonly type = "[CREATERELATION] CREATE_RELATION_REQUEST";
  constructor(public payload?: RelationshipModel, public rType?: boolean) {}
}

// action for create Associate Entity
export class CreateAssociateEntity {
  static readonly type = "[CREATEASSOCIATEENTITY] CREATE_RELATION_REQUEST";
  constructor(public payload?: RelationshipModel, public rType?: boolean) {}
}

// action for get entityTypeMaster //
export class GetEntityTypeMasterRequest {
  static readonly type = "[GETENTITYTYPEMASTER] GET_ENTITYTYPE_REQUEST";
}

// action for get processTypeMaster //
export class GetProcessTypeMasterRequest {
  static readonly type = "[GETPROCESSTYPEMASTER] GET_PROCESSYTYPE_REQUEST";
}

// action for get entity Details //
export class GetProcessByEntityIdRequest {
  static readonly type =
    "[GETPROCESSBYENTITYID] GET_PROCESS_BY_ENTITY_ID_REQUEST";
  constructor(public payload: any) {}
}

export class GetCharateristicByEntityIdRequest {
  static readonly type =
    "[GETCHARACTERISTICBYENTITYIDREQUEST] GET_CHARACTERISTICBYENTITYID_REQUEST";
  constructor(public payload: any) {}
}

// action for get entity Details //

export class GetLocationRequest {
  static readonly type = "[GETLOCATIONREQUEST] GET_LOCATION_DETAILS_REQUEST";
  constructor(public payload: any) {}
}

// action for get CharactTypeMaster //
export class GetCharactTypeMasterRequest {
  static readonly type = "[GETCHARACTYPEMASTER] GET_CHARACTYTYPE_REQUEST";
}

export class GetUserCreatedCharacteristic {
  static readonly type =
    "[GETUSERCREATEDCHARACTERISTIC] GET_USER_CREATED_CHARACTERISTIC";
}

export class GetAssociateByEntityId {
  static readonly type = "[GETASSOCIATEBYENTITYID] GET_ASSOCIATE_BY_ENTITYID";
}

// action for get domainMaster //
export class GetDomainMasterRequest {
  static readonly type = "[GETDOMAINMASTER] GET_DOMAINMASTER_REQUEST";
  constructor(public payload: any) {}
}

// action for get domainMaster //
export class GetEntityDomain {
  static readonly type = "[GETENTITYDOMAIN] GET_ENTITY_DOMAIN";
  constructor(public payload: any) {}
}

// action for get entity Details //
export class GetEntityDetailsRequest {
  static readonly type =
    "[GETENTITYDETAILSBYID] GET_ENTITYDETAILS_BY_ID_REQUEST";
  constructor(public payload: any) {}
}

// action for get entity Details //
export class GetHierarchyRequest {
  static readonly type = "[GETHIERARCHY] GET_HIERARCHY_REQUEST";
  constructor(public payload?: any) {}
}

// action for get entity Details //
export class GetEntityTypeRequest {
  static readonly type = "[GETENTITYTYPEBYID] GET_ENTITYTYPE_BY_ID_REQUEST";
  constructor(public payload: any) {}
}

export class GetRelationshipByCodeRequest {
  static readonly type =
    "[GETRELATIONSHIPBYCODE] GET_RELATIONSHIPBYCODE_REQUEST";
  constructor(public start: any, public end: any) {}
}

export class GetProcessRelationship {
  static readonly type = "[GETPROCESSRELATIONSHIP] GET_PROCESS_RELATIONSHIP";
  constructor(public entityTypeID: any, public processTypeID: any) {}
}
export class GetCharacteristicRelationshipByCodeRequest {
  static readonly type =
    "[GETCHARACTERISTICRELATIONSHIPBYCODE] GET_CHARACTERISTIC_RELATIONSHIPBYCODE_REQUEST";
  constructor(public entityTypeID: any, public characteristicTypeID: any) {}
}

export class GetParentEntities {
  static readonly type = "[GETPARENTENTITIES] GET_PARENT_ENTITIES";
  constructor(public payload?: string) {}
}

export class GetParentEntitiesSuccess {
  static readonly type = "[GETPARENTENTITIES] GET_PARENT_ENTITIES_SUCCESS";
  constructor(public response: EntityModel) {}
}

export class GetParentEntitiesFailed {
  static readonly type = "[GETPARENTENTITIES] GET_PARENT_ENTITIES_FAILED";
  constructor(public payload?: any) {}
}

export class GetCharacteristicProcess {
  static readonly type =
    "[GETCHARACTERISTICPROCESS] GET_CHARACTERISTIC_PROCESS";
  constructor(public payload?: any) {}
}
export class OwnerEntities {
  static readonly type = "[OWNERENTITIES] OWNERENTITIES";
  constructor(public payload?: string) {}
}
export class OwnerProcess {
  static readonly type = "[OWNERPROCESS] OWNER_PROCESS";
  constructor(public payload?: string) {}
}

export class GetProcessIntent {
  static readonly type = "[GETPROCESSINTENT] GET_PROCESS_INTENT";
  constructor(public payload?: any) {}
}
export class GetProcessKnowledgeBases {
  static readonly type =
    "[GETPROCESSKNOWLEDGEBASES] GET_PROCESS_KNOWLEDGE_BASES";
  constructor(public payload?: any) {}
}
export class GetCharacteristicEntity {
  static readonly type = "[GETCHARACTERISTICENTITY] GET_CHARACTERISTIC_ENTITY";
  constructor(public payload?: any) {}
}

export class GetAllParentEntities {
  static readonly type = "[GETALLPARENTENTITIES] GET_ALL_PARENT_ENTITIES";
  constructor(public payload?: string) {}
}
