import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FuseDirectivesModule } from "@fuse/directives/directives";
import { FusePipesModule } from "@fuse/pipes/pipes.module";
//import {HeaderComponent} from '../app/main/shared-module/components/header.component';
//import {FileUploadComponent} from '../app/main/shared/components/file-upload-component'
//import {FileUploadSharedComponent} from '../app/main/shared-module/components/file-upload-component'
@NgModule({
  declarations: [
    //HeaderComponent,
    // FileUploadSharedComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseDirectivesModule,
    FusePipesModule,
    //  BrowserAnimationsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    //HeaderComponent,
    //FileUploadSharedComponent,
    FuseDirectivesModule,
    FusePipesModule,
  ],
})
export class FuseSharedModule {}
