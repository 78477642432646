import {
  createCharacteristic,
  createEntity,
  createProcess,  
  createRequestType,
  getAllEntities,
  getAllProcesses,
  getCharacteristicTypeMaster,
  getUserCreatedCharacteristic,
  getDomainMaster,
  getEntityDomain,
  getEntityById,
  getEntityTypeMaster,
  getHierarchy,
  getProcessTypeMaster,
  getCharacteristicByEntityID,
  getRelationshipByCode,
  getCharacteristicRelationship,
  getParentEntities,
  deactivateEntity,
  createEntityFeature,
  deactivateCharacteristic,
  editCharacteristic,
  getCharacteristicProcess,
  getProcessRelationship,
  deactivateProcess,
  editProcess,
  getAssociateByEntityId,
  ownerEntities,
  ownerProcesses,
  createAssociate,
  deactivatAssociate,
  getProcessIntent,
  createProcessIntent,
  editProcessIntent,
  getProcessKnowledgeBases,
  createProcessKnowledgeBases,
  editProcessKnowledgeBases,  
  deleteKnowledgeDocument,
  getCharacteristicEntity,
  createCharacteristicEntity,
  editCharacteristicEntity,
  getAllParentEntities,
  withdrawRequest,
  approveRequest,
  rejectRequest,
} from "./entity.api.url";
import { Injectable,  } from "@angular/core";
import { Observable,  } from "rxjs";
import {
  getProcessByEntityID,
  getParentProcessByEntityID,
} from "./entity.api.url";
import {
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { SharedApiService } from "app/main/shared-module/services/shared.api.service";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";

const SERVER_URL = environment.serverUrl;

@Injectable({
  providedIn: "root",
})
export class EntityService {
  constructor(
    private apiService: SharedApiService,
    private cookieService: CookieService
  ) {}

  httpOptions: any = {
    headers: this.generateHeaders(),
  };

  getEntityById(payload): Observable<any> {
    return this.apiService.sendGetRequest(getEntityById + payload, {});
  }

  getAllEntities(): Observable<any> {
    return this.apiService.sendGetRequest(getAllEntities, {});
  }

  getAllProcesses(): Observable<any> {
    return this.apiService.sendGetRequest(getAllProcesses, {});
  }

  createEntity(payload): Observable<any> {
    return this.apiService.sendPostRequest(createEntity, payload);
  }
  editEntity(payload, id): Observable<any> {
    return this.apiService.sendPutRequest(createEntity + "/" + id, payload);
  }
  deactivateEntity(id, payload): Observable<any> {
    return this.apiService.sendPostRequest(deactivateEntity + "/" + id, {});
  }
  createFeaturedEntity(id, payload): Observable<any> {
    return this.apiService.sendPostRequest(
      createEntityFeature + "/" + id,
      payload
    );
  }
  createRequestType(payload): Observable<any> {
    return this.apiService.sendPostRequest(createRequestType, payload);
  }

  createCharacteristic(payload): Observable<any> {
    return this.apiService.sendPostRequest(createCharacteristic, payload);
  }
  editCharacteristic(id, payload): Observable<any> {
    return this.apiService.sendPutRequest(
      editCharacteristic + "/" + id,
      payload
    );
  }

  createProcess(payload): Observable<any> {
    return this.apiService.sendPostRequest(createProcess, payload);
  }

  editProcess(id, payload): Observable<any> {
    return this.apiService.sendPutRequest(editProcess + "/" + id, payload);
  }

  getEntityTypeMaster(): Observable<any> {
    return this.apiService.sendGetRequest(getEntityTypeMaster, {});
  }

  getParentEntities(pageNo = 0): Observable<any> {
    return this.apiService.sendGetRequest(getParentEntities + "/" + pageNo, {});
  }

  getProcessTypeMaster(id): Observable<any> {
    return this.apiService.sendGetRequest(getProcessTypeMaster + "/" + id, {});
  }

  getCharactTypeMaster(id): Observable<any> {
    return this.apiService.sendGetRequest(
      getCharacteristicTypeMaster + "/" + id,
      {}
    );
  }

  getUserCreatedCharacteristic(id): Observable<any> {
    return this.apiService.sendGetRequest(
      getUserCreatedCharacteristic + "/" + id,
      {}
    );
  }
  deactivateCharacteristics(id, payload): Observable<any> {
    return this.apiService.sendPostRequest(
      deactivateCharacteristic + "/" + id,
      {}
    );
  }
  deactivateProcess(id, payload): Observable<any> {
    return this.apiService.sendPostRequest(deactivateProcess + "/" + id, {});
  }

  getDomainMaster(id): Observable<any> {
    return this.apiService.sendGetRequest(getDomainMaster + "/" + id, {});
  }

  getEntityDomain(id): Observable<any> {
    return this.apiService.sendGetRequest(getEntityDomain + "/" + id, {});
  }
  getOwnerEntities(): Observable<any> {
    return this.apiService.sendGetRequest(ownerEntities, {});
  }

  getOwnerProcess(entityId): Observable<any> {
    return this.apiService.sendGetRequest(ownerProcesses + "/" + entityId, {});
  }

  getHierarchy(id): Observable<any> {
    return this.apiService.sendGetRequest(getHierarchy + "/" + id, {});
  }

  getEntityType(id): Observable<any> {
    return this.apiService.sendGetRequest(getEntityById + id, {});
  }

  getProcessByEntityID(id): Observable<any> {
    return this.apiService.sendGetRequest(getProcessByEntityID + "/" + id, {});
  }

  getParentProcessByEntityID(id): Observable<any> {
    return this.apiService.sendGetRequest(
      getParentProcessByEntityID + "/" + id,
      {}
    );
  }

  getCharacteristicByEntityID(id): Observable<any> {
    return this.apiService.sendGetRequest(getCharacteristicByEntityID + id, {});
  }

  getRelationshipByCode(start, end): Observable<any> {
    return this.apiService.sendGetRequest(getRelationshipByCode, {});
  }
  getProcessRelationship(entityTypeID, processTypeID): Observable<any> {
    return this.apiService.sendGetRequest(
      getProcessRelationship + "/" + entityTypeID + "/" + processTypeID,
      {}
    );
  }
  getCharacteristicRelationship(
    entityTypeID,
    characteristicTypeID
  ): Observable<any> {
    return this.apiService.sendGetRequest(
      getCharacteristicRelationship +
        "/" +
        entityTypeID +
        "/" +
        characteristicTypeID,
      {}
    );
  }

  getCharacteristicProcess(
    entityID,
    entityTypeID,
    characteristicTypeID,
    characteristicRelationshipID,
    characteristicDirectionRelationshipID
  ): Observable<any> {
    return this.apiService.sendGetRequest(
      getCharacteristicProcess +
        "/" +
        entityID +
        "/" +
        entityTypeID +
        "/" +
        characteristicTypeID +
        "/" +
        characteristicRelationshipID +
        "/" +
        characteristicDirectionRelationshipID,
      {}
    );
  }

  getAssociateByEntityId(id): Observable<any> {
    return this.apiService.sendGetRequest(
      getAssociateByEntityId + "/" + id,
      {}
    );
  }
  createAssociate(payload): Observable<any> {
    return this.apiService.sendPostRequest(createAssociate, payload);
  }
  editAssociate(payload, id): Observable<any> {
    return this.apiService.sendPutRequest(createAssociate + "/" + id, payload);
  }
  deactivateAssociate(id, payload): Observable<any> {
    return this.apiService.sendPutRequest(
      deactivatAssociate + "/" + id,
      payload
    );
  }

  getProcessIntent(id): Observable<any> {
    return this.apiService.sendGetRequest(getProcessIntent + "/" + id, {});
  }
  createProcessIntent(payload): Observable<any> {
    return this.apiService.sendPostRequest(createProcessIntent, payload);
  }
  editProcessIntent(payload, id): Observable<any> {
    return this.apiService.sendPutRequest(
      editProcessIntent + "/" + id,
      payload
    );
  }

  getProcessKnowledgeBases(id): Observable<any> {
    return this.apiService.sendGetRequest(
      getProcessKnowledgeBases + "/" + id,
      {}
    );
  }
  createProcessKnowledgeBases(payload): Observable<any> {
    return this.apiService.sendPostRequest(
      createProcessKnowledgeBases,
      payload
    );
  }
  editProcessKnowledgeBases(payload, id): Observable<any> {
    return this.apiService.sendPutRequest(
      editProcessKnowledgeBases + "/" + id,
      payload
    );
  }

  deleteKnowledgeDocument(payload, id): Observable<any> {
    return this.apiService.sendDeleteRequest(
      deleteKnowledgeDocument + "/" + id,
      payload
    );
  }

  getCharacteristicEntity(id): Observable<any> {
    return this.apiService.sendGetRequest(
      getCharacteristicEntity + "/" + id,
      {}
    );
  }
  createCharacteristicEntity(payload): Observable<any> {
    return this.apiService.sendPostRequest(createCharacteristicEntity, payload);
  }
  editCharacteristicEntity(payload, id): Observable<any> {
    return this.apiService.sendPutRequest(
      editCharacteristicEntity + "/" + id,
      payload
    );
  }

  getAllParentEntities(): Observable<any> {
    return this.apiService.sendGetRequest(getAllParentEntities, {});
  }

  withdrawRequest(id, payload): Observable<any> {
    return this.apiService.sendPostRequest(withdrawRequest + "/" + id, payload);
  }
  approveRequest(id, payload): Observable<any> {
    return this.apiService.sendPostRequest(approveRequest + "/" + id, payload);
  }
  rejectRequest(id, payload): Observable<any> {
    return this.apiService.sendPostRequest(rejectRequest + "/" + id, payload);
  }

  generateHeaders(options?: any): HttpHeaders {
    var httpHeaders = null;
    let token = localStorage.getItem("token") || this.cookieService.get("token");

    if (token) {
      httpHeaders = new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      });
    } else {
      httpHeaders = new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
      });
    }

    return httpHeaders;
  }
}
