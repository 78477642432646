import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { ApiService } from "app/services/api.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-access-code",
  templateUrl: "./access-code.component.html",
  styleUrls: ["./access-code.component.scss"],
})
export class AccessCodeComponent implements OnInit {
  public addAccessCodeForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<AccessCodeComponent>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private api: ApiService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.createAccessCodeForm();
  }

  createAccessCodeForm() {
    this.addAccessCodeForm = this.fb.group({
      accessCode: ["", [Validators.required]],
    });
  }

  getAccessCode(form: FormGroup) {
    if (form.valid) {
      this.api
        .accessCodeApi(form.value)
        .then((res) => {
          if (res.success) {
            this.dialogRef.close(res);
            this.toastr.success(res.msg);
          } else {
            this.toastr.error(res.msg);
          }
        })
        .catch((res) => {
          this.dialogRef.close(res);
        });
    }
  }
}
