import { MaterialModule } from "./../shared-module/modules/material.module";
import { NgModule } from "@angular/core";
import { DatePipe } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { FuseSharedModule } from "@fuse/shared.module";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { FuseInnerScrollDirective } from "@fuse/directives/fuse-inner-scroll/fuse-inner-scroll.directive";
import { SharedApiService } from "../shared-module/services/shared.api.service";
import { MatSidenav } from "@angular/material";

import { FuseWidgetModule } from "@fuse/components/widget/widget.module";
import { FuseSidebarModule } from "@fuse/components";
import { BotProfileComponent } from "../details-screen-module/bot-profile/bot-profile.component";
import { EntityProfileComponent } from "../details-screen-module/entity-profile/entity-profile.component";
import { UserProfileComponent } from "../details-screen-module/user-profile/user-profile.component";

import { UserTimelineComponent } from "../details-screen-module/user-profile/components/user-timeline/user-timeline.component";
import { UserConnectionsComponent } from "../details-screen-module/user-profile/components/user-connections/user-connections.component";
import { UserEntityComponent } from "../details-screen-module/user-profile/components/user-entity/user-entity.component";
import { UserBotComponent } from "../details-screen-module/user-profile/components/user-bot/user-bot.component";

import { SharedCommonModule } from "../shared-module/common/shared-common.module";

const routes: Routes = [
  { path: "botProfile/:id/:name", component: BotProfileComponent },
  { path: "entity-profile/:id/:name", component: EntityProfileComponent },
  // {
  //   path: "",
  //   component: UserProfileComponent,
  // },
  {
    path: "users-details/:id/:name",
    component: UserProfileComponent,
  },
  {
    path: "users-post/:id/:name",
    component: UserProfileComponent,
  },
  {
    path: "users-connections/:id/:name",
    component: UserProfileComponent,
  },
  {
    path: "users-entity/:id/:name",
    component: UserProfileComponent,
  },
  {
    path: "users-bot/:id/:name",
    component: UserProfileComponent,
  },
];

@NgModule({
  declarations: [
    BotProfileComponent,
    EntityProfileComponent,
    UserProfileComponent,
    UserTimelineComponent,
    UserConnectionsComponent,
    UserEntityComponent,
    UserBotComponent,
  ],
  imports: [
    CommonModule,
    FuseWidgetModule,
    FuseSidebarModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FuseSharedModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedCommonModule,
  ],
  exports: [],
  providers: [SharedApiService, FuseInnerScrollDirective, MatSidenav, DatePipe],
  entryComponents: [BotProfileComponent, EntityProfileComponent],
})
export class DetailsScreenModule {}
